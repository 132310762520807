import React, { useEffect, useState } from "react";
import {
  BoxStyle,
  DashboardBoxStyle,
  ButtonBlue,
} from "components/common/CommonStyles";
import { ToastStyles } from "components/common/ToastStyle";
import { toast, Toaster } from "react-hot-toast";

import { Form, Modal, Spinner } from "react-bootstrap";
import {
  GetIntervieweeAll,
  DeleteUserApi,
  DeleteMultipleStudents,
} from "../../axios/apis";
import { Table } from "components/Table/Table";
import Loading from "components/common/Loading";

export const Candidates = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [studentdata, setStudentData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  console.log(studentdata);

  const FetchData = async () => {
    setLoading(true);
    try {
      const response = await GetIntervieweeAll();
      setStudentData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    FetchData();
  }, []);

  //   const handleDeleteUser = async (userId, userEmail) => {
  //     console.log(`Deleting user with ID: ${userId}, Email: ${userEmail}`);

  //     const shouldDelete = window.confirm(
  //       "Are you sure you want to delete this user?"
  //     );

  //     if (shouldDelete) {
  //       try {
  //         await DeleteUserApi(userId);
  //         FetchData(); // Fetch updated data after deletion
  //         toast.success("User deleted successfully", ToastStyles);
  //       } catch (error) {
  //         console.error(error);
  //         toast.error("Failed to delete user. Please try again.", ToastStyles);
  //       }
  //     }
  //   };
  // select and delete

  const handleSelectedStudent = (e) => {
    if (selectedStudent.includes(e)) {
      setSelectedStudent(selectedStudent.filter((student) => student !== e));
    } else {
      setSelectedStudent([...selectedStudent, e]);
    }
  };

  const [show, setshow] = useState(false);
  const [deletestu, setdeletestu] = useState("");
  const handleClose = () => {
    setshow(false);
  };

  const deleteStudent = async () => {
    if (deletestu !== "DeleteStudents") {
      toast.error("Incorrect Text", ToastStyles);
    } else {
      handleClose();
      setLoading(true);
      try {
        console.log("Deleting students with IDs:", selectedStudent);
        const res = await DeleteMultipleStudents({
          studentsId: selectedStudent,
        });
        toast.success("Students deleted Successfully", ToastStyles);
        setLoading(false);
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log("Error deleting students:", error);
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      field: "firstname",
      headerName: "Name",
      minWidth: 180,
      renderCell: (data) => (
        <div>{data?.row?.firstname + " " + (data?.row?.lastname || "")}</div>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Contact No",

      minWidth: 150,
    },
    {
      field: "className",
      headerName: "Class",
      minWidth: 120,
      renderCell: (data) => <div>{data?.row?.className}</div>,
    },
    {
      field: "attemptedInterviews",
      headerName: "Interviews Attempted",
      minWidth: 200,
      renderCell: (data) => (
        <div className="d-flex justify-content-center align-items-center mx-auto">
          {data?.row?.interviewResults?.length || "0"}
        </div>
      ),
    },
    // {
    //    field: "selct",
    //    headerName: "Select",
    //    minWidth: 120,
    //    renderCell: (data) => (
    //       <div
    //          className="d-flex justify-ceontent-center align-items-center "
    //       >

    //          <Form.Check name="check" className="checkbox" checked={selectedStudent.includes(data?.row?._id)} onChange={() => handleSelectedStudent(data?.row?._id)} />
    //       </div>
    //    ),
    // },
    //  {
    //    field: "delete",
    //    headerName: "Delete",
    //    minWidth: 120,
    //    renderCell: (data) => (
    //      <div className="d-flex justify-content-center align-items-center">
    //        <button
    //          onClick={() => handleDeleteUser(data.row._id, data.row.email)}
    //          style={{
    //            border: "none",
    //            padding: "10px",
    //            borderRadius: "5px",
    //            backgroundColor: "tomato",
    //            color: "white",
    //          }}
    //        >
    //          Delete
    //        </button>
    //      </div>
    //    ),
    //  },
    {
      field: "selct",
      headerName: "Select",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex justify-ceontent-center align-items-center ">
          <Form.Check
            name="check"
            className="checkbox"
            checked={selectedStudent.includes(data?.row?._id)}
            onChange={() => handleSelectedStudent(data?.row?._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you absolutely sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="">
            This action cannot be undone. This will permanently delete the{" "}
            <b>Students</b>.
          </h6>
          <Form.Group className="" controlId="formBasicEmail">
            <Form.Label className="form-title">
              Please type "<b>DeleteStudents</b>" to confirm.
            </Form.Label>
            <Form.Control
              className="form-input"
              type="text"
              name="deletestu"
              value={deletestu}
              onChange={(e) => setdeletestu(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBlue
            onClick={() => deleteStudent()}
            className="h-40 w-100"
            style={{ backgroundColor: "red" }}
          >
            Delete Students
          </ButtonBlue>
        </Modal.Footer>
      </Modal>
      <Toaster />
      {loading ? (
        <>
          <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
            <Loading />
          </DashboardBoxStyle>
        </>
      ) : (
        <>
          <DashboardBoxStyle className="scroller">
            <BoxStyle className="my-3">
              <div className="">
                {studentdata.length === 0 ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center my-4">
                      <h5>Currently No Candidates To Display</h5>
                    </div>
                  </>
                ) : (
                  <>
                    {/* {selectedStudent.length > 0 && <div className="d-flex justify-content-end ">
                            <div className="col-12">
                              <div className="d-flex justify-content-end  p-0"><ButtonBlue style={{ backgroundColor: "red" }} className="h-40" onClick={() => setshow(true)} width="150px">Delete</ButtonBlue></div>

                            </div>
                          </div>} */}
                    {selectedStudent.length > 0 && (
                      <div className="d-flex justify-content-end ">
                        <div className="col-12">
                          <div className="d-flex justify-content-end  p-0">
                            <ButtonBlue
                              style={{ backgroundColor: "red" }}
                              className="h-40"
                              onClick={() => setshow(true)}
                              width="150px"
                            >
                              Delete
                            </ButtonBlue>
                          </div>
                        </div>
                      </div>
                    )}

                    <Table
                      columns={columns}
                      checkbox={false}
                      data={studentdata?.map((item, i) => {
                        return {
                          ...item,
                          id: item._id,
                          key: i,
                        };
                      })}
                      style={{ width: "100%" }}
                      limit={10}
                    />
                  </>
                )}
              </div>
            </BoxStyle>
          </DashboardBoxStyle>
        </>
      )}
    </>
  );
};
