import React, { useEffect, useRef, useState } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";

export const Calender = () => {
  const [selectedDays, setSelectedDays] = useState([
    {
      day: 6,
      month: 1,
      year: 2023,
    },
    {
      day: 1,
      month: 1,
      year: 2023,
    },
  ]);

  return (
    <Calendar
      value={selectedDays}
      onChange={setSelectedDays}
      colorPrimary="#FFA303"
      colorPrimaryLight="FFA303"
      inputPlaceholder="Select a day"
      inputClassName="my-custom-input-class"
      shouldHighlightWeekends
    />
  );
};
