import React from "react";
import user from "assets/image/user.png";

import "./ScoreCard.css"
import Moment from "react-moment";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
const ScoreCard = ({ data }) => {
 const profile = Cookies.get("profileImg")
  // console.log(data)
  const navigate = useNavigate()
  const totalscore = (data) => {
    let newdata = data?.filter((data2) => data2.body !== null)
    let score = newdata?.map((data2) => data2?.body['Total Score'])
    const res = score.reduce((a, b) => a + b, 0)
    // debugger
    // console.log((res / newdata.length).toFixed(2));
    return (res / newdata.length).toFixed(2) === "NaN" ? 0 : (res / newdata.length).toFixed(2)
  }
  return (
    <div onClick={()=>{navigate(`/interviewee/interview-video/${data?._id}`)}} className="interview-score-card">
      <div className="candidate-image">
        <img src={profile} alt="user" />
      </div>
      <div className="candidate-details">
        <div>
          <h6>Date : <Moment format="DD/MM/YYYY" date={data?.createdAt} />
          </h6>
          <p>{totalscore(data?.results)}/100</p>
        </div>
      </div>
    </div>
  );
};

export default ScoreCard;
