import React from 'react'
import "./Report.css"
import CardTop from "assets/image/Rectangle 3.png"
import { Box, LinearProgress, Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
const ReportCardNull = (props) => {

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress style={{
            height: "10px", borderRadius: "5px", background: "#f4f4f4"
          }} variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)
            }%`}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <>
    
      {/* <div className="res-box col-12  mb-0">
        <div className="res-bottom">
        </div>
        <div className=' px-3 pb-3'>
          <div className="card-head d-flex justify-content-center">
            <h6>Video Interview Assesment</h6>
          </div>
          <div className="que-des my-2">Question No {props?.index+1||1}:- {props?.question?.question||"Question"}</div>
         <h6>
            Report not Generated
         </h6>
          
        </div>
        
        <div className="res-bottom">
        </div>
      </div> */}


      {/* <div className="res-box col-12  mb-0">
       
        <div className="res-bottom">
        </div>
        <div className=' px-3 pb-3'>
          <div className="card-head d-flex justify-content-center">
            <h6>Video Interview Assesment</h6>
          </div>
          <div className="que-des my-2">Question No {props?.index+1||1}:- {props?.question?.question||"Question"}</div>
          <div className="d-flex gap-2 ">
            <div className="col-12 report-box p-2">
              <h6>Professionalism</h6>
              <ul class="skill-list">

                <li class="skill">
                  <h3>Confidence (0%)</h3>
                  <progress class="skill-1" max="100" value="0">
                    <strong>Skill Level: 50%</strong>
                  </progress>
                </li>

              

                <li class="skill">
                  <h3>Optimistic (0%)</h3>
                  <progress class="skill-3" max="100" value="0">
                    <strong>Skill Level: 25%</strong>
                  </progress>
                </li>

                <li class="skill">
                  <h3>Passion & Enthusiasm (0%)</h3>
                  <progress class="skill-1" max="100" value="0">
                    <strong>Skill Level: 50%</strong>
                  </progress>
                </li>

                <li class="skill">
                  <h3>Industry Keywords (0%)</h3>
                  <progress class="skill-2" max="100" value="0">
                    <strong>Skill Level: 75%</strong>
                  </progress>
                </li>

              

                <li class="skill">
                  <h3>Clarity (0%)</h3>
                  <progress class="skill-1" max="100" value="0">
                    <strong>Skill Level: 25%</strong>
                  </progress>
                </li>
              </ul>
            </div>
           
          </div>
          <div className='res-detail'>
            <div>
              <h6>Communication</h6>

            </div>
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Fluency Report  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>
          
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Articulation  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>
            <div>
              <h6>Video</h6>

            </div>
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Sociability  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Facial Expression  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Energy Level </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>
            <div>
              <h6>Speech</h6>

            </div>
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Pace  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Clarity  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className='col-3 mb-0'>Sentiments  </p>
              <div className="col-9">
                <Box sx={{ width: '100%' }}>
                  <LinearProgressWithLabel value="0" />
                </Box>
              </div>
            </div>

          </div>
        </div>
        <div className="report-card-tips px-3">
          {props?.question?.suggestedAnswer && <p><span className='font-weight-bold'>Suggested Answer :</span> {props?.question?.suggestedAnswer} </p>}
          {props?.question?.keyword && <p><span className='font-weight-bold'>Keyword :</span> {props?.question?.keyword} </p>}
        </div>
        <div className="res-bottom">
        </div>
      </div> */}
    </>
  )
}

export default ReportCardNull