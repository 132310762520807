import { Card } from "components/Card/CardStyles";
import {
  ButtonBlue,
  ButtonYellowOutline,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import React from "react";
import { CreateInterviewMockData } from "./contants/data";
import { Link, useNavigate } from "react-router-dom";
import { MdLocationDisabled } from "react-icons/md";
import podcast from "assets/podcast.png";
import { BackButton } from "components/common/BackButton";

export const PublishInterview = ({ type }) => {
  const navigate = useNavigate();
  return (
    <DashboardBoxStyle className="scroller">
      <div className="container my-3">
        <div className="d-flex align-items-center">
          <BackButton />
          <YellowHeading className="my-2 mx-2">
            Published Templates
          </YellowHeading>
        </div>
        <div>
          <div className="py-3 my-2 ">
            <div className="col-12">
              <div className="row gx-2">
                {CreateInterviewMockData?.slice(0, 4)?.map((item) => (
                  <div className="col-3 mb-3" key={item.id}>
                    <Link to={`${item.id}`}>
                      <Card style={{ height: "400px" }}>
                        <div className="card-image-wrapper create-interview-image">
                          <img src={item.image} />
                        </div>
                        <div className="card-content-wrapper my-3">
                          <h4 className="card-title-2">{item.name}</h4>
                          <p className="card-description-2 pt-1">
                            {item.description}
                          </p>
                        </div>
                        <div className="card-footer-wrapper mt-2">
                          <div className="card-footer d-flex justify-content-between">
                            <div className="card-footer-left">
                              <ButtonBlue
                                className="secondary  px-3 h-40 d-flex align-items-center justify-content-center"
                                width="93%"
                              >
                                Published{" "}
                                <img
                                  src={podcast}
                                  height={18}
                                  className="mx-2"
                                />
                              </ButtonBlue>
                            </div>
                            <div className="card-footer-right">
                              <ButtonBlue
                                width="93%"
                                className="secondary-2 px-1 h-40 d-flex align-items-center justify-content-center"
                              >
                                Unpublish{" "}
                                <MdLocationDisabled className="mx-1" />
                              </ButtonBlue>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardBoxStyle>
  );
};
