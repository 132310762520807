import { AiFillBook, AiFillHome } from "react-icons/ai";
import {
  MdGroup,
  MdContacts,
  MdSchool,
  MdFeedback,
  MdOutlineHelp,
} from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";

import { create_UUID } from "helper/uuid";

export const SidePanelDataInterviewer = [
  {
    id: create_UUID(),
    name: "Dashboard",
    path: "/hire-pro/dashboard",
    icon: <AiFillHome />,
  },
  {
    id: create_UUID(),
    name: "Create Interview",
    path: "/hire-pro/create-interview",
    icon: <AiFillBook />,
  },
  {
    id: create_UUID(),
    path: "/hire-pro/my-interviews",
    name: "Interview Posting",
    icon: <MdSchool />,
  },
  // {
  //   id: create_UUID(),
  //   path: "/hire-pro/candidates",
  //   name: "Candidates",
  //   icon: <MdGroup />,
  // },
  {
    id: create_UUID(),
    path: "/hire-pro/profile",
    name: "Profile",
    icon: <BsFillPersonFill />,
  },
  // {
  //   id: create_UUID(),
  //   path: "/hire-pro/plans",
  //   name: "Plans & Subscription",
  //   icon:<BsFillPersonFill />,
  // },
  {
    id: create_UUID(),
    path: "/hire-pro/support-feedback",
    name: "Support & Feedback",
    icon: <MdFeedback />,
  },
  // {
  //   id: create_UUID(),
  //   path: "/hire-pro/help",
  //   name: "Help & Documentation",
  //   icon: <MdOutlineHelp />,
  // },
];
