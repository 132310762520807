import { SidepanelDataSuperAdmin } from "contants/SidepanelDataSuperAdmin";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { SidebarAndHeaderSuperAdmin } from "screen/SidebarAndHeaderSuperAdmin";

const SuperADminIndex = () => {
  const navigate = useNavigate();
  let loginToken =  Cookies.get("token")
  useEffect(() => {
    if(!loginToken){
      navigate('/')
    }
  }, [loginToken])
  return <SidebarAndHeaderSuperAdmin data={SidepanelDataSuperAdmin} />;
};

export default SuperADminIndex;
