import React, { useEffect, useState } from "react";
import { Offcanvas, Row } from "react-bootstrap";
import { IoIosMail, IoMdNotifications } from "react-icons/io";
import { NavbarStyled } from "./NavbarStyles";
import user from "assets/image/user.png";
import "./Navbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AiFillHome, AiOutlineMenu } from "react-icons/ai";
import logo from "assets/image/logo.svg";
import { GetAdmin } from "../../axios/apis";
import { Toaster, toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { SidepanelDataSuperAdmin } from "contants/SidepanelDataSuperAdmin";
import Cookies from "js-cookie";

export const NavbarAdmin = ({ updateprofile }) => {
  const location = useLocation();
  const name = Cookies.get("name");
  const role = Cookies.get("role");
  const navigate = useNavigate()
  const email = Cookies.get("email");
  const profileImg = Cookies.get("profileImg");
  var myDate = new Date();
  var hrs = myDate.getHours();

  const [greet, setGreet] = useState("Good Morning");
  useEffect(() => {
    if (hrs < 12) setGreet("Good Morning");
    else if (hrs >= 12 && hrs <= 17) setGreet("Good Afternoon");
    else if (hrs >= 17 && hrs <= 24) setGreet("Good Evening");
  }, []);
  const [show, setShow] = useState(false);
  const [userDetail, setUserDetail] = useState({})
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      navigate("/");
    }
  };
  const GetAdminDetails = async () => {
    try {
      const response = await GetAdmin();
      setUserDetail(response?.data?.data);
      // console.log(response);
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
    }
  };
  useEffect(() => {
    GetAdminDetails();
  }, [updateprofile]);
  return (
    <>
      <Toaster />
      <NavbarStyled className={`${role === "corporate" ? "bg-purple" : ""
        }`}>
        <div className="nav-sec-1 d-none-968">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <h4 className="nav-head">Hi, {userDetail?.name}</h4>

            </div>

          </div>
          <Row>
            <div className="d-flex align-items-center mt-2">
              <IoIosMail size={24} />
              <p className="nav-para">{userDetail?.email}</p>
            </div>
          </Row>
        </div>
        <div className="nav-sec-1 d-flex-968">
          <AiOutlineMenu onClick={handleShow} className="c-pointer" size={30} />
          <Offcanvas className="sidebar-968 " show={show} onHide={handleClose}>
            <Offcanvas.Header className="py-1">
              <div className="m-auto">

                <img style={{ width: "160px" }} src={logo} />
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body className="bg-purple-sidebar">
              <div className="px-3 py-4">
                <div className="min-height-sidebar">
                  {SidepanelDataSuperAdmin.slice(0, 5).map((item) => (
                    <NavLink
                      onClick={handleClose}

                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`
                        d-flex align-items-center py-2 wrapper__sidebar bg-purple`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2 color-white">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <hr />
                  {SidepanelDataSuperAdmin.slice(5, 8).map((item) => (
                    <NavLink
                      onClick={handleClose}

                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`d-flex align-items-center py-2 wrapper__sidebar bg-purple`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2 color-white">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <a className="sidebar__link" href="https://admin.viosa.in/">
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar

                  cursor-pointer bg-purple"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading px-2 mt-2 color-white">Go To Dashboard</h6>
                    </div>

                  </a>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="d-flex justify-content-end align-items-center nav-sec-2">
          {/* <div className={`${role === "corporate" ? "bg-purple" : ""
            } renew`}>
            <h6>Renew</h6>{" "}
          </div> */}
          <NavLink to={"/superadmin/notifications"} className={`nav-notification ${location.pathname === "/notifications" ? "active" : ""} ${role === "corporate" ? "bg-purple" : ""
            }`}>
            <IoMdNotifications size={28} />
          </NavLink>
          {/* <div className="user-img-cover">
            <img className="user-img" src={userDetail?.profileImg} alt="user" />
          </div> */}
        </div>
      </NavbarStyled>
    </>
  );
};
