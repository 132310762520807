import React from "react";
import "./support.css";

const SupportCard = ({ name, icon }) => {
  return (
    <>
      <div className="help-box">
        <div className="logo my-4">{icon}</div>
        <div className="title mb-2">
          <h5>{name}</h5>
        </div>
        <div className="description">
          <p>All the information</p>
          <p>About the dashboards</p>
        </div>
      </div>
    </>
  );
};

export default SupportCard;
