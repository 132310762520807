import React, { useEffect, useRef, useState } from "react";
import {
  BoxStyle,
  ButtonBlue,
  ButtonPurpleOutline,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import user from "assets/image/userDummy.jpeg";
import "./profile.css";
import { Form, Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import {
  GetIntervieweeDetails,
  IntervieweePasswordUpdate,
  UpdateIntervieweeProfile,
} from "../../../axios/apis";
import Moment from "react-moment";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";

export const Profile = ({ updateprofile, setupdateprofile }) => {
  const [showpassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateloading, setupdateLoading] = useState(false);
  const [shownewpassword, setShowNewPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [userDetail, setUserDetail] = useState({
    email: "",
    firstname: "",
    lastname: "",
    linkedin: "",
    phoneNumber: "",
    profileImage: "",
    resume: "",
    gender: "",
    coverletter: "",
    specialization: "",
  });
  const [passwordChange, setPasswordChange] = useState({
    password: "",
    newpassword: "",
    confirmnewpass: "",
  });
  const handleChange = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };
  const handlePasswordChange = (e) => {
    setPasswordChange({ ...passwordChange, [e.target.name]: e.target.value });
  };

  const UploadInput = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [loadingiconupload, setLoadingIconupload] = useState(null);

  const uploadFile = async (e) => {
    if (e.target.files[0]) {
      setLoafingFileUpload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoafingFileUpload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoafingFileUpload(true);

      axios
        .post(`${rootUrl}/v2/upload/pdf`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          setUserDetail({ ...userDetail, [e.target.name]: data.data.link });
          setLoafingFileUpload(false);
          toast.success("File Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          setLoafingFileUpload(false);
        });
    }
  };

  const [loadingfileUpload, setLoafingFileUpload] = useState(false);

  const uploadIcon = async (e) => {
    console.log("Selected file:", e.target.files[0]);
    if (e.target.files[0]) {
      setLoadingIconupload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          console.log("Upload success:", data.data.link);
          setUserDetail({ ...userDetail, [e.target.name]: data.data.link });
          setLoadingIconupload(false);
          toast.success("Image Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          console.error("Upload error:", error);
          setLoadingIconupload(false);
        });
    }
  };

  const removeProfilePhoto = async () => {
    setUserDetail({ ...userDetail, profileImage: "" });
    try {
      const res = await UpdateIntervieweeProfile({ profileImage: "" });
      setupdateLoading(false);
      setupdateprofile(!updateprofile);

      // console.log(res)
      Cookies.set("profileImage", "");
      toast.success("Profile Image Removed Successfully", ToastStyles);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setupdateLoading(false);
    }
  };
  // console.log(userDetail);

  const UploadDetails = async (e) => {
    e.preventDefault();
    console.log(e.target);
    // console.log(userDetail);
    setupdateLoading(true);
    let data = {
      firstname: userDetail.firstname,
      lastname: userDetail.lastname,
      linkedin: userDetail.linkedin,
      phoneNumber: userDetail.phoneNumber,
      profileImage: userDetail.profileImage,
      resume: userDetail.resume,
      gender: userDetail.gender,
      coverletter: userDetail.coverletter,
      specialization: userDetail.specialization,
    };

    try {
      const res = await UpdateIntervieweeProfile(data);
      setupdateLoading(false);
      setupdateprofile(!updateprofile);
      // console.log(res)
      Cookies.set("name", res?.data?.message?.firstname);
      Cookies.set("email", res?.data?.message?.email);
      Cookies.set("profileImage", res?.data?.message?.profileImage);
      toast.success("Updated Successfully", ToastStyles);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setupdateLoading(false);
    }
  };

  const GetUser = async () => {
    setLoading(true);
    try {
      const res = await GetIntervieweeDetails();
      // console.log(res);
      setUserDetail(res?.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetUser();
  }, []);

  // update password
  const [passwordloading, setpasswordLoading] = useState(false);

  const updatePassword = async (e) => {
    e.preventDefault();
    if (passwordChange.newpassword !== passwordChange.confirmnewpass) {
      toast.error("Passwords do not match", ToastStyles);
    } else if (passwordChange.newpassword === passwordChange.password) {
      toast.error("Current and New Password Can't be same.", ToastStyles);
    } else {
      setpasswordLoading(true);
      try {
        let data = {
          oldpassword: passwordChange.password,
          password: passwordChange.newpassword,
        };
        const res = await IntervieweePasswordUpdate(data);
        toast.success("Password Updated Successfully", ToastStyles);
        setPasswordChange({
          password: "",
          newpassword: "",
          confirmnewpass: "",
        });
        setpasswordLoading(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 400) {
          toast.error("Current Password is Incorrect 😣", ToastStyles);
        } else {
          toast.error("Try Again 😣", ToastStyles);
        }
        setpasswordLoading(false);
      }
    }
  };

  return (
    <>
      <Toaster />
      {loading ? (
        <>
          <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
            <Loading />
          </DashboardBoxStyle>
        </>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container profile-page">
            <BoxStyle className="d-flex justify-content-between align-items-center px-sm-4 px-2">
              <div className="d-none-768 w-100">
                <YellowHeading>Profile</YellowHeading>
                <div className="d-flex align-items-center justify-content-end">
                  <p className="m-0 me-3">
                    Joining Date -{" "}
                    <Moment format="DD/MM/YYYY">{userDetail?.createdAt}</Moment>
                  </p>
                  {/* <ButtonBlue className="h-40 px-4">
                  <b>Level 1</b>
                </ButtonBlue> */}
                </div>
                <h6>Update your account’s profile information</h6>
              </div>
              <div className="d-flex-768 w-100 flex-column ">
                <div className="d-flex justify-content-between align-items-center">
                  <YellowHeading>Profile</YellowHeading>
                  <p className="m-0 ">
                    Joining Date -{" "}
                    <Moment format="DD/MM/YYYY">{userDetail?.createdAt}</Moment>
                  </p>
                </div>
                <h6>Update your account’s profile information</h6>
              </div>
            </BoxStyle>
            <BoxStyle className="mt-2 bg-none padding-res">
              <div>
                <div className="row profile-sec-1 px-0">
                  <div className="profile-card ">
                    <div className="d-flex align-items-center image-box-res px-3 mt-3">
                      <img
                        src={userDetail?.profileImage || user}
                        style={{ marginRight: "20px" }}
                        className="rounded-circle"
                        width={80}
                        height={80}
                        alt=""
                      />
                      <div className="d-flex flex-column w-100-525">
                        {loadingiconupload ? (
                          <ButtonPurpleOutline className="mb-2">
                            {percentage}% Uploading...
                          </ButtonPurpleOutline>
                        ) : (
                          <ButtonPurpleOutline
                            onClick={(e) => {
                              e.preventDefault();
                              UploadInput.current.click();
                            }}
                            className="mb-2 h-auto"
                          >
                            SELECT A NEW PHOTO
                          </ButtonPurpleOutline>
                        )}
                        <input
                          ref={UploadInput}
                          type="file"
                          hidden
                          name="profileImage"
                          onChange={uploadIcon}
                        />
                        <ButtonPurpleOutline
                          onClick={removeProfilePhoto}
                          className="h-auto"
                          type="none"
                        >
                          REMOVE PHOTO
                        </ButtonPurpleOutline>
                      </div>
                    </div>
                    <div className="p-4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Name</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="firstname"
                          required
                          value={userDetail.firstname}
                          onChange={handleChange}
                          // placeholder="Deepa"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="lastname"
                          value={userDetail.lastname}
                          onChange={handleChange}
                          // placeholder="Chawan"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Email</Form.Label>
                        <Form.Control
                          name="email"
                          value={userDetail.email}
                          disabled
                          onChange={handleChange}
                          className="form-input"
                          type="email"
                          required
                          // placeholder="abc@gmail.com"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="phone"
                          name="phoneNumber"
                          required
                          value={userDetail.phoneNumber}
                          onChange={handleChange}
                          // placeholder=""
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className=" profile-card">
                    <div className="p-4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Specialization - major/minor
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="specialization"
                          value={userDetail.specialization}
                          onChange={handleChange}
                          // placeholder="Economics"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">linkedin</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="linkedin"
                          value={userDetail.linkedin}
                          onChange={handleChange}
                          placeholder="linkedin URL"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Gender</Form.Label>

                        <Form.Select
                          id="disabledSelect"
                          name="gender"
                          value={userDetail.gender}
                          onChange={handleChange}
                          className="form-input"
                        >
                          <option default disabled value="">
                            Select Gender
                          </option>
                          <hr />
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Resume</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="file"
                          // value={userDetail.resume}
                          name="resume"
                          onChange={uploadFile}
                          placeholder="Upload Resume"
                        />
                        {userDetail?.resume && (
                          <p
                            style={{ color: "green" }}
                            className="form-title mb-0"
                          >
                            Uploaded
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Cover Letter
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="file"
                          name="coverletter"
                          onChange={uploadFile}
                          placeholder="Upload Cover Letter"
                        />
                        {userDetail?.coverletter && (
                          <p style={{ color: "green" }} className="form- mb-0">
                            Uploaded
                          </p>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-end mt-4">
                        {updateloading ? (
                          <>
                            <ButtonBlue className="h-40 " width="150px">
                              <Spinner animation="border" size="sm" />
                            </ButtonBlue>
                          </>
                        ) : (
                          <>
                            <ButtonBlue
                              className="h-40 "
                              width="150px"
                              onClick={UploadDetails}
                            >
                              Save
                            </ButtonBlue>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BoxStyle>
            {/* <BoxStyle className="d-flex  align-items-center my-3 px-sm-4 px-2">
              <div>
                <YellowHeading>Update Password</YellowHeading>
                <h6>
                  Ensure your account is using a long, random password to stay
                  secure.
                </h6>
              </div>
            </BoxStyle>
            <BoxStyle className="mb-3 bg-none padding-res">
              <div className="row profile-sec-1 px-0 justify-content-end">
                <div className="profile-card ">
                  <Form onSubmit={updatePassword} className="p-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Current Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type={showpassword ? "text" : "password"}
                        name="password"
                        minLength={8}
                        required
                        value={passwordChange.password}
                        onChange={handlePasswordChange}
                        placeholder="Current Password"
                      />
                      {showpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        New Password
                      </Form.Label>
                      <Form.Control
                        name="newpassword"
                        value={passwordChange.newpassword}
                        onChange={handlePasswordChange}
                        className="form-input"
                        minLength={8}
                        required
                        type={shownewpassword ? "text" : "password"}
                        placeholder="New Password"
                      />
                      {shownewpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowNewPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowNewPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type={showconfirmpassword ? "text" : "password"}
                        name="confirmnewpass"
                        value={passwordChange.confirmnewpass}
                        minLength={8}
                        required
                        onChange={handlePasswordChange}
                        placeholder="Re-enter Password"
                      />
                      {showconfirmpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowConfirmPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowConfirmPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                      {passwordloading ? (
                        <ButtonBlue className="h-40" style={{ width: "160px" }}>
                          <Spinner animation="border" size="sm" />
                        </ButtonBlue>
                      ) : (
                        <ButtonBlue
                          className="h-40 "
                          style={{ width: "160px" }}
                          type="submit"
                        >
                          Save
                        </ButtonBlue>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </BoxStyle> */}
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
