import React from "react";
import "./Vcard.css"
import { Link } from "react-router-dom";
import sample from "assets/sample.mp4";
const RecommendedVcard = ({ item }) => {
 
  return (
    <div className="p-2 rcard" key={item?.id}>
      <div>
        <div className="d-flex align-items-center">
          <div className="me-2">
            <img className="rcard-img " src={item?.poster || sample} />
          </div>
          <div className="card-content-wrapper">
            <h6 className="card-title">{item?.title}</h6>
            <p className="card-description pt-1 mb-0">{item?.description?.slice(0,60)}...</p>
          </div>
          {/* <div className="card-footer-wrapper mt-2">
            <div className="card-footer d-flex justify-content-between">
              <div className="card-footer-left w-100">
                <ButtonBlue width="90%">{item.button}</ButtonBlue>
              </div>
              <div className="card-footer-right">
                <ButtonYellowOutline>
                  <img src={item.icon} />
                </ButtonYellowOutline>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RecommendedVcard;
