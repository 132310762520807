import { Table } from "components/Table/Table";
import {
  BoxStyle,
  ButtonBlue,
  DashboardBoxStyle,
  DarkHeading,
  Paragraph,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { MdClose, MdDone } from "react-icons/md";
import { Toaster, toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { CollegeSignUp, DeleteCollegeById, GetCollegesAll, UpdateCollegeById } from "../../axios/apis";
import Loading from "components/common/Loading";
import { Form, Modal, Spinner } from "react-bootstrap";

export const Colleges = () => {

  const [loading, setLoading] = useState(false)
  const [interview, setInverView] = useState({
    expiry: true,
    renewed: false,
    newapplication: false,
  });
  const [colleges, setcolleges] = useState([])

  const GetAllColleges = async () => {
    setLoading(true);
    try {
      const response = await GetCollegesAll();
      console.log(response);
      setcolleges(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  const AllowAccess = async (e) => {
    console.log(e)
    const res = window.confirm("Are you sure you want to Approve?");
    if (res) {
      setLoading(true);
      try {
        const data = {
          id: e._id,
          status: true,
        }
        const response = await UpdateCollegeById(data);
        GetAllColleges()
        console.log(response);
        toast.success("Approved Successfully", ToastStyles)
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setLoading(false);
      }
    }
  }
  const DeleteRequest = async (e) => {
    console.log(e)
    const res = window.confirm("Are you sure you want to Reject and Delete?");
    if (res) {
      setLoading(true);
      try {
        const data = {
          id: e._id,
        }
        const response = await DeleteCollegeById(data);
        console.log(response);
        GetAllColleges()
        toast.error("Deleted Successfully", ToastStyles)
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    GetAllColleges();
  }, []);
  const columns = [
    {
      field: "profileImg",
      headerName: "",
      minWidth: 100,
      renderCell: (params) => (
        <div>
          <img src={params?.value} height={40} width={40} alt="profile" />
        </div>
      ),
    },
    {
      field: "collegename",
      headerName: "College Name",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 130,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 130,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 170,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 170,
      renderCell: (params) =>
        params.value === true ? (
          <div className="mx-auto">

            <ButtonBlue className="table-button px-4 mx-0">Active</ButtonBlue>
          </div>

        ) : (
          <div className="mx-auto">

            <ButtonBlue className="table-button-danger px-4 mx-0">
              Stopped
            </ButtonBlue>
          </div>
        ),
    },
    {
      field: "share",
      headerName: "Access",
      minWidth: 140,
      renderCell: (params) =>
        params.row.status === true ? (
          <div className="mx-auto">
            🟢
          </div>
        ) : (
          <>
            <div onClick={() => { AllowAccess(params.row) }} className="circle-border-50 mx-2">
              <MdDone color="#219653" fontSize={24} />
            </div>
            <div onClick={() => { DeleteRequest(params.row) }} className="circle-border-50 mx-2">
              <MdClose color="#EB5757" fontSize={24} />
            </div>
            {/* <div className="circle-border-50 mx-2">
              <MdMoreHoriz color="#521986" fontSize={24} />
            </div> */}
          </>
        ),
    },
  ];

  const [collegeDetails, setCollegeDetails] = useState({
    name: "",
    email: "",
    password: "",
  })

  const [collegeModalLoading, setCollegeModalLoading] = useState(false)
  const handleCollegeDetails = (e) => {
    setCollegeDetails({ ...collegeDetails, [e.target.name]: e.target.value })
  }
  const [showCollege, setShowCollege] = useState(false);
  const addNewCollege = async (e) => {
    e.preventDefault()
    setCollegeModalLoading(true)
    const formdata = {
      password: collegeDetails?.password,
      email: collegeDetails?.email,
      role: "college",
      collegename: collegeDetails?.name,
    };
    try {
      // debugger;
      const res = await CollegeSignUp(formdata);
      if (res?.data?.status === 400) {
        alert("Email already Exist");
      }
      else if (res?.data?.status === 500) {
        alert("Account already Exist");

      } else {
        GetAllColleges()
        alert("Account Created SuccessFully")

      }
    } catch (error) {
      alert(error.response.data.message)
    }
    setCollegeModalLoading(false);
  }

  return (
    <>
      <Toaster />
      {/* Add College Modal */}
      <Modal show={showCollege} onHide={() => setShowCollege(false)}>
        <Modal.Header closeButton>
          <DarkHeading>Add College</DarkHeading>
        </Modal.Header>
        <Modal.Body className="job-form mx-2">

          <Form className="px-3 pb-2" onSubmit={addNewCollege}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Name</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="name"
                required
                value={collegeDetails.name}
                onChange={handleCollegeDetails}
                placeholder="Deepa"
              />
            </Form.Group>

            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Email</Form.Label>
              <Form.Control
                name="email"
                required
                value={collegeDetails.email}
                onChange={handleCollegeDetails}
                className="form-input"
                type="email"
                placeholder="abc@gmail.com"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Password</Form.Label>
              <Form.Control
                className="form-input"
                type="password"
                name="password"
                required
                value={collegeDetails.password}
                onChange={handleCollegeDetails}
                placeholder=""
              />
            </Form.Group>
            {/* <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Password</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="password"
                value={studentDetails.password}
                onChange={handlestudentdetails}
                placeholder=""
              />
            </Form.Group> */}
            <hr />
            {collegeModalLoading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add New College
              </ButtonBlue>
            )}
          </Form>
        </Modal.Body>
      </Modal>

      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <BoxStyle className="my-1 py-3 px-4 mb-3">
              <div className="">
                <YellowHeading className="mb-0 fs-30">
                  All Your College Profile
                </YellowHeading>
                <br />
                <Paragraph className="mb-0 mt-0">
                  Search and keep track of your Colleges{" "}
                </Paragraph>
              </div>
              {/* <MultiStepProgress /> */}
            </BoxStyle>
            {/* <div className="d-flex justify-content-between">
              <div className="col-9">
                <Wrapper className="w-100 border-10 border-1">
                  <SearchInput placeholder="Search" className="border-10" />
                  <GridSearchIcon
                    style={{ position: "absolute", right: "20px", top: "20px" }}
                  />
                </Wrapper>
              </div>
              <Select className="border-10 h-60 mx-2">
                <option value="1">All</option>
                <option value="2">Active</option>
              </Select>
            </div> */}
            <div className="d-flex justify-content-end my-2">
              <ButtonBlue onClick={() => setShowCollege(true)} className="px-4">
                Add College
              </ButtonBlue>
            </div>
            {!interview.favoriteCourses && (
              <BoxStyle className="my-2 py-1 px-2 mb-3">
                {/* <Paragraph className="fs-20 mx-2 my-2">
                  Colleges &nbsp;
                  <MdContacts color={"#521986"} fontSize={24} />
                </Paragraph> */}
                {/* <div className="d-flex">
                  <div className="relative">
                    <ButtonBlue
                      onClick={() =>
                        setInverView({
                          newapplication: false,
                          renewed: false,
                          expiry: true,
                        })
                      }
                      className={`px-4 mx-2 h-40 ${interview.expiry ? "active" : "disabled"
                        }`}
                    >
                      Expiring
                    </ButtonBlue>
                    {interview.expiry && <div className="expiry-counter">1</div>}
                  </div>
                  <ButtonBlue
                    className={`px-4 mx-2 h-40 ${interview.renewed ? "active" : "disabled"
                      }`}
                    onClick={() =>
                      setInverView({
                        newapplication: false,
                        expiry: false,
                        renewed: true,
                      })
                    }
                  >
                    Renewed
                  </ButtonBlue>
                  <ButtonBlue
                    className={`px-4 mx-2 h-40 ${interview.newapplication ? "active" : "disabled"
                      }`}
                    onClick={() =>
                      setInverView({
                        expiry: false,
                        renewed: false,
                        newapplication: true,
                      })
                    }
                  >
                    New Application
                  </ButtonBlue>
                </div> */}

                <Table
                  columns={columns}
                  data={colleges.map((item) => {
                    return {
                      ...item,
                      id: item._id,
                      key: item._id,
                    };
                  })}
                  limit={10}
                />
              </BoxStyle>
            )}
          </div>
        </DashboardBoxStyle>)}
    </>
  );
};
