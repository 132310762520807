import React from "react";
import cardsample from "assets/image/cardsample.png";
import { BsBagFill, BsFillFileEarmarkFill } from "react-icons/bs";
import { ButtonBlue, ButtonYellowFill } from "components/common/CommonStyles";
import "./StudentCard.css";
import { Form } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { TbNumbers } from "react-icons/tb";
import { MdOutlineTimelapse } from "react-icons/md";
import { useNavigate } from "react-router";
export const TeamCard = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className="student-card mb-3 p-3">
      {/* <img src={cardsample} alt="candidate" /> */}
      <div className="my-3 px-2">
        <div className="d-flex mb-2 justify-content-between">
          <h5 className="title">{data?.name}</h5>
          {/* <Form.Check className="checkbox" type="checkbox" /> */}
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <AiFillCheckCircle />
            <p className="px-2">{data?.studyclass} Class</p>{" "}
          </div>
          {/* <div className="d-flex">
            <MdOutlineTimelapse />
            <p className="px-2">{data?.year} Year</p>{" "}
          </div> */}
          <div className="d-flex">
            <AiFillCheckCircle />
            <p className="px-2">{data?.students?.length} Students</p>{" "}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          {/* <div className="d-flex">
            <BsBagFill />
            <p className="px-2">{data?.standard} Standard</p>{" "}
          </div> */}
          {/* <div className="d-flex">
            <TbNumbers />
            <p className="px-2">{data?.students?.length} Students</p>{" "}
          </div> */}
        </div>

        {/* <p className="mt-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat</p> */}
      </div>
      <div className="px-2">
        <ButtonYellowFill
          onClick={() => {
            navigate(`${data?._id}`);
          }}
          className="h-40 "
        >
          View Team
        </ButtonYellowFill>
      </div>
    </div>
  );
};
