import React, { useEffect, useState } from "react";

import "../../Interviewer/Dashboard/Dashboard.css";
import { BsFillSaveFill } from "react-icons/bs";

import {
  MdFileCopy,
  MdGroup,
  MdHowToReg,
  MdHowToVote,
  MdPeople,
  MdPersonAdd,
} from "react-icons/md";
import { MdNotificationsActive } from "react-icons/md";
import {
  BoxStyle,
  DarkHeading,
  DashboardBoxStyle,
  Select,
} from "components/common/CommonStyles";

import "swiper/css";
import "swiper/css/pagination";

import { Multicolorbar } from "components/Bar/Multicolorbar";
import { ToastStyles } from "components/common/ToastStyle";
import { getAdminDashboardReq, getCandidateStats, getInterviewerStats } from "../../../axios/apis";
import { toast } from "react-hot-toast";
import Loading from "components/common/Loading";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [statisticData, setStatisticData] = useState([])
  const [statisticData2, setStatisticData2] = useState([])
  const [dashboardData, setDashboardData] = useState({})

  const GetData = async () => {
    setLoading(true)
    try {
      const res1 = await getAdminDashboardReq();
      setDashboardData(res1?.data?.data)
      const res2 = await getInterviewerStats();
      setStatisticData(res2?.data?.data)
      const res3 = await getCandidateStats();
      setStatisticData2(res3?.data?.data)
      console.log(res1, res2, res3)

    } catch (error) {
      console.log(error)
      toast.error("Try Again", ToastStyles)
    }
    setLoading(false)
  }
  useEffect(() => {
    GetData()
  }, [])
  console.log(dashboardData)
  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className=" scroller">
          <div>
            <div className="card1-container my-3 ">
              <div className="dashboard-card2 ">
                <div className="col-12">
                  <div className="d-flex align-items-center mb-2">
                    <MdGroup size={26} color=" #521986" />
                    <h6 className="card1-head mb-0">&nbsp; No of Users</h6>
                  </div>
                  <DarkHeading>{dashboardData?.totalUsers}</DarkHeading>
                </div>
                {/* <div className="col-3">
                  <Select className="border-10">
                    <option value="All">All</option>
                    <option value="Today">Today</option>
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                  </Select>
                </div> */}
              </div>
              <div className="dashboard-card2">
                <div className="col-12">
                  <div className="d-flex align-items-center mb-2">
                    <MdPersonAdd size={26} color=" #521986" />
                    <h6 className="card1-head mb-0">
                      &nbsp; Candidate Interviewed
                    </h6>
                  </div>
                  <DarkHeading>{dashboardData?.candidateInterviewed}</DarkHeading>
                </div>
                {/* <div className="col-3">
                  <Select className="border-10">
                    <option value="All">All</option>
                    <option value="Today">Today</option>
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                  </Select>
                </div> */}
              </div>
              <div className="dashboard-card2">
                <div className="col-12">
                  <div className="d-flex align-items-center mb-2">
                    <MdFileCopy size={26} color=" #521986" />
                    <h6 className="card1-head mb-0">&nbsp; Resume Received</h6>
                  </div>
                  <DarkHeading>{dashboardData?.resumeRecieved}</DarkHeading>
                </div>
                {/* <div className="col-3">
                  <Select className="border-10">
                    <option value="All">All</option>
                    <option value="Today">Today</option>
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                  </Select>
                </div> */}
              </div>
              <div className="dashboard-card2">
                <div className="col-12">
                  <div className="d-flex align-items-center mb-2">
                    <MdHowToVote size={26} color=" #521986" />
                    <h6 className="card1-head mb-0">&nbsp; Job posted</h6>
                  </div>
                  <DarkHeading>{dashboardData?.jobPosted}</DarkHeading>
                </div>
                {/* <div className="col-3">
                  <Select className="border-10">
                    <option value="All">All</option>
                    <option value="Today">Today</option>
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                  </Select>
                </div> */}
              </div>
              <div className="dashboard-card2">
                <div className="col-12">
                  <div className="d-flex align-items-center mb-2">
                    <MdPeople size={26} color=" #521986" />
                    <h6 className="card1-head mb-0">&nbsp; Interview Scheduled</h6>
                  </div>
                  <DarkHeading>{dashboardData?.interviewScheduled}</DarkHeading>
                </div>
                {/* <div className="col-3">
                  <Select className="border-10">
                    <option value="All">All</option>
                    <option value="Today">Today</option>
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                  </Select>
                </div> */}
              </div>
              <div className="dashboard-card2">
                <div className="col-12">
                  <div className="d-flex align-items-center mb-2">
                    <MdHowToReg size={26} color=" #521986" />
                    <h6 className="card1-head mb-0">&nbsp; Total Selection</h6>
                  </div>
                  <DarkHeading>{dashboardData?.totalSelection}</DarkHeading>
                </div>
                {/* <div className="col-3">
                  <Select className="border-10">
                    <option value="All">All</option>
                    <option value="Today">Today</option>
                    <option value="This Week">This Week</option>
                    <option value="This Month">This Month</option>
                  </Select>
                </div> */}
              </div>
            </div>
            <div className="dashboard-sec-2 px-2">
              <div className=" my-3 col-12">
                <BoxStyle className="padding-5 ">
                  <DarkHeading className=" p-3">
                    Statistics of Interviewers
                  </DarkHeading>
                  <div className="m-3">
                    <Multicolorbar data={statisticData} />
                  </div>
                  <DarkHeading className=" p-3">
                    Statistics of Candidates
                  </DarkHeading>
                  <div className="m-3">
                    <Multicolorbar data={statisticData2} />
                  </div>
                </BoxStyle>
              </div>
              {/* <div className="dashboard-sec-2-2">
            <div className="reminders pb-3">
              <div className="d-flex justify-content-between align-content-center p-3">
                <h4>Reminders</h4>
                <MdNotificationsActive size={26} color=" #521986" />
              </div>
              <div className="reminder-msg-box">
                <div className="reminder-icon">
                  <BsFillSaveFill size={26} color="#521986" />
                </div>
                <div style={{ width: "50%" }} className="remainder-content">
                  <div>
                    <h6>5 Min Interview</h6>
                  </div>
                  <h6 style={{ color: "#ffa303" }}>New course</h6>
                </div>
                <div className="remainder-time">6:30AM</div>
              </div>
              <div className="reminder-msg-box">
                <div className="reminder-icon">
                  <BsFillSaveFill size={26} color="#521986" />
                </div>
                <div style={{ width: "50%" }} className="remainder-content">
                  <div>
                    <h6>UI Designer</h6>
                  </div>
                  <h6 style={{ color: "#ffa303" }}>New Interview</h6>
                </div>
                <div className="remainder-time">6:30AM</div>
              </div>
              <div className="reminder-msg-box">
                <div className="reminder-icon">
                  <BsFillSaveFill size={26} color="#521986" />
                </div>
                <div style={{ width: "50%" }} className="remainder-content">
                  <div>
                    <h6>UX Designer </h6>
                  </div>
                  <h6 style={{ color: "#ffa303" }}>New Interview</h6>
                </div>
                <div className="remainder-time">6:30AM</div>
              </div>
              <div className="reminder-msg-box">
                <div className="reminder-icon">
                  <BsFillSaveFill size={26} color="#521986" />
                </div>
                <div style={{ width: "50%" }} className="remainder-content">
                  <div>
                    <h6>Complete the Interview</h6>
                  </div>
                  <h6 style={{ color: "#ffa303" }}>Complete Goal</h6>
                </div>
                <div className="remainder-time">6:30AM</div>
              </div>
              <div className="reminder-msg-box">
                <div className="reminder-icon">
                  <BsFillSaveFill size={26} color="#521986" />
                </div>
                <div style={{ width: "50%" }} className="remainder-content">
                  <div>
                    <h6>Recent Interview Report</h6>
                  </div>
                  <h6 style={{ color: "#ffa303" }}>Download</h6>
                </div>
                <div className="remainder-time">6:30AM</div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </DashboardBoxStyle>)}
    </>
  );
};
