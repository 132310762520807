export const mockDataInvoices = [
  {
    id: 35,
    name: "Car",
    email: "cbridel0@symantec.com",
    Job: "Technical Writer",
  },
  {
    id: 2742,
    name: "Diane",
    email: "dnarducci1@photobucket.com",
    Job: "Statistician II",
  },
  {
    id: 121,
    name: "Itch",
    email: "ienever2@nifty.com",
    Job: "Senior Sales Associate",
  },
  {
    id: 800,
    name: "Teriann",
    email: "tgauchier3@wsj.com",
    Job: "Registered Nurse",
  },
  {
    id: 116,
    name: "Terrie",
    email: "tgillon4@multiply.com",
    Job: "Senior Sales Associate",
  },
  {
    id: 14,
    name: "Gusti",
    email: "gdimblebee5@geocities.com",
    Job: "Senior Sales Associate",
  },
  {
    id: 48,
    name: "Joycelin",
    email: "jpickwell6@mail.ru",
    Job: "Marketing Assistant",
  },
  {
    id: 68,
    name: "Chico",
    email: "cbrizell7@cpanel.net",
    Job: "Librarian",
  },
  {
    id: 3217,
    name: "Bertie",
    email: "bboyfield8@merriam-webster.com",
    Job: "Chemical Engineer",
  },
  {
    id: 500,
    name: "Benedikta",
    email: "bkopfer9@purevolume.com",
    Job: "Director of Sales",
  },
  {
    id: 1633,
    name: "Fionna",
    email: "fcowpa@tuttocitta.it",
    Job: "Assistant Manager",
  },
  {
    id: 98,
    name: "Abel",
    email: "asallingsb@topsy.com",
    Job: "Internal Auditor",
  },
  {
    id: 9,
    name: "Lanette",
    email: "lduhamelc@usatoday.com",
    Job: "Sales Associate",
  },
  {
    id: 1478,
    name: "Sibel",
    email: "salexanderd@microsoft.com",
    Job: "VP Product Management",
  },
  {
    id: 1805,
    name: "Ced",
    email: "cstrettlee@unblog.fr",
    Job: "Recruiter",
  },
  {
    id: 1605,
    name: "Bernie",
    email: "bplewsf@nbcnews.com",
    Job: "Desktop Support Technician",
  },
  {
    id: 1195,
    name: "Lilly",
    email: "lmotiong@craigslist.org",
    Job: "Staff Scientist",
  },
  {
    id: 10,
    name: "Petronia",
    email: "phanwrighth@free.fr",
    Job: "Information Systems Manager",
  },
  {
    id: 1396,
    name: "Marleah",
    email: "mgarmani@gravatar.com",
    Job: "Information Systems Manager",
  },
  {
    id: 16,
    name: "Winfield",
    email: "wveillardj@e-recht24.de",
    Job: "Quality Control Specialist",
  },
  {
    id: 3299,
    name: "Leland",
    email: "ljiruchk@tripod.com",
    Job: "Librarian",
  },
  {
    id: 4780,
    name: "Skipp",
    email: "sfreckinghaml@fc2.com",
    Job: "VP Accounting",
  },
];
