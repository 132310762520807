import {
  ButtonBlue,
  Input,
} from "components/common/CommonStyles";
import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible, AiFillLock, AiOutlineNumber, AiOutlineUser } from "react-icons/ai";

import { MdOutlineEmail, MdOutlinePassword, MdOutlineQrCodeScanner } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { ForgetPasswordOtp, ResetPasswordForget, VerifyOtpPassword } from "../../axios/apis";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";


export const ForgetPassword = ({ setSelect }) => {
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");
  const [loading, setLoading] = useState(false)
  const [otpsent, setOtpSent] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)
  const [updatePassword, setUpdatePassword] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [showpassword, setShowPassword] = useState(false);

  // const getOtp = () => {
  //   if (data.mobileNo === "" || data.mobileNo.length < 10) {
  //     alert("Please Enter a valid Number");
  //     return;
  //   }
  //   let appVerifier = window.recaptchaVerifier;
  //   signInWithPhoneNumber(auth, "+91" + data.mobileNo, appVerifier)
  //     .then((result) => {
  //       setfinal(result);
  //       alert("code sent");
  //       setshow(true);
  //     })
  //     .catch((err) => {
  //       alert(err);
  //       window.location.reload();
  //     });
  // };

  // useEffect(() => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: getOtp,
  //     },
  //     auth
  //   );
  // }, []);
  // const ValidateOtp = () => {
  //   console.log(data.otp)
  //   if (data.otp === null || final === null) return;
  //   final
  //     .confirm(data.otp)
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((err) => {
  //       alert("Wrong code");
  //     });
  // };
  const [data, setData] = React.useState({
    email: "",
    otp: "",
    password: "",
  });
  const inputHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const getOtp = async () => {
    setLoading(true)
    try {
      const res = await ForgetPasswordOtp({ email: data.email })
      setOtpSent(true);
      setLoading(false)
      // toast.success("Otp Sent Successfully")
    } catch (error) {
      // console.log(error.response.data.message)
      alert(error.response.data.message)
      setLoading(false)
    }
  }
  const ValidateOtp = async () => {
    setOtpLoading(true)
    try {
      const res = await VerifyOtpPassword({ email: data.email, otp: data.otp })
      // console.log(res)
      setOtpLoading(false)
      if (res.data.message === "Code Verified!") {
        setUpdatePassword(true)
      }
      else {
        alert("Otp Verification Failed")
      }
      // setOtpSent(true);
      // toast.success("Otp Validated Successfully")
    } catch (error) {
      alert(error.response.data.message)
      setOtpLoading(false)
    }
  }
  const UpdatePassword = async () => {
    setPasswordLoading(true)
    try {
      const res = await ResetPasswordForget({ email: data.email, password: data.password })
      // console.log(res)
      setPasswordLoading(false)

      if (res.data.message === "Password changed successfully") {
        alert("Password changed successfully. Login again to Continue")
        setSelect(0)
      }
      else {
        alert(res.data.message)
      }
      // setOtpSent(true);
      // toast.success("Otp Validated Successfully")
    } catch (error) {
      alert(error.response.data.message)
      setPasswordLoading(false);
    }
  }
  return (
    <>
      <ToastContainer />
      <div style={{ minHeight: "200px" }} className="mb-3 mt-3">
        {
          updatePassword ? (<>
            <div className="d-flex align-items-center">
              <MdOutlineEmail
                className="input-icon"
                color="#ffa303"
                size={28}
              />
              <Input
                placeholder="Enter Registered Email"
                name="email"
                type="email"
                value={data.email.email}
                readOnly
                className="mb-3 px-5 br-5"
              // onChange={(e) => inputHandler(e)}
              ></Input>

            </div>
            <div className="d-flex align-items-center ">
              <AiFillLock className="input-icon" color="#ffa303" size={28} />
              <Input
                placeholder="Password"
                name="password"
                autoComplete="password"
                minLength={8}
                className="mb-3 px-5 br-5 password"
                type={showpassword ? "text" : "password"}
                required
                onChange={(e) => inputHandler(e)}
              ></Input>
              {showpassword ? (
                <AiFillEyeInvisible
                  onClick={() => setShowPassword(false)}
                  className="password-eye pe-1"
                  color="#ffa303"
                  size={28}
                />
              ) : (
                <AiFillEye
                  onClick={() => setShowPassword(true)}
                  className="password-eye pe-1"
                  color="#ffa303"
                  size={28}
                />
              )}
            </div>

            <div className="d-flex align-items-center">

              <ButtonBlue disabled={passwordLoading} className="w-100 mb-3 h-40 box-shadow" onClick={() => UpdatePassword()}>
                {passwordLoading ? (<Spinner size="sm" animation="border" />) : ("Update")}
              </ButtonBlue>
            </div>
          </>) : (<>
            <div className="d-flex align-items-center">
              <MdOutlineEmail
                className="input-icon"
                color="#ffa303"
                size={28}
              />
              <Input
                placeholder="Enter Registered Email"
                name="email"
                type="email"
                required
                className="mb-3 px-5 br-5"
                onChange={(e) => inputHandler(e)}
              ></Input>
            </div>
            {/* <div id="recaptcha-container"></div> */}
            <ButtonBlue disabled={loading} className="w-100 mb-3 h-40 box-shadow" onClick={() => getOtp()}>
              {loading ? (<Spinner size="sm" animation="border" />) : ("Get Otp")}
            </ButtonBlue>
            {/* <p>Enter The OTP sent to your Registered Email</p> */}
            {
              otpsent ? (<>
                <p style={{ fontSize: "12px" }}>Enter the Otp Sent to your Registered Email</p>
                <div className="d-flex align-items-center">
                  <AiOutlineNumber className="input-icon" color="#ffa303" size={28} />
                  <Input
                    placeholder="Enter Otp"
                    name="otp"
                    type="text"
                    className="mb-3 px-5 br-5"
                    onChange={(e) => inputHandler(e)}
                  ></Input>
                </div>

                <div className="d-flex justify-content-end mb-2">
                  <ButtonBlue
                    color="white"
                    className="w-100 h-40 box-shadow px-3"
                    onClick={ValidateOtp}
                    disabled={otpLoading}
                  >
                    {otpLoading ? (<Spinner size="sm" animation="border" />) : ("Submit")}
                  </ButtonBlue>
                </div>
              </>) : ("")
            }
          </>)
        }


      </div>
    </>
  );
};
