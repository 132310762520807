import {
  BoxStyle,
  ButtonBlue,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./interview.css";
import {
  DeleteInterviewById,
  GetAllCreatorInterview,
  GetAllCreatorInterviewByLimit,
  SearchInterview,
  GetAllCollegeInterviewByLimit,
} from "../../../axios/apis";
import { Table } from "components/Table/Table";
import Moment from "react-moment";
import { Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Loading from "components/common/Loading";
import { MdClose } from "react-icons/md";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import { Input } from "@mui/material";
import Cookies from "js-cookie"; // Ensure you've installed js-cookie

export const MyInterview = ({ type }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [interview, setInterView] = useState([]);
  const [page, setPage] = useState(1);
  const [totalpages, setTotalpages] = useState(1);
  const collegeId = Cookies.get("collegeID"); // Assuming 'collegeId' is the name of your cookie
  console.log("collegeid", collegeId);
  const arr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  ];
  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllCollegeInterviewByLimit({
        page: page,
        limit: 10,
        title: "",
      });
      setTotalpages(response.data.totalpages);
      setInterView(response.data.interviews);
      // console.log(response.data.interviews);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  const DeleteInterview = async (e) => {
    // console.log(e)
    const res = window.confirm("Are you sure you want to Delete Interview?");
    if (res) {
      setLoading(true);
      try {
        const data = {
          id: e._id,
        };
        const response = await DeleteInterviewById(data);
        // console.log(response);
        GetAllInterviews();
        toast.error("Deleted Successfully", ToastStyles);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    GetAllInterviews();
  }, [page]);

  const columns = [
    {
      field: "jobtitle",
      headerName: "ROLE",
      minWidth: 200,
      renderCell: (data) => (
        <div
          className="d-flex
        align-items-center "
          onClick={() => {
            // console.log(data);
          }}
        >
          {data?.value}
        </div>
      ),
    },
    {
      field: "jobcategory",
      headerName: "JOB Category",
      minWidth: 200,
      // renderCell: (data) => (
      //   <div
      //     className="d-flex
      //   align-items-center ms-4"
      //     onClick={(e) => {
      //       console.log(e);
      //     }}
      //   >
      //     {data?.value}
      //   </div>
      // ),
    },
    {
      field: "createdAt",
      headerName: "CREATED ON",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="DD-MM-YYYY">{data.value}</Moment>
        </div>
      ),
    },
    {
      field: "expirydate",
      headerName: "EXPIRY DATE",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {" "}
          <Moment format="DD-MM-YYYY">{data.value}</Moment>
        </div>
      ),
    },
    // {
    //   field: "a",
    //   headerName: "STUDENTS",
    //   minWidth: 150,
    //   renderCell: (data) => (
    //     <div className="d-flex align-items-center ">{data?.row?.candidates?.length}</div>
    //   ),
    // },
    {
      field: "interviewResult",
      headerName: "RESPONSES",
      minWidth: 150,
      // renderCell: (params) => (
      //   <div className="d-flex align-items-center">
      //     {params?.value?.length || 0}
      //   </div>
      // ),
      renderCell: (params) => {
        // Assuming that interviewResult contains an array of results with attemptedby_id populated
        const results = params.row.interviewResult;
        // console.log("Detailed interview results with user details:", results);
        // console.log("user details:", params);

        // Get the stored collegeId from cookies
        const storedCollegeId = Cookies.get("collegeID");
        // console.log("Stored College ID:", storedCollegeId);

        // Filter and count only those results where attemptedby_id's collegeid matches the stored collegeId
        const count = results.reduce((acc, result) => {
          const userCollegeId = result.attemptedby_id.collegeid;
          // console.log(
          //   `Checking if user's collegeId ${userCollegeId} matches stored collegeId ${storedCollegeId}:`,
          //   userCollegeId === storedCollegeId
          // );
          return userCollegeId === storedCollegeId ? acc + 1 : acc;
        }, 0);

        console.log(
          `Count of matching users for interview ${params.id}: ${count}`
        );
        return <div className="d-flex align-items-center">{count || 0}</div>;
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 70,
      renderCell: (params) => (
        <div className="d-flex align-items-center justify-content-center">
          {params.value === "Scheduled" ? "🟢" : "🔴"}
        </div>
      ),
    },
    {
      field: "",
      headerName: "ACTION",
      minWidth: 70,
      renderCell: (data) => (
        <div
          onClick={() => navigate(`/college/my-interviews/${data.row._id}`)}
          className="d-flex align-items-center text-hover"
        >
          View more
        </div>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      minWidth: 70,
      renderCell: (data) => (
        <div
          onClick={() => {
            DeleteInterview(data.row);
          }}
          className="circle-border-50 mx-2"
        >
          <MdClose color="#EB5757" fontSize={24} />
        </div>
      ),
    },
  ];

  // search functionality
  const [search, setsearch] = useState("");
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setsearch(value);
  };
  const handleSearchSubmit = async () => {
    try {
      const response = await SearchInterview({
        name: search,
      });
      setInterView(response.data.interviews);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <div className="my-2 border-10">
              <div className="d-flex justify-content-between">
                <YellowHeading className="mb-0" style={{ lineHeight: "50px" }}>
                  My Interviews
                </YellowHeading>

                {/* <ButtonBlue
                  className="px-3"
                  onClick={() => navigate("/college/create-interview/new")}
                >
                  Create new interview
                </ButtonBlue> */}
              </div>
              <div className="gap-4 d-flex align-items-center">
                <Input
                  placeholder="Enter interview name"
                  className="h-65  px-2 border rounded-2"
                  name="keyword"
                  value={search}
                  onChange={handleSearchChange}
                />
                <button
                  onClick={handleSearchSubmit}
                  className="common-btn p-1 px-4 rounded-2 "
                  style={{ background: "#6f42c1", color: "white" }}
                >
                  Search
                </button>
              </div>
              {/* <MultiStepProgress /> */}
            </div>

            <Table
              columns={columns}
              data={interview.map((item) => {
                return {
                  ...item,
                  id: item._id,
                  key: item._id,
                };
              })}
              data2={interview}
              limit={10}
              checkbox={false}
            />
          </div>
          <MDBPagination center className="my-1">
            {arr.slice(0, totalpages).map((data, i) => (
              <MDBPaginationItem active={page === i + 1}>
                <MDBPaginationLink onClick={() => setPage(i + 1)} href="#">
                  {i + 1}
                </MDBPaginationLink>
              </MDBPaginationItem>
            ))}
          </MDBPagination>
        </DashboardBoxStyle>
      )}
    </>
  );
};
