import { ThemeProvider as TP } from "styled-components";

const theme = {
  white: "#ffff",
  primary: "#FFA303",
  primaryLight: "#FFA303",
  primaryDark: "#A76900",
  secondary: "#521986",
  lightBlue1: "#F0DBFF",
  success: "#27AE60",
  darkBlack: "rgba(16, 0, 43, 1)",
  lightGrey: "rgba(166, 160, 176, 1)",
  shadow1:
    "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
  white100: "rgba(255, 255, 255, 1)",
  blue100: "linear-gradient(90deg, #2E77AC 0%, #42ADC6 100%)",
  lightBlue: "  rgba(200, 231, 240, 1)",
  bluedark: "rgba(0, 56, 116, 1)",
  border20: "20px",
  danger: "#BA1A1A",
  pinkLight: "#F0DBFF",
  fontFamily1: "'Inter', sans-serif",
  fontFamily2: "'Lato', sans-serif",
  linearGradient: "linear-gradient(180deg, #F6EDFF 0%, #FFFFFF 100%)",
  boxShadow: " 0px 4px 10px #F5ECFF",
  boxShadow3: "0px 4px 8px rgba(0, 0, 0, 0.16)",
  boxShadow4: "0px 5px 15px rgba(170, 135, 166, 0.6)",
  boxShadow2:
    "0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3);",
  slate: "#808285",
};

export const ThemeProvider = ({ children }) => (
  <TP theme={theme}>{children}</TP>
);
