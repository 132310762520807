import React, { useCallback, useEffect, useState } from "react";
import { FaUserFriends } from "react-icons/fa";
import Lefticon from "../../../assets/image/lefticon.svg";
import Righticon from "../../../assets/image/righticon.svg";
import "./Dashboard.css";
import { RiVideoAddFill } from "react-icons/ri";
import { BsFillSaveFill, BsPatchCheckFill } from "react-icons/bs";
import sample from "assets/sample.mp4";
import { MdOutlineTimelapse } from "react-icons/md";
import { MdNotificationsActive } from "react-icons/md";
import { SayButton } from "react-say";
import { Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap
import {
  DashboardBoxStyle,
  YellowHeading,
  ButtonBlue,
} from "components/common/CommonStyles";
import JolPlayer from "jol-player";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper";
import DashboardVcard from "components/Card/DashboardVcard";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  GetAllInterviewAdmin,
  getDashboardReq,
  getStudentDashboardReq,
  GetIntervieweeDetails,
  GetStudentByBatchId,
  FetchSingleCourse,
} from "../../../axios/apis";
import { Spinner } from "react-bootstrap";
import { CardData } from "../data";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";
import { Toaster } from "react-hot-toast";

export const Dashboard = () => {
  const [viewWidth, setViewWidth] = useState("");
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [interview, setInterView] = useState([]);
  const navigate = useNavigate();
  const [isPurchased, setIsPurchased] = useState(false); // State to control purchase status
  const [Popup, setPopupVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const userID = localStorage.getItem("userId");
  // console.log("user ID:", userID);
  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllInterviewAdmin();
      setInterView(response?.data?.interviews);
      // console.log(response.data.interviews);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setViewWidth(window.innerWidth);
  }, [window.innerWidth]);

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const res = await getDashboardReq();
      console.log("res", res);
      setDashboardData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getStudentDashboard = async () => {
    try {
      setLoading(true);
      const res = await getStudentDashboardReq();
      setDashboardData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const params = useParams();
  useEffect(() => {
    if (params?.id) {
      Cookies.set("token", params.id);
      Cookies.set("role", "interviewee");
    }
    // window.location.reload()
    if (window.location.pathname === "/interviewee/dashboard") {
      getDashboardData();
      GetAllInterviews();
    }
    if (window.location.pathname === "/college-student/dashboard") {
      getStudentDashboard();
      GetAllInterviews();
    }
  }, []);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const checkCoursePurchase = async () => {
      try {
        setLoading(true);
        const response = await GetIntervieweeDetails();
        console.log("response user details", response);
        if (!response || !response.data) {
          console.error("Failed to fetch user details");
          return;
        }
        const user = response.data.message;
        console.log("User details:", user);
        // Step 2: Extract batch IDs from the user details
        const teams = user?.teams || [];
        if (teams.length === 0) {
          console.log("No teams found for the user");
          setShowPopup(true);
          return;
        }
        // Step 3: Fetch batch details using GetStudentByBatchId API
        const batchDetailsPromises = teams.map((teamId) =>
          GetStudentByBatchId(teamId)
        );
        const batchDetailsResponses = await Promise.all(batchDetailsPromises);

        const courseIds = batchDetailsResponses.flatMap(
          (response) => response?.data?.data?.course || []
        );
        console.log("Course IDs extracted from batches:", courseIds);

        if (courseIds.length === 0) {
          console.log("No courses found in batches");
          setShowPopup(true); // If no courses, show the popup
          return;
        }
        // Step 4: Fetch course details using FetchSingleCourse API
        const courseDetailsPromises = courseIds.map((courseId) =>
          FetchSingleCourse(courseId, userId)
        );
        const courseDetailsResponses = await Promise.all(courseDetailsPromises);

        const fullCourseDetails = courseDetailsResponses.map(
          (response) => response.data.courses
        );
        console.log("Full course details fetched:", fullCourseDetails);

        // Step 5: Check if any course has the name "InterviewPro"
        const hasInterviewProCourse = fullCourseDetails.some(
          (course) => course.name === "Interview Pro"
        );

        if (!hasInterviewProCourse) {
          setShowPopup(true); // Show popup if "InterviewPro" course is not found
        } else {
          console.log(
            "User has purchased the InterviewPro course. No popup will be shown."
          );
        }
      } catch (error) {
        console.error("Error in checkCoursePurchase:", error);
        setShowPopup(true); // Show popup if there's an error in fetching the data
      } finally {
        setLoading(false); // Stop loading
      }
    };
    checkCoursePurchase();
  }, []);
  const handlePurchaseClick = () => {
    window.location.href = "https://viosa.in/course/interview-pro";
  };

  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className=" scroller">
          <div
            className="container px-0
            "
          >
            {/* <h5 className="text-center my-1  pb-2">
              “A person who never made a mistake never tried anything new."
              —Albert Einstein
            </h5> */}

            <div className="card1-container mt-3">
              <Link
                to={"/interviewee/practice-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Total Interviews</h6>

                  <h6>{dashboardData?.totalInterviews}</h6>
                </div>
                <div className="col-2">
                  <BsPatchCheckFill size={26} color=" #521986" />
                </div>
              </Link>
              <Link
                to={"/interviewee/my-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Total interview attempts</h6>
                  <h6>{dashboardData?.attemptedInterviews}</h6>
                </div>
                <div className="col-2">
                  <FaUserFriends size={26} color=" #521986" />
                </div>
              </Link>
              {/* <div className="dashboard-card1 ">
                  <div className="col-10">
                    <h6 className="card1-head">My Company Interviews</h6>
                    <h6>5/10</h6>
                  </div>
                  <div className="col-2">
                    <TiTick size={26} color=" #521986" />
                  </div>
                </div> */}
              <Link
                to={"/interviewee/my-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Total Time Spent</h6>
                  <h6>{Math.round(dashboardData?.totalMinutes)} Minutes</h6>
                </div>
                <div className="col-2">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </Link>
              <Link
                to={"/interviewee/my-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Average Interview Score</h6>
                  <h6>{Math.round(dashboardData?.averageScore)}/100</h6>
                </div>
                <div className="col-2">
                  <RiVideoAddFill size={26} color=" #521986" />
                </div>
              </Link>
              {/* <div className="dashboard-card1 ">
                  <div className="col-10">
                    <h6 className="card1-head">Saved Videos</h6>
                    <h6>150</h6>
                  </div>
                  <div className="col-2">
                    <BsFillSaveFill size={26} color=" #521986" />
                  </div>
                </div> */}

              {/* <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">ATS Complaint Resume</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">Apply Multiple Jobs</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">Career Path</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">Upgrade your plan</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div> */}
            </div>
            <div className="dashboard-sec-2 ">
              <div className=" w-100-820">
                <div className="overview-video ">
                  <YellowHeading className=" p-3 d-none-525">
                    How to use this Tool?
                  </YellowHeading>
                  <div className="video-player dashboard mx-4 ">
                    {" "}
                    <video
                      className="w-100 pb-4"
                      src="https://interview-bot.s3.ap-south-1.amazonaws.com/interview+bot/Interview+Pro/product+demonstration/Interview+Pro.mp4"
                      alt="Tool video"
                      controls
                    />
                  </div>
                </div>
              </div>
              {/* <div className="dashboard-sec-2-2 d-none-820">
                <div className="reminders">
                  <div className="d-flex justify-content-between align-content-center p-3">
                    <h4>Reminders</h4>
                    <MdNotificationsActive size={26} color=" #521986" />
                  </div>
                  <div className="reminder-box-scroll">
                    {interview?.length > 0 ? (
                      <>
                        {interview
                          ?.reverse()
                          ?.slice(0, 5)
                          ?.map((data, i) => (
                            <div
                              onClick={() => {
                                navigate("/interviewee/practice-interview");
                              }}
                              key={i}
                              className="reminder-msg-box c-pointer"
                            >
                              <div className="reminder-icon">
                                <BsFillSaveFill size={22} color="#521986" />
                              </div>
                              <div
                                style={{ width: "70%" }}
                                className="remainder-content"
                              >
                                <div>
                                  <h6>{data?.jobtitle}</h6>
                                  <p style={{ color: "#521986" }}>
                                    (Mock Interview)
                                  </p>
                                </div>
                                <h6 style={{ color: "#ffa303" }}>
                                  New Interview
                                </h6>
                              </div>
                            </div>
                          ))}
                      </>
                    ) : (
                      <>
                        <h6 className="mx-2 px-2">No Reminders</h6>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
            <div className="dashboard-sec-2 mb-3">
              <div className="dashboard-sec-2-1 w-full">
                <div className="recommended-video d-none-525">
                  <div className="d-flex justify-content-between">
                    <YellowHeading className="p-3 pb-0">
                      Recommended Videos
                    </YellowHeading>
                    <div className="p-3">
                      <img
                        src={Lefticon}
                        alt="letf"
                        className="arrow-cover me-2"
                        id="swiper-back"
                      />
                      <img
                        src={Righticon}
                        alt="right"
                        className="arrow-cover"
                        id="swiper-forward"
                      />
                    </div>
                  </div>
                  <div className="card-slider p-3 pt-0 pe-0">
                    <Swiper
                      slidesPerView={"auto"}
                      spaceBetween={20}
                      // autoplay={{
                      //   delay: 3000,
                      //   disableOnInteraction: false,
                      // }}

                      navigation={{
                        nextEl: "#swiper-forward",
                        prevEl: "#swiper-back",
                      }}
                      modules={[Autoplay, Navigation]}
                      className="mySwiper pb-3 pe-3 pt-0"
                    >
                      {CardData.map((data) => (
                        <SwiperSlide>
                          <DashboardVcard data={data} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="d-flex-525 flex-column gap-2">
                  {CardData.map((data) => (
                    <DashboardVcard data={data} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          {showPopup && (
            <div className="popup-overlay">
              <div className="popup-content">
                <h2>Purchase Required</h2>
                <p>
                  Since you have not purchased InterviewPro, please purchase it
                  using the button below.
                </p>
                <ButtonBlue className="px-3" onClick={handlePurchaseClick}>
                  Go to Purchase
                </ButtonBlue>
              </div>
            </div>
          )}
        </DashboardBoxStyle>
      )}
    </>
  );
};
