import React, { useState } from "react";
import {
  BoxStyle,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import { Form } from "react-bootstrap";
import "./support.css";
import { AiFillBook, AiFillHome, AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";
import { MdGroup, MdContacts, MdSchool, MdFeedback } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";
import SupportCard from "./SupportCard";
import { BiBot } from "react-icons/bi";
import { IoMdNotifications } from "react-icons/io";

export const HelpDocumentation = () => {
  const [search, setSearch] = useState("");
  const [searchOption, setSearchOption] = useState("");
  return (
    <DashboardBoxStyle className="scroller">
      <div className="container">
        <BoxStyle className="d-flex justify-content-between align-items-center">
          <div>
            <YellowHeading>Help & Documentation</YellowHeading>
            <h6>Are you Lost? No worries i am here to help you</h6>
          </div>
          <div className="d-flex align-items-center"></div>
        </BoxStyle>
        <BoxStyle className="d-flex  my-3 flex-column">
          <div className="d-flex ">
            <YellowHeading>Search Your Query</YellowHeading>
          </div>
          <div className="search-box-flex col-12 gap-2">
            <Form.Group className="col-9">
              <Form.Control
                type="text"
                className="search-box form-input"
                name="search"
                value={search}
                placeholder="Search Jobs"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="col-3">
              <Form.Select
                id="disabledSelect"
                name="searchOption"
                value={searchOption}
                onChange={(e) => setSearchOption(e.target.value)}
                className="form-input search-box"
              >
                <option default disabled value="">
                  Filter
                </option>
                <hr />
                <option value="Dashboard">
                Dashboard
                </option>
                <option value="Interview Course">
                Interview Course
                </option>
                <option value="Practice Interview">
                Practice Interview
                </option>
                <option value="Job Interview">
                Job Interview
                </option>
                <option value="My Level">
                My Level
                </option>
                <option value="Profile">
                Profile
                </option>
                <option value="Login">
                Login
                </option>
                <option value="Logout">
                Logout
                </option>
                <option value="Notifications">
                Notifications
                </option>
                <option value="Support & Feedback">
                Support & Feedback
                </option>
                <option value="Chat Bot">
                Chat Bot
                </option>
              </Form.Select>
            </Form.Group>
          </div>
        </BoxStyle>

        <div className="help-box-cards mb-4">
          <SupportCard name="Dashboard" icon=<AiFillHome color="#602a94" size={36}/> />
          <SupportCard name="Interview Course" icon=<AiFillBook color="#602a94" size={36}/> />
          <SupportCard name="Practice Interview" icon=<MdGroup color="#602a94" size={36}/> />
          <SupportCard name="Job Interview" icon=<MdContacts color="#602a94" size={36}/>/>
          <SupportCard name="My Level" icon=<MdSchool color="#602a94" size={36}/> />
          <SupportCard name="Profile" icon=<BsFillPersonFill color="#602a94" size={36}/> />
          <SupportCard name="Login" icon=<AiOutlineLogin color="#602a94" size={36}/>/>
          <SupportCard name="Logout" icon = <AiOutlineLogout color="#602a94" size={36}/>/>
          <SupportCard name="Notifications" icon=<IoMdNotifications color="#602a94" size={36}/>/>
          <SupportCard name="Support & Feedback" icon=<MdFeedback color="#602a94" size={36}/>/>
          <SupportCard name="Chat Bot" icon=<BiBot color="#602a94" size={36}/>/>
          
        </div>
      </div>
    </DashboardBoxStyle>
  );
};
