import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import {
  BoxStyle,
  ButtonYellowFill,
  ButtonYellowOutline,
  DarkHeading,
  DashboardBoxStyle,
} from "components/common/CommonStyles";
import "swiper/css";
import "swiper/css/pagination";
import InterviewPageScoreCard from "components/Card/InterviewPageScoreCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { GetSingleInterview, InterviewReport } from "../../../axios/apis";
import { Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import job from "assets/image/job.png";
import time from "assets/image/time.png";
import stock from "assets/image/stock.png";
import tick from "assets/image/tick.png";
import { ToastStyles } from "components/common/ToastStyle";
import JolPlayer from "jol-player";
import sample from "assets/sample.mp4";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Loading from "components/common/Loading";

export const InterviewVideo = () => {
  const navigate = useNavigate();
  const [resultdata, setresultdata] = useState([]);
  const [interviewQuestions, setinterviewQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [interview, setInterView] = useState([]);

  // console.log(userDetail);
  const params = useParams();
  const GetResult = async () => {
    setLoading(true);
    try {
      const res = await InterviewReport({
        id: params.id2,
      });
      const response = await GetSingleInterview({ id: params.id });
      // console.log(response);
      setUserDetail(res?.data?.data);
      setresultdata(res?.data?.data?.results);
      setinterviewQuestions(res?.data?.data?.interview_id?.interviewquestions);

      setInterView(response?.data?.interview?.interviewResult);
      // console.log(response?.data?.interview?.interviewResult)
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetResult();
  }, [params.id2]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // console.log(resultdata[activeStep]?.video);
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <div className="d-flex justify-content-between my-2">
              <div className="button-cotainer  ">
                <button className="button-yellow" onClick={() => navigate(-1)}>
                  <BiArrowBack />
                  <span>|</span>
                  Back
                </button>
              </div>
              <div className="button-cotainer  ">
                <button
                  className="button-yellow"
                  onClick={() => navigate(`/hire-pro/reports/${params.id2}`)}
                >
                  View Report
                </button>
              </div>
            </div>
            <div className="">
              <div className="col-12">
                <BoxStyle className="pt-3 my-3 px-3 padding-5 linear">
                  <div className="main-container interview-card">
                    <div className="row">
                      <div className="col-12">
                        <div className="col-12">
                          <div className="mb-1">
                            <div className="d-flex">
                              <div className="interview-logo">
                                <img
                                  className="rounded-circle"
                                  height={70}
                                  width={70}
                                  src={userDetail?.interview_id?.icon}
                                  alt={userDetail?.interview_id?.jobtitle}
                                />
                              </div>
                              <div className="mx-2">
                                <div className="">
                                  <DarkHeading className="px-2">
                                    {userDetail?.interview_id?.jobtitle}
                                  </DarkHeading>
                                  <div className="mb-3 d-none-525">
                                    <div className="d-flex">
                                      <div className="px-1 d-flex align-items-center">
                                        <img
                                          className="rounded-circle mx-1"
                                          height={17}
                                          width={17}
                                          src={tick}
                                          alt={tick}
                                        />
                                        <span
                                          className="fs-14"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {userDetail?.interview_id?.jobtype}
                                        </span>
                                      </div>
                                      <div className="px-1 d-flex align-items-center">
                                        <img
                                          className="rounded-circle mx-1"
                                          height={17}
                                          width={17}
                                          src={time}
                                          alt={time}
                                        />
                                        <span
                                          className="fs-14"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {
                                            userDetail?.interview_id
                                              ?.joblocation
                                          }
                                        </span>
                                      </div>
                                      <div className="px-1 d-flex align-items-center">
                                        <img
                                          className="rounded-circle mx-1"
                                          height={17}
                                          width={17}
                                          src={job}
                                          alt={job}
                                        />
                                        <span
                                          className="fs-14"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {userDetail?.interview_id?.experience}
                                          &nbsp;Years
                                        </span>
                                      </div>
                                      <div className="px-1 d-flex align-items-center">
                                        <img
                                          className="rounded-circle mx-1"
                                          height={17}
                                          width={17}
                                          src={stock}
                                          alt={stock}
                                        />
                                        <span
                                          className="fs-14"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {userDetail?.interview_id?.jobcity}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="my-2">
                            <div className="mb-3 d-flex-525">
                              <div className="w-100">
                                <div className="d-flex justify-content-centerw-100">
                                  <div className="px-1 d-flex align-items-center col-6">
                                    <img
                                      className="rounded-circle mx-1"
                                      height={17}
                                      width={17}
                                      src={tick}
                                      alt={tick}
                                    />
                                    <span
                                      className="fs-14"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {userDetail?.interview_id?.jobtype}
                                    </span>
                                  </div>
                                  <div className="px-1 d-flex align-items-center col-6">
                                    <img
                                      className="rounded-circle mx-1"
                                      height={17}
                                      width={17}
                                      src={time}
                                      alt={time}
                                    />
                                    <span
                                      className="fs-14"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {userDetail?.interview_id?.joblocation}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                  <div className="px-1 d-flex align-items-center col-6">
                                    <img
                                      className="rounded-circle mx-1"
                                      height={17}
                                      width={17}
                                      src={job}
                                      alt={job}
                                    />
                                    <span
                                      className="fs-14"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {userDetail?.interview_id?.experience}
                                      &nbsp;Years
                                    </span>
                                  </div>
                                  <div className="px-1 d-flex align-items-center col-6">
                                    <img
                                      className="rounded-circle mx-1"
                                      height={17}
                                      width={17}
                                      src={stock}
                                      alt={stock}
                                    />
                                    <span
                                      className="fs-14"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {userDetail?.interview_id?.jobcity}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </BoxStyle>
              </div>
            </div>
            {/* <div className="col-12">
              <BoxStyle className="py-1 px-0 my-1 bg-none">
                <div className="card-slider py-1 px-0">
                  <Swiper
                    slidesPerView={"auto"}
                    spaceBetween={20}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay]}
                    className="mySwiper "
                  >
                    {
                      interview?.map((data, i) =>
                        <SwiperSlide key={i}>
                          <InterviewPageScoreCard data={data} />
                        </SwiperSlide>)
                    }
                  </Swiper>
                </div>
              </BoxStyle>
            </div> */}
            <div className="my-2">
              <div className="main-container">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-4 col-12 mb-2">
                      <div className="border-10-yellow overflow-scroll-steps p-3">
                        <Box>
                          <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                          >
                            {interviewQuestions.map((step, index) => (
                              <Step key={step.label}>
                                <StepLabel
                                  optional={
                                    index === interviewQuestions.length - 1 ? (
                                      <Typography variant="caption">
                                        Last Question
                                      </Typography>
                                    ) : null
                                  }
                                >
                                  {step.question}
                                </StepLabel>
                                <StepContent>
                                  <Box sx={{ mb: 2 }}>
                                    <div>
                                      <ButtonYellowFill
                                        className="h-40 me-2"
                                        style={{ height: "40px" }}
                                        onClick={handleNext}
                                        width="60px"
                                      >
                                        {index === interviewQuestions.length - 1
                                          ? "Finish"
                                          : "Next"}
                                      </ButtonYellowFill>
                                      <ButtonYellowOutline
                                        onClick={handleBack}
                                        className="h-40"
                                        width="60px"
                                        disabled={index === 0}
                                      >
                                        Back
                                      </ButtonYellowOutline>
                                    </div>
                                  </Box>
                                </StepContent>
                              </Step>
                            ))}
                          </Stepper>
                          {activeStep === interviewQuestions.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                              <Typography>
                                All Questions are Completed -{" "}
                              </Typography>
                              <Button
                                onClick={handleReset}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                View Again
                              </Button>
                            </Paper>
                          )}
                        </Box>
                      </div>
                    </div>
                    <div className="col-md-8 col-12">
                      <div className="border-10-yellow overflow-hidden">
                        {/* <JolPlayer
                          option={{
                            height: 420,
                            mode: "scaleToFill",
                            width: "fit-content",
                            language: "en",
                            controls: true,
                            loop: true,
                            setBufferContent: "Loading...",
                            videoType: "video/mp4",
                            nextButton: true,
                            prevButton: true,
                            pausePlacement: "center",
                            isShowWebFullScreen: true,
                            isProgressFloat: true,
                            videoSrc: resultdata[activeStep]?.video || sample,
                          }}
                        /> */}
                        <video
                          alt="user video"
                          src={
                            resultdata[activeStep]?.video ||
                            resultdata[0]?.video
                          }
                          controls
                          className="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
