export const subDomain = [

   {
      value: "Web Developer",
      label: "Web Developer",
   },

   {label: "Software Engineer",
   value: "Software Engineer",},
  {label: "Data Scientist",
   value: "Data Scientist",},
 {label: "DevOps",
   value: "DevOps",},
  {label: "Frontend Developer",
   value: "Frontend Developer",},
   {label: "Backend Developer",
   value: "Backend Developer",},
  {label: "UI/UX Designer",
   value: "UI/UX Designer",},
   {label: "Full Stack Developer",
   value: "Full Stack Developer"},
   {label: "Mobile Developer",
   value: "Mobile Developer"},
   {label: "Game Developer",
   value: "Game Developer"},
   {label: "QA Engineer",
   value: "QA Engineer"},
   {label: "IT Manager",
   value: "IT Manager"},
   {label: "IT Support",
   value: "IT Support"},
   {label: "IT Consultant",
   value: "IT Consultant"},
   {label: "IT Specialist",
   value: "IT Specialist"},
   {label: "IT Analyst",
   value: "IT Analyst"},
  {label: "IT Executive",
   value: "IT Executive"},
   {label: "IT Assistant",
   value: "IT Assistant"},
   {label: "IT Coordinator",
   value: "IT Coordinator"},
   {label: "IT Technician",
   value: "IT Technician"},
   {label: "IT Engineer",
   value: "IT Engineer"},
   {label: "IT Security",
   value: "IT Security"},
   {label: "IT Auditor",
   value: "IT Auditor"},
   {label: "IT Director",
   value: "IT Director"},
   {label: "IT Administrator",
   value: "IT Administrator"},
   {label: "IT Developer",
   value: "IT Developer"},
   {label: "IT Architect",
   value: "IT Architect"},
   {label: "IT Specialist",
   value: "IT Specialist"},
   {label: "Flutter Developer",
   value: "Flutter Developer"},
   {label: "React Native Developer",
   value: "React Native Developer"},
   {label: "React Developer",
   value: "React Developer"},
   {label: "Angular Developer",
   value: "Angular Developer"},
   {label: "Node.js Developer",
   value: "Node.js Developer",},
   {label: "Vue.js Developer",
   value: "Vue.js Developer"},
   {label: "Python Developer",
   value: "Python Developer"},
   {label: "Android Developer",
   value: "Android Developer"},
   {label: "Java Developer",
   value: "Java Developer"},
   {label: "iOS Developer",
   value: "iOS Developer"},
   {label: "Ruby Developer",
   value: "Ruby Developer"},
   {label: "PHP Developer",
   value: "PHP Developer"},
   {label: "C# Developer",
   value: "C# Developer"},
   {label: "C++ Developer",
   value: "C++ Developer"},
   {label: "ASP.NET Developer",
   value: "ASP.NET Developer"},
   {label: "C Developer",
   value: "C Developer"},
  {label: "Django Developer",
   value: "Django Developer"},
   {label: "Laravel Developer",
   value: "Laravel Developer"},
   {label: "WordPress Developer",
   value: "WordPress Developer"},
   {label: "Magento Developer",
   value: "Magento Developer"},
   {label: "Salesforce Developer",
   value: "Salesforce Developer"},
   {label: "Drupal Developer",
   value: "Drupal Developer"},
   {label: "SharePoint Developer",
   value: "SharePoint Developer"},
   {label: "SQL Developer",
   value: "SQL Developer"},
   {label: "MySQL Developer",
   value: "MySQL Developer"},
   {label: "Oracle Developer",
   value: "Oracle Developer"},
   {label: "MongoDB Developer",
   value: "MongoDB Developer"},
   {label: "PostgreSQL Developer",
   value: "PostgreSQL Developer"},
   {label: "Database Developer",
   value: "Database Developer"},
   {label: "Database Administrator",
   value: "Database Administrator"},
   {label: "Database Architect",
   value: "Database Architect"},
   {label: "Database Analyst",
   value: "Database Analyst"},
   {label: "Database Manager",
   value: "Database Manager"},
   {label: "Database Consultant",
   value: "Database Consultant"},
   {label: "Database Engineer",
   value: "Database Engineer"},
   {label: "Database Specialist",
   value: "Database Specialist"},
   {label: "Database Developer",
   value: "Database Developer"},
   {label: "Database Architect",
   value: "Database Architect"},
  {label: "Database Administrator",
   value: "Database Administrator"},
   {label: "Database Analyst",
   value: "Database Analyst"},
   {label: "Database Manager",
   value: "Database Manager"},
   {label: "Database Consultant",
   value: "Database Consultant"},
   {label: "Database Engineer",
   value: "Database Engineer"},
   {label: "Database Specialist",
   value: "Database Specialist"},
   {label: "Data Analyst",
   value: "Data Analyst"},
   {label: "Data Engineer",
   value: "Data Engineer"},
   {label: "Data Scientist",
   value: "Data Scientist"},
   {label: "Data Architect",
   value: "Data Architect"},
   {label: "Javascript Developer",
   value: "Javascript Developer"},
   {label: "HTML Developer",
   value: "HTML Developer"},
   {label: "CSS Developer",
   value: "CSS Developer"},
   {label: "HTML5 Developer",
   value: "HTML5 Developer"},
   {label: "HTML/CSS Developer",
   value: "HTML/CSS Developer"},
   {label: "CSS3 Developer",
   value: "CSS3 Developer"},
   {label: "HTML/CSS/JS Developer",
   value: "HTML/CSS/JS Developer"},
   {label: "UI Developer",
   value: "UI Developer"},
   {label: "UX Developer",
   value: "UX Developer"},
   {label: "UI/UX Developer",
   value: "UI/UX Developer"},
   {label: "Accountant",
   value: "Accountant"},
   {label: "Financial Analyst",
   value: "Financial Analyst"},
   {label: "Financial Advisor",
   value: "Financial Advisor"},
   {label: "Investment Banker",
   value: "Investment Banker"},
   {label: "Financial Planner",
   value: "Financial Planner"},
   {label: "Stock Broker",
   value: "Stock Broker"},
   {label: "Trader",
   value: "Trader"},
   {label: "Digital Marketing",
   value: "Digital Marketing"},
   {label: "Content Writer",
   value: "Content Writer"},
   {label: "Social Media Manager",
   value: "Social Media Manager"},
   {label: "Brand Manager",
   value: "Brand Manager"},
   {label: "Marketing Manager",
   value: "Marketing Manager"},
   {label: "Marketing Executive",
   value: "Marketing Executive"},
   {label: "Marketing Analyst",
   value: "Marketing Analyst"},
   {label: "Sales Manager",
   value: "Sales Manager"},
   {label: "Sales Executive",
   value: "Sales Executive"},
   {label: "Sales Representative",
   value: "Sales Representative"},
   {label: "Sales Engineer",
   value: "Sales Engineer"},
   {label: "Sales Consultant",
   value: "Sales Consultant"},
   {label: "Sales Coordinator",
   value: "Sales Coordinator"},
   {label: "Sales Associate",
   value: "Sales Associate"},
   {label: "HR Manager",
   value: "HR Manager"},
   {label: "HR Executive",
   value: "HR Executive"},
   {label: "HR Assistant",
   value: "HR Assistant"},
   {label: "HR Coordinator",
   value: "HR Coordinator"},
   {label: "HR Specialist",
   value: "HR Specialist"},
   {label: "HR Recruiter",
   value: "HR Recruiter"},
   {label: "HR Generalist",
   value: "HR Generalist"},
];