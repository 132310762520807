import React, { useEffect, useRef, useState } from "react";
import {
  BoxStyle,
  ButtonBlue,
  ButtonPurpleOutline,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import { useNavigate } from "react-router";
import user from "assets/image/userDummy.jpeg";
import "./profile.css";
import { Form, Spinner } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import axios from "axios";
import {
  CorporatePasswordUpdate,
  GetCorporate,
  UpdateCorporate,
} from "../../../axios/apis";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";

export const Profile = ({ updateprofile, setupdateprofile }) => {
  const [showpassword, setShowPassword] = useState(false);
  const [shownewpassword, setShowNewPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [userDetail, setUserDetail] = useState({
    company: "",
    email: "",
    phone: "",
    profileImg: "",
  });
  const [companyDetail, setCompanyDetail] = useState({});
  const [passwordChange, setPasswordChange] = useState({
    password: "",
    newpassword: "",
    confirmnewpass: "",
  });
  const [plan, setPlan] = useState({
    name: "",
    jobs: "",
  });
  const handlePlanChange = (e) => {
    setPlan({ ...plan, [e.target.name]: e.target.value });
  };
  const handleChange = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };
  const handlePasswordChange = (e) => {
    setPasswordChange({ ...passwordChange, [e.target.name]: e.target.value });
  };
  const UploadInput = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [loadingiconupload, setLoadingIconupload] = useState(null);
  const uploadIcon = async (e) => {
    if (e.target.files[0]) {
      setLoadingIconupload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          setUserDetail({ ...userDetail, [e.target.name]: data.data.link });
          setLoadingIconupload(false);
          toast.success("Image Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          setLoadingIconupload(false);
        });
    }
  };
  const removeProfilePhoto = () => {
    setUserDetail({ ...userDetail, profileImg: "" });
    toast.success("Profile Image Removed Successfully", ToastStyles);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const GetCompanyDetails = async () => {
    setLoading(true);
    try {
      const response = await GetCorporate();
      setCompanyDetail(response?.data?.data);
      setUserDetail(response?.data?.data);
      // console.log(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetCompanyDetails();
  }, []);
  const [profileLoading, setProfileLoading] = useState(false);
  const updateCompanyDetail = async (e) => {
    e.preventDefault();
    if (
      userDetail.company !== companyDetail.company &&
      userDetail.email !== companyDetail.email
    ) {
      setProfileLoading(true);
      try {
        let data = {
          profileImg: userDetail.profileImg,
          email: userDetail.email,
          company: userDetail.company,
          phoneNumber: userDetail.phoneNumber,
        };
        const res = await UpdateCorporate(data);
        toast.success("Updated Successfully", ToastStyles);
        Cookies.set("name", res?.data?.data?.updatedCompany?.company);
        Cookies.set("email", res?.data?.data?.updatedCompany?.email);
        Cookies.set("profileImg", res?.data?.data?.updatedCompany?.profileImg);
        setProfileLoading(false);
        setupdateprofile(!updateprofile);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setProfileLoading(false);
      }
    } else if (userDetail.company !== companyDetail.company) {
      setProfileLoading(true);
      try {
        let data = {
          profileImg: userDetail.profileImg,
          company: userDetail.company,
          phoneNumber: userDetail.phoneNumber,
        };
        const res = await UpdateCorporate(data);
        Cookies.set("company", res?.data?.data?.updatedCompany?.company);
        Cookies.set("profileImg", res?.data?.data?.updatedCompany?.profileImg);
        toast.success("Updated Successfully", ToastStyles);
        setProfileLoading(false);
        setupdateprofile(!updateprofile);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setProfileLoading(false);
      }
    } else if (userDetail.email !== companyDetail.email) {
      setProfileLoading(true);
      try {
        let data = {
          profileImg: userDetail.profileImg,
          email: userDetail.email,
          phoneNumber: userDetail.phoneNumber,
        };
        const res = await UpdateCorporate(data);
        Cookies.set("email", res?.data?.data?.updatedCompany?.email);
        Cookies.set("profileImg", res?.data?.data?.updatedCompany?.profileImg);
        toast.success("Updated Successfully", ToastStyles);
        setProfileLoading(false);
        setupdateprofile(!updateprofile);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setProfileLoading(false);
      }
    } else if (
      userDetail.profileImg !== companyDetail.profileImg ||
      userDetail.phoneNumber !== companyDetail.phoneNumber
    ) {
      setProfileLoading(true);
      try {
        let data = {
          profileImg: userDetail.profileImg,
          phoneNumber: userDetail.phoneNumber,
        };
        const res = await UpdateCorporate(data);
        Cookies.set("profileImg", res?.data?.data?.updatedCompany?.profileImg);
        toast.success("Updated Successfully", ToastStyles);
        setProfileLoading(false);
        setupdateprofile(!updateprofile);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setProfileLoading(false);
      }
    } else {
      toast.error("Please Update Details first");
    }
  };

  const [passwordloading, setpasswordLoading] = useState(false);

  const updatePassword = async (e) => {
    e.preventDefault();
    if (passwordChange.newpassword !== passwordChange.confirmnewpass) {
      toast.error("Passwords do not match", ToastStyles);
    } else if (passwordChange.newpassword === passwordChange.password) {
      toast.error("Current and New Password Can't be same.", ToastStyles);
    } else {
      setpasswordLoading(true);
      try {
        let data = {
          oldpass: passwordChange.password,
          password: passwordChange.newpassword,
        };
        const res = await CorporatePasswordUpdate(data);
        toast.success("Password Updated Successfully", ToastStyles);
        setPasswordChange({
          password: "",
          newpassword: "",
          confirmnewpass: "",
        });
        setpasswordLoading(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 400) {
          toast.error("Current Password is Incorrect 😣", ToastStyles);
        } else {
          toast.error("Try Again 😣", ToastStyles);
        }
        setpasswordLoading(false);
      }
    }
  };
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <BoxStyle className="mt-3">
              <div className="row profile-sec-1 px-0">
                <div className=" profile-card-2 col-sm-6 col-12">
                  <div>
                    <YellowHeading>Profile</YellowHeading>
                    <h6>Update your account’s profile information</h6>
                  </div>
                </div>
                <div className="profile-card col-sm-6 col-12">
                  <div className="d-flex align-items-center image-box-res px-3 mt-3">
                    <img
                      src={userDetail.profileImg || user}
                      style={{ marginRight: "20px" }}
                      className="rounded-circle"
                      width={80}
                      height={80}
                      alt=""
                    />
                    <div className="d-flex flex-column w-100-525">
                      {loadingiconupload ? (
                        <ButtonPurpleOutline className="mb-2">
                          {percentage}% Uploading...
                        </ButtonPurpleOutline>
                      ) : (
                        <ButtonPurpleOutline
                          onClick={() => UploadInput.current.click()}
                          className="mb-2 h-auto"
                        >
                          SELECT A NEW PHOTO
                        </ButtonPurpleOutline>
                      )}

                      <input
                        ref={UploadInput}
                        type="file"
                        hidden
                        name="profileImg"
                        onChange={uploadIcon}
                      />
                      <ButtonPurpleOutline
                        onClick={removeProfilePhoto}
                        className="h-auto"
                      >
                        REMOVE PHOTO
                      </ButtonPurpleOutline>
                    </div>
                  </div>
                  <Form className="p-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">Company</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name="company"
                        value={userDetail.company}
                        onChange={handleChange}
                        // placeholder="Deepa Chawan"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">Email</Form.Label>
                      <Form.Control
                        name="email"
                        value={userDetail.email}
                        onChange={handleChange}
                        className="form-input"
                        type="email"
                        // placeholder="abc@gmail.com"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type="phone"
                        name="phoneNumber"
                        value={userDetail.phoneNumber}
                        onChange={handleChange}
                        // placeholder=""
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                      {profileLoading ? (
                        <ButtonBlue className="h-40" style={{ width: "160px" }}>
                          <Spinner animation="border" size="sm" />
                        </ButtonBlue>
                      ) : (
                        <ButtonBlue
                          className="h-40 "
                          style={{ width: "160px" }}
                          onClick={updateCompanyDetail}
                        >
                          Save
                        </ButtonBlue>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </BoxStyle>

            <BoxStyle className="my-4">
              <div className="row profile-sec-1 px-0">
                <div className=" profile-card-2 col-sm-6 col-12">
                  <div>
                    <YellowHeading>Update Password</YellowHeading>
                    <h6>
                      Ensure your account is using a long, random password to
                      stay secure.
                    </h6>
                  </div>
                </div>
                <div className="profile-card col-sm-6 col-12">
                  <Form onSubmit={updatePassword} className="p-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Current Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type={showpassword ? "text" : "password"}
                        name="password"
                        minLength={8}
                        required
                        value={passwordChange.password}
                        onChange={handlePasswordChange}
                        placeholder="Current Password"
                      />
                      {showpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        New Password
                      </Form.Label>
                      <Form.Control
                        name="newpassword"
                        value={passwordChange.newpassword}
                        onChange={handlePasswordChange}
                        className="form-input"
                        minLength={8}
                        required
                        type={shownewpassword ? "text" : "password"}
                        placeholder="New Password"
                      />
                      {shownewpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowNewPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowNewPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type={showconfirmpassword ? "text" : "password"}
                        name="confirmnewpass"
                        minLength={8}
                        required
                        value={passwordChange.confirmnewpass}
                        onChange={handlePasswordChange}
                        placeholder="Re-enter Password"
                      />
                      {showconfirmpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowConfirmPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowConfirmPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                      {passwordloading ? (
                        <ButtonBlue className="h-40" style={{ width: "160px" }}>
                          <Spinner animation="border" size="sm" />
                        </ButtonBlue>
                      ) : (
                        <ButtonBlue
                          className="h-40 "
                          style={{ width: "160px" }}
                          type="submit"
                        >
                          Save
                        </ButtonBlue>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </BoxStyle>
            {/* <BoxStyle className="my-4">
              <div className="row profile-sec-1 px-0">
                <div className=" profile-card-2 col-sm-6 col-12">
                  <div>
                    <YellowHeading>Plan Details</YellowHeading>
                    <h6>Plan Details.</h6>
                  </div>
                </div>
                <div className="profile-card col-sm-6 col-12">
                  <Form className="p-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">Plan Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name="name"
                        disabled
                        value={plan.name}
                        onChange={handlePlanChange}
                        placeholder="Basic"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Job Posting
                      </Form.Label>
                      <Form.Control
                        name="jobs"
                        disabled
                        value={plan.jobs}
                        onChange={handlePlanChange}
                        className="form-input"
                        type="number"
                        placeholder="50"
                      />
                    </Form.Group>

                    <div className="d-flex justify-content-end mt-4">
                      <h6 className="form-title me-2">Bill</h6>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <ButtonBlue
                        className="h-40 "
                        style={{ width: "160px" }}
                        type="submit"
                      >
                        Download
                      </ButtonBlue>
                    </div>
                  </Form>
                </div>
              </div>
            </BoxStyle> */}
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
