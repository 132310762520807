import React, { useState } from "react";
import {
  BoxStyle,
  ButtonBlue,
  DarkHeading,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import { useNavigate } from "react-router";
import "./candidate.css";
import CandidateCard from "./CandidateCard";
import { Link } from "react-router-dom";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ToastStyles } from "components/common/ToastStyle";
import { toast, Toaster } from "react-hot-toast";
import { CollegeSignUp, StudentSignUp } from "../../../axios/apis";
import Cookies from "js-cookie";

export const Candidate = ({ type }) => {
  // console.log(type);
  const navigate = useNavigate();
  const [showcreateStudent, setshowCreateStudent] = useState(false);
  const createStudentModalClose = () => {
    setshowCreateStudent(false);
  };
  const createStudentModalOpen = () => {
    setshowCreateStudent(true);
  };
  const [studentmodalloading, setStudentmodalLoading] = useState(false);
  const collegeID = Cookies.get("collegeID");
  const [studentDetails, setStudentDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    password: "",
    collegeId: collegeID,
    role: "student",
  });
  const handlestudentdetails = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };
  const createNewStudent = async (e) => {
    e.preventDefault();
    // console.log(studentDetails);
    setStudentmodalLoading(true);
    try {
      const response = await StudentSignUp(studentDetails);
      // console.log(response);
      toast.success("Student Added", ToastStyles);
      setStudentmodalLoading(false);
      setStudentDetails({
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        password: "",
        collegeId: collegeID,
        role: "student",
      });
      createStudentModalClose();
    } catch (error) {
      console.log(error);
      toast.error("Error in Adding Student", ToastStyles);
      setStudentmodalLoading(false);
    }
  };
  return (
    <>
      <Toaster />
      <Modal show={showcreateStudent} onHide={createStudentModalClose}>
        <Modal.Header closeButton>
          <DarkHeading>Add Student</DarkHeading>
        </Modal.Header>
        <Modal.Body className="job-form mx-2">
          <Form className="px-3 pb-2" onSubmit={createNewStudent}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">First Name</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="firstname"
                required
                value={studentDetails.firstname}
                onChange={handlestudentdetails}
                placeholder="Deepa"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Last Name</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="lastname"
                required
                value={studentDetails.lastname}
                onChange={handlestudentdetails}
                placeholder="Chawan"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Email</Form.Label>
              <Form.Control
                name="email"
                required
                value={studentDetails.email}
                onChange={handlestudentdetails}
                className="form-input"
                type="email"
                placeholder="abc@gmail.com"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Phone Number</Form.Label>
              <Form.Control
                className="form-input"
                type="phone"
                name="phoneNumber"
                required
                value={studentDetails.phoneNumber}
                onChange={handlestudentdetails}
                placeholder=""
              />
            </Form.Group>
            {/* <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Password</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="password"
                value={studentDetails.password}
                onChange={handlestudentdetails}
                placeholder=""
              />
            </Form.Group> */}
            <hr />
            {studentmodalloading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add New Student
              </ButtonBlue>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <DashboardBoxStyle className="scroller">
        <div className="container">
          {type === "college" && (
            <div className="my-3 d-flex justify-content-end">
              <ButtonBlue
                onClick={createStudentModalOpen}
                className="px-5 h-40 me-3"
              >
                Add Student
              </ButtonBlue>
              <Link to="create-team">
                <ButtonBlue className="px-5 h-40">Team</ButtonBlue>
              </Link>
            </div>
          )}
          <BoxStyle className="my-3">
            <div className="row profile-sec-1 px-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <YellowHeading>Senior UI/UX Designer</YellowHeading>
                </div>
                <div className="d-flex align-items-center">
                  <ButtonBlue className="h-40 px-4">
                    <b>View All</b>
                  </ButtonBlue>
                </div>
              </div>
            </div>
            <hr />
            <div className="candidates-box">
              <CandidateCard />
              <CandidateCard />
              <CandidateCard />
              <CandidateCard />
            </div>
          </BoxStyle>
          <BoxStyle className="my-3">
            <div className="row profile-sec-1 px-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <YellowHeading>Senior UI/UX Designer</YellowHeading>
                </div>
                <div className="d-flex align-items-center">
                  <ButtonBlue className="h-40 px-4">
                    <b>View All</b>
                  </ButtonBlue>
                </div>
              </div>
            </div>
            <hr />
            <div className="candidates-box">
              <CandidateCard />
              <CandidateCard />
              <CandidateCard />
            </div>
          </BoxStyle>
        </div>
      </DashboardBoxStyle>
    </>
  );
};
