import React from "react";
import user from "assets/image/user.png";

import "./InterviewscoreCard.css"
import { useNavigate, useParams } from "react-router";
const InterviewPageScoreCard = ({data}) => {
  const totalscore = (data) => {
    let newdata = data?.filter((data2) => data2.body !== null)
    let score = newdata?.map((data2) => data2?.body['Total Score'])
    const res = score.reduce((a, b) => a + b, 0)
    // debugger
    // console.log((res / newdata.length).toFixed(2));
    return (res / newdata.length).toFixed(2) === "NaN" ? 0 : (res / newdata.length).toFixed(2)
  }
  const params=useParams()
  const navigate=useNavigate()
  return (
      <div onClick={()=>{navigate(`/hire-pro/interview-video/${params.id}/${data?._id}`)}} className="interview-score-card">
        <div className="candidate-image">
          <img src={data?.attemptedby_id?.profile || user} alt="user" />
        </div>
        <div className="candidate-details">
          <div>
            <h6>{data?.attemptedby_id?.firstname+" "+data?.attemptedby_id?.lastname}</h6>
            <p>{totalscore(data?.results)}/100</p>
          </div>
        </div>
      </div>
  );
};

export default InterviewPageScoreCard;
