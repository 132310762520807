import { AiFillBook, AiFillHome } from "react-icons/ai";
import {
  MdGroup,
  MdContacts,
  MdSchool,
  MdFeedback,
  MdOutlineHelp,
  MdQuestionAnswer,
  MdContactPhone,
} from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";

import { create_UUID } from "helper/uuid";

export const SidepanelDataSuperAdmin = [
  {
    id: create_UUID(),
    name: "Dashboard",
    path: "/superadmin/dashboard",
    icon: <AiFillHome />,
  },
  {
    id: create_UUID(),
    name: "Create Interview",
    path: "/superadmin/create-interview",
    icon: <AiFillBook />,
  },
  {
    id: create_UUID(),
    path: "/superadmin/my-interviews",
    name: "My Interviews",
    icon: <MdSchool />,
  },
  {
    id: create_UUID(),
    path: "/superadmin/corporate",
    name: "Corporate",
    icon: <MdContactPhone />,
  },
  {
    id: create_UUID(),
    path: "/superadmin/college",
    name: "College",
    icon: <MdGroup />,
  },
  {
    id: create_UUID(),
    path: "/superadmin/candidates",
    name: "Candidates",
    icon: <MdGroup />,
  },
  // {
  //   id: create_UUID(),
  //   path: "/superadmin/question-bank",
  //   name: "Question Bank",
  //   icon: <MdQuestionAnswer />,
  // },
  {
    id: create_UUID(),
    path: "/superadmin/profile",
    name: "Profile",
    icon: <BsFillPersonFill />,
  },
  {
    id: create_UUID(),
    path: "/superadmin/support-feedback",
    name: "Support & Feedback",
    icon: <MdFeedback />,
  },
  {
    id: create_UUID(),
    path: "/superadmin/help",
    name: "Help & Documentation",
    icon: <MdOutlineHelp />,
  },
];
