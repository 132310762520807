import { AddStudentCreateTeam, AssignInterviewtoTeam, BulkStudents, GetAllCreatorInterview, GetCollegeTeams, UpdateCollegeTeam } from "../../../axios/apis";
import { BackButton } from "components/common/BackButton";
import { ButtonBlue, DashboardBoxStyle, Paragraph, Select } from "components/common/CommonStyles";
import { ToastStyles } from "components/common/ToastStyle";
import React, { useEffect, useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import { useCsvUpload } from "./useCsvUpload";
import userslist from "./userslist.xlsx"
import Cookies from "js-cookie";
export const AddBulkStudents = () => {
  const ref = useRef(null);
  const [list, setList] = useState([]);
  const [assignteam, setAssignTeam] = useState(false);
  const [addnewteam, setaddnewteam] = useState(false)
  const [loading, setLoading] = useState(false);
  const { handleFileReader, sheetAccepted } = useCsvUpload();
  const handleChange = async () => {
    const [file] = ref.current.files;
    if (file) {
      handleFileReader(file, (data) => {
        setList(data);
      });
    }
  };


  // teams
  const [teamassign, setTeamAssign] = useState("")
  const [interviewteams, setinterviewteams] = useState([]);
  const collegeID = Cookies.get("collegeID");
  const [teamsdata, setteamsdata] = useState([])
  const [teamdetails, setTeamDetails] = useState({
    name: "",
    collegeid: collegeID,
    studyclass: "",
    standard: "",
    year: "",
    students: [],
  });
  const handleTeamDetails = (e) => {
    setTeamDetails({
      ...teamdetails,
      [e.target.name]: e.target.value,
    });
  };
  const getData = async () => {
    try {
      const response2 = await GetCollegeTeams();
      const response3 = await GetAllCreatorInterview();
      setInterviewList(response3.data.interviews);
      setteamsdata(response2.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleinterviewTeamChange = (data) => {
    // console.log(data);
    if (data === "") {
      return;
    } else if (interviewteams.length === 20) {
      toast.error("20 Teams Already added", ToastStyles);
    } else if (interviewteams.length !== 0 && interviewteams.includes(data)) {
      toast.error("Team Already Added", ToastStyles);
    } else {
      setinterviewteams((interviewteams) => [...interviewteams, data]);
    }
  };
  const removethisteam = (indexToRemove) => {
    setinterviewteams([
      ...interviewteams.filter((_, index) => index !== indexToRemove),
    ]);
  };


  // console.log(list);
  const [uploadloading, setupdateloading] = useState(false);
  const bulkUpload = async () => {
    // setLoading(true);
    const filteredList = list?.filter((item) => item.email);
    // console.log(filteredList);
    if (filteredList.length > 0) {
      if (addnewteam && (teamdetails.name === "" || teamdetails.standard === "" || teamdetails.year === "" || teamdetails.studyclass === "")) {
        toast.error("Please Fill all details", ToastStyles)
      } else if (addnewteam) {
        setupdateloading(true);
        try {
          const res = await BulkStudents(filteredList);
          let data = {
            students: res.data.data,
            standard: teamdetails.standard,
            name: teamdetails.name,
            studyclass: teamdetails.studyclass,
            year: teamdetails.year
          }
          debugger
          const res2 = await AddStudentCreateTeam(data);
          setupdateloading(false);
          if (assignAllInterview) {
            const interviews = interviewList.map((data) => data?._id)

            const res3 = await AssignInterviewtoTeam({ teams: [res2.data._id], interviewId: interviews });
          }
          else if (newInterview.length > 0) {
            const res3 = await AssignInterviewtoTeam({ teams: [res2.data._id], interviewId: newInterview });
          }
          toast.success("Students Added Successfully", ToastStyles);
        } catch (error) {
          console.log(error);
          toast.error("Try Again", ToastStyles);
          setupdateloading(false);
        }
      }
      else {
        setupdateloading(true);
        try {
          const res = await BulkStudents(filteredList);

          setupdateloading(false);
          toast.success("Students Added Successfully", ToastStyles);
        } catch (error) {
          console.log(error);
          toast.error("Try Again", ToastStyles);
          setupdateloading(false);
        }
      }

    }
  };
  // assign interview 

  const [newInterview, setnewInterview] = useState([]);
  const [interviewList, setInterviewList] = useState([]);

  const [assignAllInterview, setAssignAllInterview] = useState(false)
  // console.log(newInterview)
  const handleinterviewChange = (data) => {
    // console.log(data);
    if (data === "") {
      return;
    } else if (newInterview.length === 20) {
      toast.error("20 Interviews Already added", ToastStyles);
    } else if (newInterview.length !== 0 && newInterview.includes(data)) {
      // setnewStudents((newStudents) =>
      //   newStudents.filter((data2, index) => data2 !== data)
      // );
      toast.error("Interview Already Added", ToastStyles);
    } else {
      setnewInterview((newInterview) => [...newInterview, data]);
    }
  };

  const removethisInterview = (indexToRemove) => {
    setnewInterview([
      ...newInterview.filter((_, index) => index !== indexToRemove),
    ]);
  };


  return (
    <>
      <Toaster />
      <DashboardBoxStyle className="scroller">
        <div className="container">
          <div className="my-1  py-2">
            <div className="d-flex justify-content-between flex-wrap">
              <div className="d-flex align-items-center">
                <BackButton />
              </div>
            </div>
          </div>
          <div className="card-3 mt-4">
            <div className="d-flex justify-content-end mb-2">
              <ButtonBlue className="h-40" width="150px">
                <a
                  className="white-100 cursor-pointer"
                  href={userslist}
                  download
                  style={{ color: "white" }}
                >
                  Sample File
                </a>
              </ButtonBlue>
            </div>
            <div className=" text-center">
              <div className="card-body">
                <div className="row my-2">
                  <h2>IMPORT USERS </h2>
                </div>
                {/* <div className="row my-3">
                <h6>Import Users on your Viosa Site</h6>
              </div> */}
                <div className="row d-flex align-items-center justify-content-center mb-3">
                  <input
                    style={{ width: "240px" }}
                    type="file"
                    ref={ref}
                    accepted={sheetAccepted}
                    onChange={handleChange}
                  />
                </div>
                <div className="row my-4">
                  <p style={{ margin: "0px" }}>
                    We Accept Name, Email, Password, Class and Mobile number, Name, Password, Mobile Number and Email are
                    required.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {list.length > 0 && (
            <table className="table  display">
              <thead>
                <tr>
                  <th>SNo</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Class</th>
                  <th>Password</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((data, id) => {
                  return (
                    <tr key={id}>
                      <td>{id + 1}</td>
                      <td>{data?.firstname}</td>
                      <td>{data?.email}</td>
                      <td>{data?.phoneNumber}</td>
                      <td>{data?.className}</td>
                      <td>{data?.password}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {list.length > 0 && (
            <div className="d-flex flex-column justify-content-center mb-2">
              {/* <div className="d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onClick={(e) => setAssignTeam(e.target.checked)}
                />
                <h6 className="mt-2 pt-1 mx-2">Add to Team</h6>
              </div>
              {
                assignteam && <>
                  <label className="secondary my-3">Assign Team</label>
                  <div className="tags-input">
                  
                    <Select
                      className="b-none border-10 secondary"
                      onChange={(e) =>
                        setTeamAssign(e.target.value)
                      }
                    >
                      <option default value="">
                        Select here
                      </option>
                      {teamsdata.map((data) => (
                        <option value={data._id}>{data?.name}</option>
                      ))}
                    </Select>
                  </div>
                </>
              } */}
              <div className="d-flex align-items-center justify-content-center">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onClick={(e) => setaddnewteam(e.target.checked)}
                />
                <h6 className="mt-2 pt-1 mx-2">Add to New Team</h6>
              </div>
              {
                addnewteam && <>
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Team Name</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="name"
                          value={teamdetails?.name}
                          onChange={handleTeamDetails}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Class</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="studyclass"
                          value={teamdetails?.studyclass}
                          onChange={handleTeamDetails}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Standard</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="standard"
                          value={teamdetails?.standard}
                          onChange={handleTeamDetails}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Year</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="year"
                          value={teamdetails?.year}
                          onChange={handleTeamDetails}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <Paragraph className="fs-14 fw-500 mb-1 secondary">
                      Assign Interview*
                    </Paragraph>

                    <div className="tags-input">
                      {newInterview.length > 0 ? (
                        <ul id="tags">
                          {newInterview?.map((tag, index) => (
                            <li key={index} className="tag">
                              <span className="tag-title">
                                {interviewList?.find((data) => data._id == tag)?.jobtitle
                                }
                              </span>
                              <span
                                className="tag-close-icon"
                                onClick={() => removethisInterview(index)}
                              >
                                x
                              </span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul id="tags">
                          <h6>Add upto 20 Interviews</h6>
                        </ul>
                      )}
                      <Select
                        className="b-none border-10 secondary"
                        aria-hidden
                        onChange={(e) => handleinterviewChange(e.target.value)}
                      >
                        <option default value="">
                          Select here
                        </option>
                        {interviewList?.map((data) => (
                          <option value={data?._id}>
                            {data?.jobtitle}
                          </option>
                        ))}
                      </Select>
                    </div>

                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onClick={(e) => setAssignAllInterview(e.target.checked)}
                    />
                    <h6 className="mt-2 pt-1 mx-2">Assign All Interviews</h6>
                  </div>
                </>
              }

              {uploadloading ? (
                <div className="d-flex justify-content-center mt-2">
                  <ButtonBlue className="h-40" width="200px">
                    <Spinner animation="border" size="sm" />
                  </ButtonBlue>
                </div>
              ) : (
                <div className="d-flex justify-content-center mt-2 ">
                  <ButtonBlue
                    onClick={bulkUpload}
                    className="h-40"
                    width="200px"
                  >
                    Add Students
                  </ButtonBlue>
                </div>
              )}

            </div>
          )}
        </div>
      </DashboardBoxStyle>
    </>
  );
};
