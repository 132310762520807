import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState } from "react";
import "./sidebar.css";
import {
  NavLink,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";

import { AiFillHome } from "react-icons/ai";
import { NavbarInterviewer } from "components/Navbar/NavbarInterviewer";

import { SupportFeedback } from "components/common/Support/SupportFeedback";
import { HelpDocumentation } from "components/common/Support/HelpDocumentation";
import { QuestionAnswer } from "container/SuperAdmin/QuestionAnswer";
import { Colleges } from "container/SuperAdmin/Colleges";
import { Dashboard } from "container/SuperAdmin/Dashboard/Dashboard";
import { Corporates } from "container/SuperAdmin/Corporates";
import CreateInterviewForm from "container/SuperAdmin/CreateInterview/CreateInterviewForm";
import { CreateInterview } from "container/Interviewer/CreateInterview/CreateInterview";
import { DraftInterviews } from "container/SuperAdmin/CreateInterview/DraftInterviews";
import { MyInterview } from "container/Interviewer/MyInterview/MyInterview";
import { PublishInterview } from "container/SuperAdmin/CreateInterview/PublishInterview";
import { SingleMyInterview } from "container/Interviewer/MyInterview/SingleMyInterview";
import { MyInterviewRecorded } from "container/Interviewer/MyInterview/MyInterviewRecorded";
import { Candidate } from "container/Interviewer/Candidates/Candidate";
import DraftInterviewForm from "container/SuperAdmin/CreateInterview/DraftInterviewForm";
import Reports from "container/Interviewee/Reports/Reports";
import { NavbarAdmin } from "components/Navbar/NavbarAdmin";
import { Profile } from "container/SuperAdmin/Profile/Profile";
import { Candidates } from "container/SuperAdmin/Candidates";
import Notification from "container/SuperAdmin/Notification";
import Cookies from "js-cookie";

export const SidebarAndHeaderSuperAdmin = ({ data }) => {
  const [hideSidebar, setHideSidebar] = useState(false);
  const navigate = useNavigate();
  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      navigate("/");
    }
  };
  return (
    <>
      <div className="d-flex">
        <div className={`${hideSidebar ? "col-w-6" : "col-w-20"}`}>
          <SidePanel hideSidebar={hideSidebar}>
            <div className="px-3 py-4">
              <div className="min-height-sidebar">
                {data.slice(0, 8).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${
                        hideSidebar ? "wrapper__sidebar_hide" : ""
                      } d-flex align-items-center py-2 wrapper__sidebar`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <hr />
                {data.slice(8, 10).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${
                        hideSidebar ? "wrapper__sidebar_hide" : ""
                      } d-flex align-items-center py-2 wrapper__sidebar`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <a className="sidebar__link" href="https://admin.viosa.in/">
                  {!hideSidebar ? (
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar
                  cursor-pointer"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading px-2 mt-2">
                        Go To Dashboard
                      </h6>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center py-2 wrapper__sidebar wrapper__sidebar_hide cursor-pointer">
                      <AiFillHome />
                    </div>
                  )}
                </a>
              </div>
              <div
                className="sidebar__link"
                onClick={() => {
                  setHideSidebar(!hideSidebar);
                }}
              >
                {!hideSidebar ? (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar cursor-pointer">
                    <HiOutlineArrowNarrowLeft />
                    <h6 className="sidebar__heading px-2 mt-2">Hide Sidebar</h6>
                  </div>
                ) : (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar wrapper__sidebar_hide cursor-pointer">
                    <HiOutlineArrowNarrowRight />
                  </div>
                )}
              </div>
            </div>
          </SidePanel>
        </div>
        <div className={`${hideSidebar ? "col-w-94" : "col-w-80"}`}>
          <div className="w-100">
            <NavbarAdmin />
            <div className="main-content ">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/create-interview"
                  element={<CreateInterview type={"superadmin"} />}
                />
                <Route
                  path="/create-interview/new"
                  element={<CreateInterviewForm />}
                />
                <Route
                  path="/create-interview/draft"
                  element={<DraftInterviews />}
                />
                <Route
                  path="/create-interview/draft/:id"
                  element={<DraftInterviewForm />}
                />
                <Route
                  path="/edit-interview/:id"
                  element={<DraftInterviewForm />}
                />
                <Route
                  path="/interview/publish"
                  element={<PublishInterview />}
                />
                <Route
                  path="/my-interviews"
                  element={<MyInterview type={"superadmin"} />}
                />

                <Route path="/question-bank" element={<QuestionAnswer />} />
                <Route path="/college" element={<Colleges />} />
                <Route path="/corporate" element={<Corporates />} />
                <Route
                  path="/my-interviews/:id"
                  element={<SingleMyInterview type={"superadmin"} />}
                />
                <Route path="/reports/:id" element={<Reports />} />
                <Route
                  path="/interview/:candidateId"
                  element={<MyInterviewRecorded />}
                />
                <Route path="/candidates" element={<Candidates />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/help" element={<HelpDocumentation />} />
                <Route path="/support-feedback" element={<SupportFeedback />} />
                <Route path="/notifications" element={<Notification />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
