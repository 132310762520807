import React, { useState, useEffect } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router";
import axios from "axios";
import { toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Header from "./Header";
import Footer from "./Footer";
import {
  InterviewPageBox,
  LightPurpleBtn,
  PurpleBoxYellowBorder,
} from "container/ActualInterview/InterviewStyled";
import {
  ButtonPurpleOutline,
  ButtonYellowFill,
  DashboardBoxStyle,
  Paragraph,
  PinkButton,
} from "components/common/CommonStyles";
import {
  ApplyInterviewCopy,
  GetIntervieweeDetails,
  GetSingleInterview,
  IntervieweeSignUp,
  UpdateIntervieweeProfile,
} from "../../axios/apis";

const UploadDocuments = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [updateloading, setupdateLoading] = useState(false);
  const [loadingfileUpload, setLoafingFileUpload] = useState(false);
  const [interviewdata, setInterviewData] = useState({});
  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    console.log("Hi");
    console.log("Params:", params);
  }, [params]);
  useEffect(() => {
    if (state && state.user) {
      console.log("Received user data:", state.user);
    } else {
      console.log("No user data received.");
    }
  }, [state]);

  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      console.log(response);
      setInterviewData(response.data.interview);
      console.log("response", response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(
        "Try Again: We couldn't fetch interview details",
        ToastStyles
      );
      setLoading(false);
    }
  };

  const GetUser = async () => {
    setLoading(true);
    try {
      const res = await GetIntervieweeDetails();
      // console.log(res);
      setUserDetail(res?.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again: We couldn't fetch user details", ToastStyles);
      setLoading(false);
    }
  };
  // console.log(interviewdata);
  useEffect(() => {
    GetInterviewDetails();
    GetUser();
  }, [params.id]);

  const handleChange = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };

  const handleUpload = async (file, type) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    const rootUrl =
      process.env.NODE_ENV === "production"
        ? "https://uploader.viosa.in/api"
        : "https://uploader.viosa.in/api";

    const config = {
      onUploadProgress: (progress) => {
        const percentange = Math.round(
          (progress.loaded / progress.total) * 100
        );
        setPercentage(percentange);
        console.log(`${type} upload progress:`, percentange);
        if (percentange === 100) {
          setLoading(false);
        }
      },
    };

    try {
      const response = await axios.post(
        `${rootUrl}/v2/upload/${type}`,
        formData,
        config
      );
      console.log(`${type} upload response:`, response.data);
      return response.data.link;
    } catch (error) {
      console.error(`${type} upload error:`, error);
      toast.error("Upload failed. Please try again.", ToastStyles);
      setLoading(false);
      throw error;
    }
  };

  const uploadFile = async (e) => {
    if (e.target.files[0]) {
      setLoafingFileUpload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoafingFileUpload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoafingFileUpload(true);

      axios
        .post(`${rootUrl}/v2/upload/pdf`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          setUserDetail({ ...userDetail, [e.target.name]: data.data.link });
          setLoafingFileUpload(false);
          toast.success("File Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          setLoafingFileUpload(false);
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting files...");
    try {
      const resumeLink = await handleUpload(resume, "pdf");
      const coverLetterLink = await handleUpload(coverLetter, "pdf");

      // Assuming you have an API endpoint to save these links
      // await saveResumeCoverLetterLinks(params.id, resumeLink, coverLetterLink);

      toast.success("Files uploaded successfully", ToastStyles);
      navigate(`/interview/${params.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const Apply = async (e) => {
    e.preventDefault();
    // console.log(userDetail);
    if (userDetail.resume === "") {
      toast.error("Please Upload Resume", ToastStyles);
      return;
    }
    if (userDetail.coverletter === "") {
      toast.error("Please Upload CoverLetter", ToastStyles);
      return;
    }
    setupdateLoading(true);
    let data = {
      firstname: userDetail.firstname,
      lastname: userDetail.lastname,
      phoneNumber: userDetail.phoneNumber,
      resume: userDetail.resume,
      coverletter: userDetail.coverletter,
    };
    let data2 = {
      interviewId: params.id,
    };
    try {
      const res = await UpdateIntervieweeProfile(data);
      const res2 = await ApplyInterviewCopy(data2);
      toast.success("Applied Successfully", ToastStyles);
      navigate(`/interview/${params.id}`);
      // navigate(`/interview/video-interview/${params?.id}`);
      setupdateLoading(false);
      // setupdateprofile(!updateprofile)
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || "Try Again", ToastStyles);
      setupdateLoading(false);
    }
  };
  const documents = interviewdata.documents || [];

  return (
    <>
      <Header />
      <div
        className="container-fluid m-5 "
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-12 col-md-4">
          <PurpleBoxYellowBorder className="me-3 margin-res mt-0">
            <Form onSubmit={Apply} className="p-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="form-title">Name</Form.Label>
                <Form.Control
                  className="form-input"
                  type="text"
                  required
                  disabled
                  name="firstname"
                  value={userDetail.firstname + " " + userDetail.lastname}
                  onChange={handleChange}
                  // placeholder="Deepa"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="form-title">Email</Form.Label>
                <Form.Control
                  name="email"
                  required
                  disabled
                  value={userDetail.email}
                  onChange={handleChange}
                  className="form-input"
                  type="email"
                  placeholder="abc@gmail.com"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="form-title">Phone Number</Form.Label>
                <Form.Control
                  className="form-input"
                  type="phone"
                  disabled
                  name="phoneNumber"
                  required
                  value={userDetail.phoneNumber}
                  onChange={handleChange}
                  placeholder=""
                />
              </Form.Group>
              {documents.includes("Resume") && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="form-title">Resume</Form.Label>
                  <Form.Control
                    className="form-input"
                    type="file"
                    // value={userDetail.resume}

                    name="resume"
                    onChange={uploadFile}
                    placeholder="Upload Resume"
                  />
                  {userDetail?.resume && (
                    <p style={{ color: "green" }} className="form-title mb-0">
                      Uploaded
                    </p>
                  )}
                </Form.Group>
              )}
              {documents.includes("CoverLetter") && (
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="form-title">Cover Letter</Form.Label>
                  <Form.Control
                    className="form-input"
                    type="file"
                    name="coverletter"
                    onChange={uploadFile}
                    placeholder="Upload Cover Letter"
                  />
                  {userDetail?.coverletter && (
                    <p style={{ color: "green" }} className="form- mb-0">
                      Uploaded
                    </p>
                  )}
                </Form.Group>
              )}
              <p>
                By applying, you agree with{" "}
                <span className="yellow">VIOSA.IN Candidate Terms of Use</span>
              </p>
              {updateloading ? (
                <>
                  <ButtonYellowFill>
                    <Spinner animation="border" size="sm" />
                  </ButtonYellowFill>
                </>
              ) : (
                <>
                  <ButtonYellowFill disabled={loadingfileUpload} type="submit">
                    Start Now
                  </ButtonYellowFill>
                </>
              )}
            </Form>
          </PurpleBoxYellowBorder>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UploadDocuments;
