import React, { useState } from "react";
import {
  BoxStyle,
  ButtonBlue,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import logo from "assets/image/logo.svg";

import { Form, Spinner } from "react-bootstrap";
import "./support.css";
import { IoMdCall, IoMdMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import { FaTwitterSquare, FaYoutubeSquare } from "react-icons/fa";
import { AiFillFacebook } from "react-icons/ai";
import { Rating } from "@mui/material";
import { FeedbackSubmit } from "../../../axios/apis";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "../ToastStyle";
export const SupportFeedback = () => {
  const [loading, setLoading] = useState(false);
  const [formDetails, setFormDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    message: "",
    rating: 3,
  });

  const handleChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };
  const sendFeedback = async (e) => {
    setLoading(true);
    e.preventDefault();
    const { status } = await FeedbackSubmit(formDetails);
    setLoading(false);
    if (status === 201) {
      toast.success("Feedback sent Successfully", ToastStyles);
      setFormDetails({
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        message: "",
        rating: 3,
      });
    } else {
      toast.error("Feedback sent Failed", ToastStyles);
    }
  };

  return (
    <>
      <Toaster />
      <DashboardBoxStyle className="scroller">
        <div className="container ">
          <BoxStyle className="d-flex align-items-center">
            <div>
              <YellowHeading>Support & Feedback</YellowHeading>
            </div>
          </BoxStyle>
          <BoxStyle className="mt-3 px-sm-2 px-0">
            <div className="row profile-sec-1 px-4">
              <div className=" profile-card profile-card-dark">
                <div className="logo-box">
                  <img src={logo} alt="" />
                </div>
                <div className="support-contact">
                  <h4>Contact Information</h4>
                  <p>Fill the form our team will get back to you</p>
                </div>
                <div className="support-contact mt-5">
                  <a href="https://wa.me/918655803488" className="d-flex my-2">
                    <IoMdCall size={30} />
                    <p>+91-86558-03488</p>
                  </a>
                  <a href="mailto:mail@viosa.in" className="d-flex my-2">
                    <IoMdMail size={30} />
                    <p>mail@viosa.in</p>
                  </a>
                  <a
                    href="https://goo.gl/maps/2YoHrrX9Nv4qy9ak8"
                    className="d-flex my-2"
                  >
                    <MdLocationPin size={52} />
                    <p>
                      104, Abhishek Premises, Off New Link Rd, Veera Desai
                      Industrial Estate, Andheri West, Mumbai,
                      Maharashtra 400053
                    </p>
                  </a>
                </div>
                <div className="support-contact d-flex justify-content-between support-box-end">
                  <div className="social-media">
                    <div>
                      <a href="https://www.linkedin.com/company/viosalearning/">
                        <BsLinkedin color="#ffffff" size={30} />
                      </a>
                    </div>
                    {/* <div>
                      <a href="#">
                        <FaYoutubeSquare color="#ffffff" size={34} />
                      </a>
                    </div> */}
                    <div>
                      <a href="https://twitter.com/viosa_learning">
                        <FaTwitterSquare color="#ffffff" size={34} />
                      </a>
                    </div>
                    <div>
                      <a href="https://www.facebook.com/viosa.learning">
                        <AiFillFacebook color="#ffffff" size={36} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-card ">
                <Form className="px-4 py-2 " onSubmit={sendFeedback}>
                  <h5 style={{ color: "#602a94" }} className="pb-2">
                    Contact Form
                  </h5>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">First Name</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      required
                      name="firstname"
                      value={formDetails.firstname}
                      onChange={handleChange}
                      placeholder="First Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Last Name</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      name="lastname"
                      value={formDetails.lastname}
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Email</Form.Label>
                    <Form.Control
                      name="email"
                      value={formDetails.email}
                      onChange={handleChange}
                      className="form-input"
                      required
                      type="email"
                      placeholder="abc@gmail.com"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Number</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="phone"
                      name="phoneNumber"
                      required
                      minLength={10}
                      value={formDetails.phoneNumber}
                      onChange={handleChange}
                      placeholder="Number"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Message</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      name="message"
                      value={formDetails.message}
                      onChange={handleChange}
                      required
                      placeholder="Message"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Ratings</Form.Label>
                    <div>
                      <Rating
                        name="size-large"
                        size="large"
                        value={formDetails.rating}
                        onChange={(event, newValue) => {
                          setFormDetails({ ...formDetails, rating: newValue });
                        }}
                      />
                    </div>
                  </Form.Group>

                  <div className="d-flex justify-content-end mt-4">
                    {loading ? (
                      <>
                        <ButtonBlue className=" h-40 w-100 box-shadow" disabled>
                          <Spinner animation="border" size="sm" />
                        </ButtonBlue>
                      </>
                    ) : (
                      <>
                        <ButtonBlue
                          className="h-40 w-100 px-5 box-shadow"
                          type="submit"
                        >
                          Send
                        </ButtonBlue>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </BoxStyle>
        </div>
      </DashboardBoxStyle>
    </>
  );
};
