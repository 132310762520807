import React from "react";
import Logo from "assets/image/logo.svg";
import "./header.css";
import { ButtonYellowOutline } from "components/common/CommonStyles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const Header = () => {
  const params = useParams();
  const location = useLocation();
  // console.log(location)
  const navigate = useNavigate();

  const handleGoBack = () => {
    // history.goBack();
    // navigate("/interviewee/practice-interview");
    navigate(-1);
  };
  return (
    <div className="header">
      <div onClick={handleGoBack} className="back-icon">
        <IoIosArrowBack size={24} />
      </div>
      <img src={Logo} alt="logo" />
      <div>
        <div className="next"></div>
      </div>
      {/* <ButtonYellowOutline
        className="h-40 px-3"
        onClick={() => {
          if (location.pathname === `/interview/${params.id}`) { navigate(-2) }
          else if (location.pathname === `/interview/video-interview/${params.id}`) { navigate(-3) }
          else if (location.pathname === `/interview/video-interview2/${params.id}`) { navigate(-4) }
        }}
        style={{ position: "absolute", right: "10px" }}
      >
        Exit
      </ButtonYellowOutline> */}
    </div>
  );
};

export default Header;
