import React from "react";
import { WebCam } from "components/Video/Webcam";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
  BoxStyle,
  ButtonBlue,
  ButtonPurpleOutline,
  DarkHeading,
  DashboardBoxStyle,
  Paragraph,
  SpanYellow,
  YellowHeading,
} from "components/common/CommonStyles";
import { PracticeCardData } from "container/Interviewee/data";
import video from "assets/image/video.png";
import { Steps } from "components/Steps/Steps";
import "swiper/css";
import "swiper/css/pagination";
import InterviewPageScoreCard from "components/Card/InterviewPageScoreCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { AiTwotoneCalendar } from "react-icons/ai";
import {
  MdApproval,
  MdAttachEmail,
  MdEmail,
  MdLocationPin,
  MdMoreHoriz,
  MdOutlineClose,
  MdPhone,
  MdRemoveRedEye,
} from "react-icons/md";

export const MyInterviewRecorded = () => {
  const navigate = useNavigate();
  return (
    <DashboardBoxStyle className="scroller">
      <div className="container">
        <div className="col-md-12 d-flex align-items-center">
          <div className="button-cotainer mx-3">
            <button className="button-yellow" onClick={() => navigate(-1)}>
              <BiArrowBack />
              <span>|</span>
              Back
            </button>
          </div>
          <div className="col-10">
            {/* {PracticeCardData.slice(0, 1).map((item, index) => (
              <BoxStyle className="pt-3 my-3 padding-5 linear" key={item.id}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="col-12">
                        <div className="mb-1">
                          <div className="d-flex">
                            <div className="">
                              <img
                                className="rounded-circle"
                                height={70}
                                width={70}
                                src={item.logo}
                                alt={item.title}
                              />
                            </div>
                            <div className="mx-2">
                              <div className="">
                                <DarkHeading className="px-2">
                                  {item.title}
                                </DarkHeading>
                                <div className="mb-3">
                                  <div className="d-flex">
                                    {item.iconsAndTitle.map((i) => (
                                      <div className="px-1 d-flex align-items-center">
                                        <img
                                          className="rounded-circle mx-1"
                                          height={17}
                                          width={17}
                                          src={i.icon}
                                          alt={item.title}
                                        />
                                        <span
                                          className="fs-14"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {i.title}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BoxStyle>
            ))} */}
            <div className="d-flex align-items-center">
              <Paragraph className="mb-0">
                Job name can be very big or small
              </Paragraph>
              <Paragraph className="mb-0 fs-12 mx-1">
                This is small JD description and can be see here and if you want
                to read click on{" "}
                <SpanYellow className="fs-12 fw-600">Read More</SpanYellow>
              </Paragraph>
            </div>
          </div>
        </div>
        <div className="col-12">
          <BoxStyle className="py-3 my-2">
            <div className="card-slider p-3">
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={20}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay]}
                className="mySwiper "
              >
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
                <SwiperSlide>
                  <InterviewPageScoreCard />
                </SwiperSlide>
              </Swiper>
            </div>
          </BoxStyle>
        </div>
        <div className="col-12">
          <BoxStyle className="py-3 my-2 p-2">
            <div className="">
              <div className="d-flex">
                <div className="col-9">
                  <div className="d-flex gx-0">
                    <div className="col-1">
                      <img
                        src="https://i.pravatar.cc/80?img=59"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="col-11 ms-4">
                      <div className="d-flex gx-0">
                        <div className="col-3">
                          <Paragraph className="mb-0">
                            Business analyst
                          </Paragraph>
                        </div>
                        <div className="col-4">
                          <Paragraph className="mb-0">
                            <AiTwotoneCalendar />
                            Applied date :- 21/12/2022
                          </Paragraph>
                        </div>
                        <div className="col-5">
                          <Paragraph className="mb-0">
                            <MdPhone /> Phone Number :- +91 00000 00000
                          </Paragraph>
                        </div>
                      </div>
                      <div className="d-flex gx-0">
                        <div className="col-3">
                          <Paragraph className="mb-0 fw-600">
                            Ranjan sharma
                          </Paragraph>
                        </div>
                        <div className="col-4">
                          <Paragraph className="mb-0">
                            <MdLocationPin /> Location :- Mumbai
                          </Paragraph>
                        </div>
                        <div className="col-5">
                          <Paragraph className="mb-0">
                            <MdEmail /> Email :- Email@email.com
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-3 ms-4">
                  <div className="d-flex align-items-center">
                    <ButtonBlue className="px-2 fw-700">
                      Download Resume | <MdAttachEmail fontSize={24} />
                    </ButtonBlue>
                    <MdMoreHoriz fontSize={30} className="cursor-pointer" />
                  </div>
                </div>
              </div>
            </div>
            <div className="my-2">
              <div className="row align-items-center">
                <div className="col-4">
                  <ButtonBlue className="primary w-100 fw-700 fs-20">
                    Schedule interview
                  </ButtonBlue>
                </div>
                <div className="col-2">
                  <ButtonPurpleOutline className="primary px-3 fs-16 fw-500 secondary">
                    Shortlist | <MdApproval fontSize={20} />
                  </ButtonPurpleOutline>
                </div>
                <div className="col-2">
                  <ButtonPurpleOutline className="primary px-2 fs-16 fw-500 secondary">
                    Keep in View | <MdRemoveRedEye fontSize={20} />
                  </ButtonPurpleOutline>
                </div>
                <div className="col-2">
                  <ButtonPurpleOutline className="primary px-3 fs-16 fw-500 secondary">
                    Reject | <MdOutlineClose fontSize={20} />
                  </ButtonPurpleOutline>
                </div>
                <div className="col-2 px-2">
                  <ButtonBlue className="success px-3 fw-700">
                    Hire Me
                  </ButtonBlue>
                </div>
              </div>
            </div>
          </BoxStyle>
        </div>
        <div className="my-5">
          <div className="container">
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <div className="border-10-yellow overflow py-5">
                    <Steps counter={3} />
                  </div>
                </div>
                <div className="col-4">
                  <div className="border-10-yellow overflow-hidden">
                    <WebCam />
                  </div>
                </div>
                <div className="col-4">
                  <div className="border-10-yellow">
                    <DarkHeading className="fs-14 mx-3">
                      Video Transcript
                    </DarkHeading>
                    <div className="d-flex">
                      <div className="col-2 mx-1">
                        <img src={video} height={40} width={40} />
                      </div>
                      <div className="col-10">
                        <Paragraph className="fs-low line-22 px-1">
                          What ever question you have you can put here and
                          answer the question?
                        </Paragraph>
                      </div>
                    </div>
                    <div className="d-flex">
                      <Paragraph className="mx-2 line-22 px-1">
                        Cum sed fringilla faucibus aliquam eu. Tortor
                        pellentesque diam senectus sit in eget cras elementum
                        feugiat. Tincidunt posuere viverra felis magna. Vitae
                        lectus dictumst ultricies nulla vitae bibendum nibh.
                        Viverra venenatis duis erat turpis. Elit eu et lorem dui
                        pellentesque. Sed imperdiet eget amet congue facilisi
                        nunc ac. Ultricies euismod amet est cras. Ut egestas et
                        venenatis nec. Blandit dignissim odio vitae amet enim
                        sed in.
                      </Paragraph>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardBoxStyle>
  );
};
