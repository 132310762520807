import React, { useEffect, useState, useCallback } from "react";
import {
  BoxStyle,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import { Form, Pagination } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import PracticeInterviewCard from "./PracticeInterviewCard";
import Loading from "components/common/Loading";
import {
  GetAllInterviewCorporate,
  GetIntervieweeDetails,
} from "../../../axios/apis";
import "./interviewee.css";

export const JobInterview = () => {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [attemptedInterviews, setAttemptedInterviews] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchInterviews = useCallback(async () => {
    setLoading(true);
    try {
      const response = await GetAllInterviewCorporate({
        page,
        limit: 10,
        title: search,
      });

      console.log("API Response:", response.data);

      const filteredInterviews = response.data.interviews.filter(
        (interview) =>
          interview.creator_type === "corporate" &&
          !interview.draft &&
          interview.interviewquestions.length > 0 &&
          interview.open
      );

      console.log("Filtered Interviews:", filteredInterviews);

      setInterviews(filteredInterviews);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching interviews:", error);
    } finally {
      setLoading(false);
    }
  }, [page, search]);

  const fetchInterviewDetails = useCallback(async () => {
    setLoading(true);
    try {
      const response = await GetIntervieweeDetails();
      const ids = response.data.message.interviewResults.map(
        (data) => data._id
      );
      setAttemptedInterviews(ids);
    } catch (error) {
      console.error("Error fetching interviewee details:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInterviews();
  }, [fetchInterviews]);

  useEffect(() => {
    fetchInterviewDetails();
  }, [fetchInterviewDetails]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      fetchInterviews();
    }
  };

  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container my-0">
            <div className="row">
              <YellowHeading className="my-2">Job Interviews</YellowHeading>
              <div className="px-2 mb-3">
                <Form.Control
                  className="form-input"
                  name="search"
                  value={search}
                  onChange={handleSearchChange}
                  placeholder="Search"
                  type="text"
                  onKeyDown={handleSearchKeyDown}
                />
                <div className="eye-position">
                  <AiOutlineSearch
                    onClick={fetchInterviews}
                    className="search-icon pe-1"
                    size={28}
                  />
                </div>
              </div>
              <div className="mt-0">
                {interviews.length > 0 ? (
                  interviews.map((item) => (
                    <PracticeInterviewCard
                      isAttempted={attemptedInterviews.includes(item._id)}
                      key={item._id}
                      item={item}
                    />
                  ))
                ) : (
                  <h6>Currently No Job Interview.</h6>
                )}
                <Pagination className="justify-content-center">
                  <Pagination.First
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                  />
                  <Pagination.Prev
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                  />
                  {Array.from({ length: totalPages }, (_, i) => (
                    <Pagination.Item
                      key={i}
                      active={page === i + 1}
                      onClick={() => setPage(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={page === totalPages}
                  />
                  <Pagination.Last
                    onClick={() => setPage(totalPages)}
                    disabled={page === totalPages}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
