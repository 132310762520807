import styled from "styled-components";
export const SidePanelPrimaryBox = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.white};
  height: calc(100vh - 70px);
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
    background: ${({ theme }) => theme.primary};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${({ theme }) => theme.secondary};
  }
  &.bg-purple{
  background: rgb(82, 25, 134);
  color:#ffffff   ;
  box-shadow: none ;

}
`;

export const SidePanelSecondaryBox = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.white};
  height: 70px;

  &.aligncenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
