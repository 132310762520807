import { Table } from "components/Table/Table";
import { BackButton } from "components/common/BackButton";
import {
  BoxStyle,
  ButtonBlue,
  DashboardBoxStyle,
  Paragraph,
  SpanYellow,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import {
  DeleteTeam,
  GetAllCreatorInterview,
  GetCollegeStudents,
  GetCollegeTeamById,
  RemoveTeamStudent,
  UpdateCollegeTeam,
} from "../../../axios/apis";
import { useNavigate, useParams } from "react-router";
import { Form, Modal, Spinner } from "react-bootstrap";
import Moment from "react-moment";
import { BsThreeDots, BsArrowLeft } from "react-icons/bs";
import Loading from "components/common/Loading";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

export const TeamDetails = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [teamdata, setTeamData] = useState({});
  const [editTeam, setEditTeam] = useState(false);
  const params = useParams();
  const [readmore, setreadmore] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [interviewList, setInterviewList] = useState([]);
  const [students, setStudents] = useState([]);
  const [allstudents, setallstudents] = useState([]);
  const GetTeamDetails = async () => {
    setLoading(true);
    try {
      const response = await GetCollegeTeamById({ id: params.id });
      const response2 = await GetCollegeStudents();
      const response3 = await GetAllCreatorInterview();
      setInterviewList(response3.data.interviews);
      let studentarr = [];
      response.data.data.students.map((data) => studentarr.push(data._id));
      let allstu = [];
      response2.data.data.map((data) =>
        allstu.push({ _id: data?._id, value: data?.email, label: data?.email })
      );
      // console.log(allstu);
      setallstudents(allstu);
      let studentsdata = response2.data.data.filter(
        (i) => studentarr.indexOf(i._id) === -1
      );
      setStudentList(studentsdata);
      let interviewarr = [];
      response.data.data.interview.map((data) => interviewarr.push(data?._id));
      setnewInterview(response?.data?.data?.interview);
      setTeamData(response?.data?.data);
      setStudents(response?.data?.data?.students || []);
      // let allstudent

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  // console.log(teamdata);
  useEffect(() => {
    GetTeamDetails();
  }, [params.id]);

  const [newStudents, setnewStudents] = useState([]);
  const [newInterview, setnewInterview] = useState([]);
  const [interviewListItems, setInterViewListItems] = useState([]);
  const [isStudent, setIsStudent] = useState(true);
  const handleinterviewSkillChange = (data) => {
    // console.log(data);
    if (data === "") {
      return;
    } else if (newStudents.length !== 0 && newStudents.includes(data)) {
      toast.error("Student Already Added", ToastStyles);
    } else {
      setnewStudents((newStudents) => [...newStudents, data]);
    }
  };
  const handleinterviewChange = (data) => {
    // console.log(data);
    if (data === "") {
      return;
    } else if (newInterview.length === 20) {
      toast.error("20 Interviews Already added", ToastStyles);
    } else if (newInterview.length !== 0 && newInterview.includes(data)) {
      // setnewStudents((newStudents) =>
      //   newStudents.filter((data2, index) => data2 !== data)
      // );
      toast.error("Interview Already Added", ToastStyles);
    } else {
      setnewInterview((newInterview) => [...newInterview, data]);
    }
  };

  const removethisStudent = (indexToRemove) => {
    setnewStudents([
      ...newStudents.filter((_, index) => index !== indexToRemove),
    ]);
  };
  const removethisInterview = (indexToRemove) => {
    setnewInterview([
      ...newInterview.filter((_, index) => index !== indexToRemove),
    ]);
  };

  const handleChange = (e) => {
    setTeamData({ ...teamdata, [e.target.name]: e.target.value });
  };
  const RemoveFromTeam = async (row) => {
    let yes = window.confirm("Are you Sure want to Remove this Student?");
    if (yes) {
      try {
        console.log(row);
        const payload = {
          studentid: row?._id,
          id: params.id,
        };
        const res = await RemoveTeamStudent(payload);
        GetTeamDetails();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [updateloading, setupdateloading] = useState(false);
  const update = async () => {
    setupdateloading(true);

    let updateBody = teamdata;
    delete updateBody.__v;
    delete updateBody.createdAt;
    delete updateBody.updatedAt;
    delete updateBody._id;
    delete updateBody.students;
    // delete updateBody.interview
    // updateBody.students = updateBody.students.concat(newStudents)
    updateBody.newStudents = newStudents;
    // updateBody.interview = newInterview
    let data = {
      id: params.id,
      updateBody,
    };
    try {
      const response = await UpdateCollegeTeam(data);
      GetTeamDetails();
      setupdateloading(false);
      setEditTeam(false);
      toast.success("Updated Successfully", ToastStyles);
      setnewStudents([]);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setupdateloading(false);
    }
    setupdateloading(false);
  };
  const columns = [
    {
      field: "firstname",
      headerName: "Name",
      minWidth: 180,
      renderCell: (data) => (
        <div>{data?.row?.firstname + " " + (data?.row?.lastname || "")}</div>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      renderCell: (data) => <div>{data?.row?.email}</div>,
    },
    {
      field: "phoneNumber",
      headerName: "Contact No",

      minWidth: 200,
      renderCell: (data) => <div>{data?.row?.phoneNumber}</div>,
    },
    {
      field: "className",
      headerName: "Class",
      minWidth: 200,
      renderCell: (data) => <div>{data?.row?.className}</div>,
    },
    {
      field: "attemptedInterviews",
      headerName: "Interviews Attempted",
      minWidth: 200,
      renderCell: (data) => (
        <div className="d-flex align-items-center ">
          {data?.row?.interviewResults?.length || "0"}
        </div>
      ),
    },
    {
      field: "report",
      headerName: "Reports",
      minWidth: 120,
      renderCell: (data) => (
        <div
          onClick={() => {
            setIsStudent(false);
            setInterViewListItems(data?.row?.interviewResults);
            // console.log(data);
          }}
          className="d-flex justify-ceontent-center align-items-center text-hover"
        >
          View Reports
        </div>
      ),
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 120,
      renderCell: (data) => (
        <div
          onClick={() => RemoveFromTeam(data.row)}
          className="d-flex justify-ceontent-center align-items-center text-hover"
        >
          Remove
        </div>
      ),
    },
    // {
    //   field: "",
    //   headerName: "Action",
    //   minWidth: 115,
    //   renderCell: (data) => (
    //     <div
    //       onClick={(() =>console.log("Remove This"))}
    //       className="d-flex align-items-center text-hover"
    //     >
    //       Remove
    //     </div>
    //   ),
    // },
  ];

  // console.log(interviewListItems, "dggdgd", students, "Datatatata");
  const columns2 = [
    {
      field: "icon",
      headerName: "",
      minWidth: "50",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <img
            className="user-img"
            src={params?.row?.interview_id?.icon}
            alt="logo"
          />
        </div>
      ),
    },

    {
      field: "updatedAt",
      headerName: "Date of Attempt",
      minWidth: "150",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <Moment format="DD/MM/YYYY" date={params?.value} />
        </div>
      ),
    },
    {
      field: "createdAt",
      headerName: "Time of Attempt",
      minWidth: "150",
      renderCell: (params) => (
        <div className="d-flex align-items-center">
          <Moment format="hh:mm A" date={params?.value} />
        </div>
      ),
    },
    // {
    //   field: "video",
    //   headerName: "Interviews",
    //   minWidth: "150",
    //   renderCell: (params) => (
    //     <SpanYellow
    //       onClick={() =>
    //         navigate(`/college/my-interviews/${params?.row?.interview_id}`)
    //       }
    //     >
    //       Interview
    //     </SpanYellow>
    //   ),
    // },
    {
      field: "video",
      headerName: "Interviews",
      minWidth: "150",
      renderCell: (params) => (
        <SpanYellow
          onClick={() =>
            navigate(`/college-student/interview-video/${params?.row?._id}`)
          }
        >
          Interview
        </SpanYellow>
      ),
    },
    {
      field: "reports",
      headerName: "Reports",
      minWidth: "150",
      renderCell: (data) => (
        <div
          onClick={() => {
            if (isStudent) {
              return;
            } else {
              navigate(`/college-student/reports/${data.row._id}`);
            }
          }}
          className="d-flex align-items-center text-hover"
        >
          View Reports
        </div>
      ),
    },
  ];
  const [show, setshow] = useState(false);
  const [teamname, setTeamname] = useState("");
  const handleClose = () => {
    setshow(false);
  };
  const navigate = useNavigate();
  const deleteTeam = async () => {
    if (teamname !== teamdata.name) {
      toast.error("Team name is incorrect", ToastStyles);
    } else {
      setLoading(true);
      try {
        const res = await DeleteTeam({ id: params.id });
        toast.success("Team deleted Successfully", ToastStyles);
        navigate(-1);
        setLoading(false);
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log("error");
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you absolutely sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="">
            This action cannot be undone. This will permanently delete the{" "}
            <b>{teamdata?.name} </b>team, and remove all students.
          </h6>
          <Form.Group className="" controlId="formBasicEmail">
            <Form.Label className="form-title">
              Please type "<b>{teamdata?.name}</b>" to confirm.
            </Form.Label>
            <Form.Control
              className="form-input"
              type="text"
              name="teamname"
              value={teamname}
              onChange={(e) => setTeamname(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBlue
            onClick={deleteTeam}
            className="h-40 w-100"
            style={{ backgroundColor: "red" }}
          >
            Delete this team
          </ButtonBlue>
        </Modal.Footer>
      </Modal>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <div className="my-1  p-2">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center">
                  <BackButton />
                </div>
              </div>
            </div>
            <BoxStyle className="mt-2  p-2 p-sm-3">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-end  p-0">
                    <ButtonBlue
                      style={{ backgroundColor: "red" }}
                      className="h-40"
                      onClick={() => setshow(true)}
                      width="150px"
                    >
                      Delete
                    </ButtonBlue>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Team Name</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      name="name"
                      disabled={!editTeam}
                      value={teamdata?.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-12 col-sm-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Class</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      disabled={!editTeam}
                      name="studyclass"
                      value={teamdata?.studyclass}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Standard</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      name="standard"
                      disabled={!editTeam}
                      value={teamdata?.standard}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="col-12 col-sm-6">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="form-title">Year</Form.Label>
                    <Form.Control
                      className="form-input"
                      type="text"
                      name="year"
                      disabled={!editTeam}
                      value={teamdata?.year}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              {editTeam && (
                <div className="row">
                  <div className="col-12">
                    <Paragraph className="fs-14 fw-500 mb-1 secondary">
                      Add Students*
                    </Paragraph>

                    <div className="tags-input">
                      {newStudents.length > 0 ? (
                        <ul id="tags">
                          {newStudents?.map((tag, index) => (
                            <li key={index} className="tag">
                              <span className="tag-title">
                                {studentList?.find((data) => data._id == tag)
                                  ?.firstname +
                                  " " +
                                  (studentList?.find((data) => data._id == tag)
                                    ?.lastname || "")}
                              </span>
                              <span
                                className="tag-close-icon"
                                onClick={() => removethisStudent(index)}
                              >
                                x
                              </span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul id="tags">
                          <h6>Select Students</h6>
                        </ul>
                      )}
                      {/* <Select
                      className="b-none border-10 secondary"
                      aria-hidden
                      onChange={(e) => handleinterviewSkillChange(e.target.value)}
                    >
                      <option default value="">
                        Select here
                      </option>
                      {studentList?.map((data) => (
                        <option value={data?._id}>
                          {data?.firstname + " " + (data?.lastname || "")}
                        </option>
                      ))}
                    </Select> */}
                      <Select
                        className="select-interview"
                        createOptionPosition="first"
                        closeMenuOnSelect={true}
                        onChange={(e) => handleinterviewSkillChange(e._id)}
                        options={allstudents}
                        // onInputChange={changeInputHandler}
                        isClearable={false}

                        // inputValue={titleInput}
                      />
                    </div>
                  </div>
                  {/* <div className="col-12">
                  <Paragraph className="fs-14 fw-500 mb-1 secondary">
                    Assign Interview*
                  </Paragraph>
                  
                  <div className="tags-input">
                    {newInterview.length > 0 ? (
                      <ul id="tags">
                        {newInterview?.map((tag, index) => (
                          <li key={index} className="tag">
                            <span className="tag-title">
                              {interviewList?.find((data) => data._id == tag)?.jobtitle
                               }
                            </span>
                            <span
                              className="tag-close-icon"
                              onClick={() => removethisInterview(index)}
                            >
                              x
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <ul id="tags">
                        <h6>Add upto 20 Interviews</h6>
                      </ul>
                    )}
                    <Select
                      className="b-none border-10 secondary"
                      aria-hidden
                      onChange={(e) => handleinterviewChange(e.target.value)}
                    >
                      <option default value="">
                        Select here
                      </option>
                      {interviewList?.map((data) => (
                        <option value={data?._id}>
                          {data?.jobtitle}
                        </option>
                      ))}
                    </Select>
                  </div>

                </div> */}
                </div>
              )}
              <div className="row">
                <div className="col-12">
                  {editTeam ? (
                    <>
                      <div className="d-flex justify-content-end my-2 p-0">
                        {updateloading ? (
                          <>
                            <ButtonBlue className="h-40" width="150px">
                              <Spinner animation="border" size="sm" />
                            </ButtonBlue>
                          </>
                        ) : (
                          <>
                            <ButtonBlue
                              onClick={update}
                              className="h-40"
                              width="150px"
                            >
                              Save
                            </ButtonBlue>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex justify-content-end my-2 p-0">
                        <ButtonBlue
                          className="h-40"
                          onClick={() => setEditTeam(true)}
                          width="150px"
                        >
                          Edit
                        </ButtonBlue>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row justify-content-between">
                {isStudent ? (
                  <div className="d-flex align-items-center">
                    <Paragraph className="mb-0">Students Details</Paragraph>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <BsArrowLeft
                      onClick={() => setIsStudent(true)}
                      className="me-3 cursor-pointer"
                    />
                    <Paragraph className="mb-0">Student Interviews</Paragraph>
                  </div>
                )}

                <div>
                  <Paragraph className="mb-0">
                    Created On :-{" "}
                    <Moment format="DD-MM-YYYY">{teamdata?.createdAt}</Moment>
                  </Paragraph>
                </div>
              </div>

              <Table
                columns={isStudent ? columns : columns2}
                checkbox={false}
                data={
                  isStudent
                    ? students.map((item, i) => {
                        return {
                          ...item,
                          id: item._id || i,
                          key: i,
                        };
                      })
                    : interviewListItems.map((item, i) => {
                        return {
                          ...item,
                          id: item._id || i,
                          key: i,
                        };
                      })
                }
                limit={10}
              />
            </BoxStyle>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
