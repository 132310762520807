import { ButtonBlue } from "components/common/CommonStyles";
import React from "react";
import { AiFillCheckCircle, AiOutlineStock } from "react-icons/ai";
import { BsFillHandbagFill } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import { MdOutlineTimelapse } from "react-icons/md";
import vcardimg from "assets/image/vcard-image.png";
import heartblue from "assets/image/heartblue.png";
import "./VCard.css";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

const DashboardVcard = ({ data }) => {
  const navigate = useNavigate();
  // console.log(data)
  const role = Cookies.get("role");
  return (
    <>
      <div className="recommended-vcard">
        <div className="vcard-sec-1">
          <img src={data?.poster} alt="card" />
        </div>
        <div className="vcard-sec-2">
          {/* <div className="vcard-title">{data.title}</div> */}
          <div className="vcard-desc d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div>
                <AiFillCheckCircle color=" #521986" size={14} />
                <p>Course Video</p>
              </div>
              <div>
                <MdOutlineTimelapse color=" #521986" size={14} />
                <p>{data.duration}</p>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-1">
              <div>
                <BsFillHandbagFill color=" #521986" size={14} />
                <p>Entry Level</p>
              </div>
              <div>
                <AiOutlineStock color=" #521986" size={14} />
                <p>Difficulty Level 1</p>
              </div>
            </div>
          </div>
        </div>
        <div className="vcard-sec-3 justify-content-end">
          {/* <div className="people-watched">
            <div className="people-icon">
              {" "}
              <FaUserFriends size={22} color=" #521986" />
            </div>
            <h6>127 Watched</h6>
          </div> */}
          <div className="vcard-more d-flex">
            {/* <img
              style={{ width: "20px", height: "20px" }}
              src={heartblue}
              alt="like"
            />
            <p>Know More</p> */}
            <ButtonBlue
              onClick={() => {
                if (role === "student") {
                  navigate(`/college-student/interview-courses/${data?.id}`);
                } else {
                  navigate(`/interviewee/interview-courses/${data?.id}`);
                }
              }}
              className="h-35 px-3"
            >
              {" "}
              Watch now
            </ButtonBlue>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardVcard;
