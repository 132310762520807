import { Table } from "components/Table/Table";
import { BackButton } from "components/common/BackButton";
import {
  BoxStyle,
  BoxYellowOutline,
  ButtonBlue,
  ButtonYellowFill,
  DarkHeading,
  DashboardBoxStyle,
  Paragraph,
  Select,
  SpanYellow,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { mockDataInterview } from "./mockData";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import {
  AssignInterview,
  GetCollegeTeams,
  GetSingleInterview,
} from "../../../axios/apis";
import { useNavigate, useParams } from "react-router";
import { Spinner, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import { IconButton } from "@mui/material";
import { MdFileCopy } from "react-icons/md";
import Loading from "components/common/Loading";
import Cookies from "js-cookie"; // Importing js-cookie for handling cookies

export const SingleMyInterview = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [interviewdata, setInterviewData] = useState({});
  const params = useParams();
  const [showquestions, setshoequestions] = useState(false);
  const [teams, setTeams] = useState([]);
  const [updateloading, setupdateloading] = useState(false);
  const [newTeams, setnewTeams] = useState([]);
  const [candidates, setcandidates] = useState(0);
  const [collegeId, setCollegeId] = useState("");

  useEffect(() => {
    const fetchedCollegeId = Cookies.get("collegeID"); // Assuming your cookie key is 'collegeId'
    setCollegeId(fetchedCollegeId);
    console.log("College ID from Cookies:", fetchedCollegeId);
  }, []);
  const navigate = useNavigate();
  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      console.log("response", response);
      let myteams = [];
      response?.data?.interview?.teams?.forEach((item) => {
        myteams?.push(item?._id);
      });
      // const filteredTeams = response.data.interview?.teams.filter(
      //   (data) => data.collegeid === collegeId
      // );
      // console.log("filterteams", filteredTeams);
      // console.log("myteams", myteams);
      // setnewTeams(filteredTeams);
      const interviewData = response.data.interview;

      // Assuming the teams come within the interview data and include details like collegeid
      const teamsIncluded = interviewData.teams || [];

      // Filter these teams based on the collegeid
      const filteredTeams = teamsIncluded.filter(
        (team) => team.collegeid === collegeId
      );
      console.log("filterteams", filteredTeams);

      // Extract just the IDs from the filtered teams
      const filteredTeamIds = filteredTeams.map((team) => team._id);
      console.log("filterteamsIds", filteredTeamIds);

      setnewTeams(filteredTeamIds); // Assuming you want to keep only the IDs of teams matching the collegeId
      setTeams(filteredTeams); // Keep the full team details if needed elsewhere in the component

      const response2 = await GetCollegeTeams();
      setTeams(response2?.data?.data);
      const filteredResults = response.data.interview?.interviewResult.filter(
        (data) => data.attemptedby_id.collegeid === collegeId
      );
      console.log(filteredResults);
      setInterviewData({
        ...response.data.interview,
        interviewResult: filteredResults,
      });
      let unique = [
        ...new Set(filteredResults.map((data) => data.attemptedby_id._id)),
      ];
      // setInterviewData(response.data.interview);
      // let unique = [
      //   ...new Set(
      //     response.data.interview?.interviewResult.map(
      //       (data) => data.attemptedby_id?._id
      //     )
      //   ),
      // ];
      setcandidates(unique.length);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (collegeId) {
      // Ensure collegeId is set before fetching details
      GetInterviewDetails();
    }
  }, [params.id, collegeId]); // Add collegeId as a dependency
  const handleteamChange = (data) => {
    if (data === "") {
      return;
    } else if (newTeams.length === 20) {
      toast.error("20 Interviews Already added", ToastStyles);
    } else if (newTeams.length !== 0 && newTeams.includes(data)) {
      // setnewStudents((newStudents) =>
      //   newStudents.filter((data2, index) => data2 !== data)
      // );
      toast.error("Interview Already Added", ToastStyles);
    } else {
      setnewTeams((newTeams) => [...newTeams, data]);
    }
  };
  const removethisTeam = (indexToRemove) => {
    setnewTeams([...newTeams.filter((_, index) => index !== indexToRemove)]);
  };
  const assignNewInterview = async () => {
    setupdateloading(true);
    if (newTeams?.length === 0) {
      toast.error("Please Add atleast one team", ToastStyles);
      return;
    }
    try {
      const response = await AssignInterview({
        interviewId: params.id,
        teams: newTeams,
      });
      // console.log(response);
      setupdateloading(false);
      toast.success("Teams Assigned Successfully", ToastStyles);
      GetInterviewDetails();
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setupdateloading(false);
    }
    setupdateloading(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {data?.row?.attemptedby_id?.name}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 180,
      renderCell: (data) => {
        // console.log("Rendering Email:", data.row?.attemptedby_id?.email);
        console.log("Rendering: ", data);
        return (
          <div className="d-flex align-items-center">
            {data.row?.attemptedby_id?.email}
          </div>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Applied Date",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="DD-MM-YYYY">{data.value}</Moment>
        </div>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Time",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="hh:mm:ss">{data.value}</Moment>
        </div>
      ),
    },
    // {
    //   field: "Role",
    //   headerName: "Role",

    //   minWidth: 180,
    //   renderCell: (data) => (
    //     <div className="d-flex align-items-center">
    //       {data?.row?.attemptedby_id?.role}
    //     </div>
    //   ),
    // },
    // {
    //   field: "video",
    //   headerName: "Video",
    //   minWidth: "150",
    //   renderCell: (params) => (
    //     <SpanYellow
    //       onClick={() =>
    //         navigate(`/college/interview-video/${params?.row?._id}`)
    //       }
    //     >
    //       Video
    //     </SpanYellow>
    //   ),
    //   renderCell: (data) => (
    //     <div
    //       onClick={() => navigate(`/college/reports/${data.row._id}`)}
    //       className="d-flex align-items-center text-hover"
    //     >
    //       Video
    //     </div>
    //   ),
    // },
    // {
    //   field: "score",
    //   headerName: "Vidoe Score",
    //   minWidth: 115,
    // },
    // {
    //   field: "status",

    //   minWidth: 115,

    //   headerName: "STATUS",
    // },
    // {
    //   field: "Selection",
    //   headerName: "Selection",
    //   minWidth: 120,
    // },
    {
      field: "reports",
      headerName: "Reports",
      minWidth: "150",
      renderCell: (data) => (
        <div
          onClick={() => navigate(`/college/reports/${data.row._id}`)}
          className="d-flex align-items-center text-hover"
        >
          Report
        </div>
      ),
    },
  ];
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller p-0 p-sm-2">
          <div className="container">
            <BoxStyle className="my-1  p-2">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center">
                  <BackButton />
                </div>
                <ButtonYellowFill
                  className="h-40"
                  style={{ width: "120px" }}
                  onClick={() => {
                    navigate(`/college/edit-interview/${params.id}`);
                  }}
                >
                  Edit
                </ButtonYellowFill>
              </div>
              <div className="p-2 d-flex justify-content-evenly">
                <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">
                    {candidates}
                  </DarkHeading>

                  <DarkHeading className="fs-14 text-center mb-0">
                    Total Students
                  </DarkHeading>
                </BoxYellowOutline>
                {/* <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">3</DarkHeading>
                  <DarkHeading className="fs-14 mb-0 text-center">
                    Yet to respond
                  </DarkHeading>
                </BoxYellowOutline> */}
                <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">
                    {interviewdata?.interviewResult?.length || 0}
                  </DarkHeading>
                  <DarkHeading className="fs-14 text-center mb-0">
                    Responded
                  </DarkHeading>
                </BoxYellowOutline>
                {/* <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">3</DarkHeading>
                  <DarkHeading className="fs-14 text-center mb-0">
                    Shortlisted
                  </DarkHeading>
                </BoxYellowOutline> */}
                {/* <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">3</DarkHeading>
                  <DarkHeading className="fs-14 text-center mb-0">
                    Hired
                  </DarkHeading>
                </BoxYellowOutline> */}
              </div>
              <div className="my-3  p-2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Paragraph>Job Status 🟢</Paragraph>
                    <Paragraph>
                      <Tooltip title="Copy to Clipboard">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              window.location.origin +
                                "/interview/apply-interview/" +
                                params.id
                            );
                            toast.success(
                              "Link Copied to Clipboard",
                              ToastStyles
                            );
                          }}
                          className="d-flex align-items-center ms-1"
                          style={{
                            fontSize: "16px",
                            padding: "0",
                            margin: "0",
                          }}
                        >
                          Copy Link <MdFileCopy fontSize={22} color="#521986" />
                        </IconButton>
                      </Tooltip>
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph className="mb-0">
                      Created On :-{" "}
                      <Moment format="DD-MM-YYYY">
                        {interviewdata?.createdAt}
                      </Moment>
                    </Paragraph>
                    <Paragraph className="mb-0">
                      Expiry Date :-{" "}
                      <Moment format="DD-MM-YYYY">
                        {interviewdata?.expirydate}
                      </Moment>
                    </Paragraph>
                  </div>
                </div>
                <div className="">
                  <Paragraph className="mb-0 ">
                    {interviewdata?.jobtitle} &nbsp;
                    <div
                      style={{ fontSize: "13px" }}
                      dangerouslySetInnerHTML={{
                        __html: interviewdata?.jobdescription,
                      }}
                    ></div>
                  </Paragraph>
                </div>
                {showquestions && (
                  <>
                    {interviewdata?.interviewquestions.map(
                      (question, index) => (
                        <h6>
                          Question {index + 1}: {question.question}
                        </h6>
                      )
                    )}
                  </>
                )}
                <div
                  onClick={() => setshoequestions(!showquestions)}
                  className="d-flex text-hover"
                >
                  {!showquestions ? "View Questions" : "Hide Questions"}
                </div>

                <div>
                  <div className="col-12">
                    <Paragraph className="fs-14 fw-500 mb-1 secondary">
                      Assign Teams*
                    </Paragraph>

                    <div className="tags-input">
                      {newTeams.length > 0 ? (
                        <ul id="tags">
                          {newTeams?.map((tag, index) => (
                            <li key={index} className="tag">
                              <span className="tag-title">
                                {teams?.find((data) => data._id == tag)?.name}
                              </span>
                              <span
                                className="tag-close-icon"
                                onClick={() => removethisTeam(index)}
                              >
                                x
                              </span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul id="tags">
                          <h6>Add upto 20 Teams</h6>
                        </ul>
                      )}
                      <Select
                        className="b-none border-10 secondary"
                        aria-hidden
                        onChange={(e) => handleteamChange(e.target.value)}
                      >
                        <option default value="">
                          Select here
                        </option>
                        {teams?.map((data) => (
                          <option value={data?._id}>{data?.name}</option>
                        ))}
                      </Select>
                    </div>
                    <div className="mt-2">
                      {updateloading ? (
                        <>
                          <ButtonBlue className="h-40" width="150px">
                            <Spinner animation="border" size="sm" />
                          </ButtonBlue>
                        </>
                      ) : (
                        <>
                          <ButtonBlue
                            onClick={assignNewInterview}
                            className="h-40"
                            width="150px"
                          >
                            Save
                          </ButtonBlue>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </BoxStyle>
            <div className="my-1 p-2">
              <Table
                columns={columns}
                checkbox={false}
                data={interviewdata?.interviewResult?.map((item) => {
                  return {
                    ...item,
                    id: item._id,
                    key: item._id,
                  };
                })}
                limit={10}
              />
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
