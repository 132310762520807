// import React from "react";
// import { BiArrowBack } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";

// export const BackButton = () => {
//   const navigate = useNavigate();
//   return (
//     <div className="button-cotainer">
//       <button className="button-yellow" onClick={() => navigate(-1)}>
//         <BiArrowBack />
//         <span>|</span>
//         Back
//       </button>
//     </div>
//   );
// };
import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useLocation } from "react-router-dom";

export const BackButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    console.log("Current location:", location.pathname); // Debugging: Check current location
    console.log("History state before navigation:", window.history.state); // Debugging: Check browser's history state

    // Alternative navigation using window.history
    if (window.history.length > 1) {
      window.history.back();
    } else {
      navigate(-1); // Fallback to useNavigate
    }

    setTimeout(() => {
      console.log("History state after navigation:", window.history.state); // Debugging: Check history state after navigation
    }, 100);
  };

  return (
    <div className="button-container">
      <button className="button-yellow" onClick={handleBackClick}>
        <BiArrowBack />
        <span className="separator">|</span>
        <span className="button-text">Back</span>
      </button>
    </div>
  );
};
