import React, { useEffect, useState } from "react";
import {
   BoxStyle,
   DashboardBoxStyle,
   YellowHeading,
} from "components/common/CommonStyles";

import "./interview.css";
import { BackButton } from "components/common/BackButton";
import { Spinner } from "react-bootstrap";
import { GetAllCreatorInterview } from "../../../axios/apis";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import DraftInterviewCard from "components/Card/DraftInterviewCard";
import Loading from "components/common/Loading";


export const DraftInterview = () => {

   const [draftinterviews, setDraftinterviews] = useState(false);
   const [loading, setLoading] = useState(true);
   const [interview, setInterView] = useState([]);

   const GetAllInterviews = async () => {
      setLoading(true)
      try {
         const response = await GetAllCreatorInterview();
         setInterView(response.data.interviews);
         let draftinterview = response?.data?.interviews.filter((data) => data?.draft === true)
         setDraftinterviews(draftinterview)
         // console.log(response.data.interviews);
         setLoading(false)
      } catch (error) {
         console.log(error);
         toast.error("Try Again", ToastStyles)
         setLoading(false)
      }
   };
   // console.log(draftinterviews)
   useEffect(() => {
      GetAllInterviews();
   }, []);

   return (
      <>
         <Toaster />
         {loading ? (
            <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
            <Loading/>

            </DashboardBoxStyle>
         ) : (
            <DashboardBoxStyle className="scroller">
               <div className="container my-2">
                  <div className="my-1  p-2">
                     <div className="d-flex justify-content-between flex-wrap">
                        <div className="d-flex align-items-center">
                           <BackButton />
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="mt-3">
                        <BoxStyle className="border-10">
                           <YellowHeading>Draft Interviews</YellowHeading>
                           {draftinterviews.length > 0 ? (draftinterviews.map((item, i) => (
                              <DraftInterviewCard key={i} item={item} />
                           ))) : (<><div className="my-3"><h6>No Draft Interviews</h6></div></>)}
                        </BoxStyle>
                     </div>
                  </div>
               </div>
            </DashboardBoxStyle>)}
      </>
   );
};
