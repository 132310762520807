import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

export const MultiStepProgress = ({progress}) => {
  return (
    <ProgressBar
      height={5}
      percent={progress===1?75:25}
      backgroundColor="#FFDDB8"
      filledBackground="#9747FF"
    >
      <Step transition="scale">
        {({ accomplished }) => (
          <div class="gradient-wrapper">
            <div id="maincircle">1</div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          //   <img style={{ pointerEvents: "none" }} width="70" src={step2} />
          <div class="gradient-wrapper">
            <div id="maincircle">2</div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished }) => (
          <div class="gradient-wrapper">
            <div id="maincircle">3</div>
          </div>
        )}
      </Step>
    </ProgressBar>
  );
};
