import React, { useEffect } from "react";
import { SideBarAndHeader } from "screen/SideBarAndHeader";
import { SidePanelData } from "contants/SidepanelData";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

export const Index = () => {
  const navigate = useNavigate();
  let loginToken =  Cookies.get("token")
  useEffect(() => {
    if(!loginToken){
      navigate('/')
    }
  }, [loginToken])
  
  return <SideBarAndHeader data={SidePanelData} />;
};
