import React, { useEffect, useState } from "react";
import {
  BoxStyle,
  ButtonBlue,
  DarkHeading,
  DashboardBoxStyle,
  YellowButton,
} from "components/common/CommonStyles";
import { useNavigate, useParams } from "react-router";
import { Form, Modal, Spinner } from "react-bootstrap";
import { ToastStyles } from "components/common/ToastStyle";
import { toast, Toaster } from "react-hot-toast";
import {
  CreateNewTeam,
  DeleteMultipleStudents,
  GetCollegeStudents,
  GetCollegeTeams,
  StudentSignUp,
  GetCollegeStudentsbyCollegeId,
} from "../../../axios/apis";
import { TeamCard } from "./TeamCard";
import { Table } from "components/Table/Table";
import { BsThreeDots } from "react-icons/bs";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";

export const Candidate = ({ type }) => {
  const params = useParams();
  // console.log(type);
  const navigate = useNavigate();
  const [teamdata, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentdata, setStudentData] = useState([]);
  const [showcreateStudent, setshowCreateStudent] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState([]);
  // console.log(studentdata)
  const [bulkStudentEmails, setBulkStudentEmails] = useState("");

  const FetchData = async () => {
    setLoading(true);
    const collegeID = Cookies.get("collegeID"); // Assuming the collegeID is stored in cookies or another state management
    try {
      const response = await GetCollegeStudentsbyCollegeId(collegeID);
      console.log("response", response);
      const response2 = await GetCollegeTeams();
      setStudentData(response.data.data);
      setTeamData(response2.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  // console.log(teamdata);
  useEffect(() => {
    FetchData();
  }, []);

  const createStudentModalClose = () => {
    setshowCreateStudent(false);
  };
  const createStudentModalOpen = () => {
    setshowCreateStudent(true);
  };
  const [studentmodalloading, setStudentmodalLoading] = useState(false);
  const collegeID = Cookies.get("collegeID");
  const [studentDetails, setStudentDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    password: "viosa@2022",
    collegeid: collegeID,
    role: "student",
  });
  const handlestudentdetails = (e) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
  };
  const createNewStudent = async (e) => {
    e.preventDefault();
    // console.log(studentDetails);
    setStudentmodalLoading(true);
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Validate email format
    if (!emailRegex.test(studentDetails.email)) {
      toast.error("Invalid email format", ToastStyles);
      setStudentmodalLoading(false);
      return;
    }
    // Check against a list of known spam domains
    const spamDomains = ["example.com", "spamdomain.com"]; // Add your own list of spam domains
    const domain = studentDetails.email.split("@")[1];
    if (spamDomains.includes(domain)) {
      toast.error("Email from this domain is not allowed", ToastStyles);
      setStudentmodalLoading(false);
      return;
    }
    try {
      const response = await StudentSignUp(studentDetails);
      // console.log(response);
      toast.success("Student Added", ToastStyles);
      setStudentmodalLoading(false);
      setStudentDetails({
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        password: "viosa@2022",
        collegeid: collegeID,
        role: "student",
      });
      createStudentModalClose();
      FetchData();
    } catch (error) {
      console.log(error);
      toast.error("Error in Adding Student", ToastStyles);
      setStudentmodalLoading(false);
    }
  };

  //   add team
  const [teamdetails, setTeamDetails] = useState({
    name: "",
    collegeid: collegeID,
    studyclass: "",
    standard: "",
    year: "",
    students: [],
  });
  const handleTeamDetails = (e) => {
    setTeamDetails({
      ...teamdetails,
      [e.target.name]: e.target.value,
    });
  };
  const [createTeamModal, setCreateTeamModal] = useState(false);
  const createTeamModalOpen = () => {
    setCreateTeamModal(true);
  };
  const createTeamModalClose = () => {
    setCreateTeamModal(false);
    setTeamDetails({
      name: "",
      collegeid: collegeID,
      studyclass: "",
      standard: "",
      year: "",
      students: [],
    });
  };
  const [teammodalloading, setTeammodalloading] = useState(false);

  const addNewTeam = async (e) => {
    e.preventDefault();
    const { name, studyclass, collegeid, standard, year } = teamdetails;
    if (name === "") {
      toast.error("Please Add Team name", ToastStyles);
    } else if (studyclass === "") {
      toast.error("Please Add Class", ToastStyles);
    } else if (standard === "") {
      toast.error("Please Add Standard", ToastStyles);
    } else if (year === "") {
      toast.error("Please Add Year", ToastStyles);
    } else {
      setTeammodalloading(true);
      try {
        let data = {
          name,
          studyclass,
          collegeid,
          standard,
          year,
          students: teamStudents,
        };
        const response = await CreateNewTeam(data);
        debugger;
        toast.success("Team Added Successfully", ToastStyles);
        createTeamModalClose();
        setTeammodalloading(false);
        FetchData();
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setTeammodalloading(false);
      }
    }
  };
  // console.log(teamdetails.students);

  const [teamStudents, setTeamStudents] = useState([]);
  const removethisStudent = (indexToRemove) => {
    setTeamStudents([
      ...teamStudents.filter((_, index) => index !== indexToRemove),
    ]);
  };
  const handleStudentListChange = (e) => {
    // console.log(e.target.value);
    let data = e.target.value;
    if (data === "") {
      return;
    } else if (teamStudents.length === 20) {
      toast.error("20 Students Already added", ToastStyles);
    } else if (teamStudents.length !== 0 && teamStudents.includes(data)) {
      toast.error("Student Already Added", ToastStyles);
    } else {
      // Email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Validate email format
      if (!emailRegex.test(data)) {
        toast.error("Invalid email format", ToastStyles);
        return;
      }

      // Check against a list of known spam domains
      const spamDomains = ["example.com", "spamdomain.com", "gmial.com"]; // Add your own list of spam domains
      const domain = data.split("@")[1];
      if (spamDomains.includes(domain)) {
        toast.error("Email from this domain is not allowed", ToastStyles);
        return;
      }
      setTeamStudents((teamStudents) => [...teamStudents, data]);
      e.target.value = "";
    }
  };
  const [teamStudent, setTeamStudent] = useState(true);
  // console.log(teamStudents);
  const columns = [
    {
      field: "firstname",
      headerName: "Name",
      minWidth: 180,
      renderCell: (data) => (
        <div>{data?.row?.firstname + " " + (data?.row?.lastname || "")}</div>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Contact No",

      minWidth: 150,
    },
    {
      field: "className",
      headerName: "Class",
      minWidth: 120,
      renderCell: (data) => <div>{data?.row?.className}</div>,
    },
    {
      field: "attemptedInterviews",
      headerName: "Interviews Attempted",
      minWidth: 200,
      renderCell: (data) => (
        <div className="d-flex justify-content-center align-items-center mx-auto">
          {data?.row?.interviewResults?.length || "0"}
        </div>
      ),
    },
    {
      field: "selct",
      headerName: "Select",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex justify-ceontent-center align-items-center ">
          <Form.Check
            name="check"
            className="checkbox"
            checked={selectedStudent.includes(data?.row?._id)}
            onChange={() => handleSelectedStudent(data?.row?._id)}
          />
        </div>
      ),
    },
  ];
  // console.log(selectedStudent)

  // select and delete

  const handleSelectedStudent = (e) => {
    if (selectedStudent.includes(e)) {
      setSelectedStudent(selectedStudent.filter((student) => student !== e));
    } else {
      setSelectedStudent([...selectedStudent, e]);
    }
  };

  const [show, setshow] = useState(false);
  const [deletestu, setdeletestu] = useState("");
  const handleClose = () => {
    setshow(false);
  };

  const deleteStudent = async () => {
    if (deletestu !== "DeleteStudents") {
      toast.error("Incorrect Text", ToastStyles);
    } else {
      handleClose();
      setLoading(true);
      try {
        const res = await DeleteMultipleStudents({
          studentsId: selectedStudent,
        });
        toast.success("Students deleted Successfully", ToastStyles);
        setLoading(false);
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log("error");
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you absolutely sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="">
            This action cannot be undone. This will permanently delete the{" "}
            <b>Students</b>.
          </h6>
          <Form.Group className="" controlId="formBasicEmail">
            <Form.Label className="form-title">
              Please type "<b>DeleteStudents</b>" to confirm.
            </Form.Label>
            <Form.Control
              className="form-input"
              type="text"
              name="deletestu"
              value={deletestu}
              onChange={(e) => setdeletestu(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <ButtonBlue
            onClick={() => deleteStudent()}
            className="h-40 w-100"
            style={{ backgroundColor: "red" }}
          >
            Delete Students
          </ButtonBlue>
        </Modal.Footer>
      </Modal>
      <Toaster />
      <Modal show={showcreateStudent} onHide={createStudentModalClose}>
        <Modal.Header closeButton>
          <DarkHeading>Add Student</DarkHeading>
        </Modal.Header>
        <Modal.Body className="job-form mx-2">
          <div className="d-flex justify-content-end">
            <ButtonBlue
              width="200px"
              className="h-40"
              onClick={() => navigate("bulk-students")}
            >
              Add Bulk Student
            </ButtonBlue>
          </div>
          <Form className="px-3 pb-2" onSubmit={createNewStudent}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">First Name</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="firstname"
                required
                value={studentDetails.firstname}
                onChange={handlestudentdetails}
                placeholder="Deepa"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Last Name</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="lastname"
                required
                value={studentDetails.lastname}
                onChange={handlestudentdetails}
                placeholder="Chawan"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Email</Form.Label>
              <Form.Control
                name="email"
                required
                value={studentDetails.email}
                onChange={handlestudentdetails}
                className="form-input"
                type="email"
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                placeholder="abc@gmail.com"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Phone Number</Form.Label>
              <Form.Control
                className="form-input"
                type="phone"
                name="phoneNumber"
                required
                value={studentDetails.phoneNumber}
                onChange={handlestudentdetails}
                placeholder=""
              />
            </Form.Group>
            {/* <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Password</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="password"
                value={studentDetails.password}
                onChange={handlestudentdetails}
                placeholder=""
              />
            </Form.Group> */}
            <hr />
            {studentmodalloading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add New Student
              </ButtonBlue>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={createTeamModal} onHide={createTeamModalClose}>
        <Modal.Header closeButton>
          <DarkHeading>Add Team</DarkHeading>
        </Modal.Header>
        <Modal.Body className="job-form h-80 mx-2">
          <div className="px-3 pb-2">
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Name</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="name"
                required
                value={teamdetails.name}
                onChange={handleTeamDetails}
                placeholder="Team Name"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Study Class</Form.Label>
              <Form.Control
                name="studyclass"
                required
                value={teamdetails.studyclass}
                onChange={handleTeamDetails}
                className="form-input"
                type="text"
                placeholder=""
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Standard</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="standard"
                required
                value={teamdetails.standard}
                onChange={handleTeamDetails}
                placeholder=""
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Year</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="year"
                value={teamdetails.year}
                onChange={handleTeamDetails}
                placeholder=""
              />
            </Form.Group>
            <Form.Label className="form-title my-2">Add Students</Form.Label>
            <div className="tags-input">
              {teamStudents.length > 0 ? (
                <ul id="tags">
                  {teamStudents?.map((tag, index) => (
                    <li key={index} className="tag">
                      <span className="tag-title">{tag}</span>
                      <span
                        className="tag-close-icon"
                        onClick={() => removethisStudent(index)}
                      >
                        x
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul id="tags">
                  <h6>Please Enter Email ID to Add Students</h6>
                </ul>
              )}
              <input
                type="text"
                onKeyUp={(event) =>
                  event.key === "Enter" ? handleStudentListChange(event) : null
                }
                placeholder="Press enter to add Student"
              />
            </div>
            <div className="col-12 d-flex justify-content-end mt-4 mb-0"></div>
            <hr />
            {teammodalloading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue onClick={addNewTeam} className="w-100">
                Add New Team
              </ButtonBlue>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {loading ? (
        <>
          <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
            <Loading />
          </DashboardBoxStyle>
        </>
      ) : (
        <>
          <DashboardBoxStyle className="scroller">
            <div className="container">
              {type === "college" && (
                <div className="my-3 d-flex justify-content-end">
                  <ButtonBlue
                    onClick={createStudentModalOpen}
                    className="px-3 px-sm-5 h-40 me-3"
                  >
                    Add Student
                  </ButtonBlue>
                  <ButtonBlue
                    onClick={createTeamModalOpen}
                    className="px-3 px-sm-5 h-40"
                  >
                    Add Team
                  </ButtonBlue>
                </div>
              )}
              <BoxStyle className="my-3">
                <div className="row profile-sec-1 px-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="d-flex align-items-center mx-2 mb-3"
                      style={{ marginTop: "10px" }}
                    >
                      <ButtonBlue
                        className={`px-3 px-sm-5 h-40 ${
                          teamStudent ? "active" : "inactive"
                        }`}
                        onClick={() => {
                          setTeamStudent(true);
                        }}
                      >
                        Teams
                      </ButtonBlue>
                      <YellowButton
                        className={`mx-1 mx-sm-1 px-3 px-sm-5 ${
                          teamStudent ? " inactive" : "active"
                        }`}
                        onClick={() => {
                          setTeamStudent(false);
                        }}
                      >
                        Students
                      </YellowButton>
                    </div>
                    {/* <div>
                  <YellowHeading>Senior UI/UX Designer</YellowHeading>
                </div>
                <div className="d-flex align-items-center">
                  <ButtonBlue className="h-40 px-4">
                    <b>View All</b>
                  </ButtonBlue>
                </div> */}
                  </div>
                </div>
                <hr />
                {teamStudent ? (
                  <>
                    <div className="candidates-box">
                      {teamdata.length === 0 ? (
                        <>
                          <div className="d-flex align-items-center justify-content-center my-4">
                            <h5>Currently No Teams To Display</h5>
                          </div>
                        </>
                      ) : (
                        <>
                          {teamdata.map((data, i) => (
                            <TeamCard data={data} key={i} />
                          ))}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="">
                      {studentdata.length === 0 ? (
                        <>
                          <div className="d-flex align-items-center justify-content-center my-4">
                            <h5>Currently No Students To Display</h5>
                          </div>
                        </>
                      ) : (
                        <>
                          {selectedStudent.length > 0 && (
                            <div className="d-flex justify-content-end ">
                              <div className="col-12">
                                <div className="d-flex justify-content-end  p-0">
                                  <ButtonBlue
                                    style={{ backgroundColor: "red" }}
                                    className="h-40"
                                    onClick={() => setshow(true)}
                                    width="150px"
                                  >
                                    Delete
                                  </ButtonBlue>
                                </div>
                              </div>
                            </div>
                          )}
                          <Table
                            columns={columns}
                            checkbox={false}
                            data={studentdata?.map((item, i) => {
                              return {
                                ...item,
                                id: item._id,
                                key: i,
                              };
                            })}
                            style={{ width: "100%" }}
                            limit={10}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </BoxStyle>
              {/* <BoxStyle className="my-3">
            <div className="row profile-sec-1 px-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <YellowHeading>Senior UI/UX Designer</YellowHeading>
                </div>
                <div className="d-flex align-items-center">
                  <ButtonBlue className="h-40 px-4">
                    <b>View All</b>
                  </ButtonBlue>
                </div>
              </div>
            </div>
            <hr />
            <div className="candidates-box">
              <CandidateCard />
              <CandidateCard />
              <CandidateCard />
            </div>
          </BoxStyle> */}
            </div>
          </DashboardBoxStyle>
        </>
      )}
    </>
  );
};
