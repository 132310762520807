import React, { useEffect, useRef, useState } from "react";
import {
  BoxStyle,
  ButtonBlue,
  ButtonPurpleOutline,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import { useNavigate } from "react-router";
import user from "assets/image/user.png";
import "./profile.css";
import { Form, Spinner } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import axios from "axios";
import {
  AdminPasswordUpdate,
  CorporatePasswordUpdate, GetAdmin, UpdateAdmin,
} from "../../../axios/apis";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";

export const Profile = ({ updateprofile, setupdateprofile }) => {
  const [showpassword, setShowPassword] = useState(false)
  const [shownewpassword, setShowNewPassword] = useState(false)
  const [showconfirmpassword, setShowConfirmPassword] = useState(false)
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
  });
  const [details, setDetails] = useState({})
  const [passwordChange, setPasswordChange] = useState({
    password: "",
    newpassword: "",
    confirmnewpass: "",
  })

  const handleChange = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };
  const handlePasswordChange = (e) => {
    setPasswordChange({ ...passwordChange, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const GetAdminDetails = async () => {
    setLoading(true);
    try {
      const response = await GetAdmin();
      setUserDetail(response?.data?.data);
      setDetails(response?.data?.data)
      console.log(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetAdminDetails();
  }, []);
  const [profileLoading, setProfileLoading] = useState(false);
  const UpdateAdminDetail = async (e) => {
    e.preventDefault();
    if (
      userDetail.name !== details.name &&
      userDetail.email !== details.email
    ) {
      setProfileLoading(true);
      try {
        let data = {
          email: userDetail.email,
          name: userDetail.name,
        };
        const res = await UpdateAdmin(data);
        toast.success("Updated Successfully", ToastStyles);
        Cookies.set(
          "name",
          res?.data?.data?.name
        );
        Cookies.set("email", res?.data?.data?.email);
        setProfileLoading(false);
        setupdateprofile(!updateprofile)
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setProfileLoading(false);
      }
    } else if (userDetail.name !== details.name) {
      setProfileLoading(true);
      try {
        let data = {
          name: userDetail.name,
        };
        const res = await UpdateAdmin(data);
        Cookies.set(
          "name",
          res?.data?.data?.name
        );
        toast.success("Updated Successfully", ToastStyles);
        setProfileLoading(false);
        setupdateprofile(!updateprofile)

      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setProfileLoading(false);
      }
    } else if (userDetail.email !== details.email) {
      setProfileLoading(true);
      try {
        let data = {
          email: userDetail.email,
        };
        const res = await UpdateAdmin(data);
        Cookies.set("email", res?.data?.data?.email);
        toast.success("Updated Successfully", ToastStyles);
        setProfileLoading(false);
        setupdateprofile(!updateprofile)

      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setProfileLoading(false);
      }
    } else {
      toast.error("Please Update Details first");
    }
  };

  const [passwordloading, setpasswordLoading] = useState(false);

  const updatePassword = async (e) => {
    e.preventDefault();
    if (passwordChange.newpassword !== passwordChange.confirmnewpass) {
      toast.error("Passwords do not match", ToastStyles);
    } else if (passwordChange.newpassword === passwordChange.password) {
      toast.error("Current and New Password Can't be same.", ToastStyles);
    } else {
      setpasswordLoading(true);
      try {
        let data = {
          oldpass: passwordChange.password,
          password: passwordChange.newpassword,
        };
        const res = await AdminPasswordUpdate(data);
        toast.success("Password Updated Successfully", ToastStyles);
        setPasswordChange({
          password: "",
          newpassword: "",
          confirmnewpass: "",
        });
        setpasswordLoading(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 400) {
          toast.error("Current Password is Incorrect 😣", ToastStyles);
        } else {
          toast.error("Try Again 😣", ToastStyles);
        }
        setpasswordLoading(false);
      }
    }
  };
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <BoxStyle className="mt-3">
              <div className="row profile-sec-1 px-0">
                <div className=" profile-card-2 col-sm-6 col-12">
                  <div>
                    <YellowHeading>Profile</YellowHeading>
                    <h6>
                      Update your account’s profile information and email
                      address
                    </h6>
                  </div>
                </div>
                <div className="profile-card col-sm-6 col-12">

                  <Form className="p-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name="name"
                        value={userDetail?.name}
                        onChange={handleChange}
                      // placeholder="Deepa Chawan"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">Email</Form.Label>
                      <Form.Control
                        name="email"
                        value={userDetail?.email}
                        onChange={handleChange}
                        className="form-input"
                        type="email"
                      // placeholder="abc@gmail.com"
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                      {profileLoading ? (
                        <ButtonBlue className="h-40" style={{ width: "160px" }}>
                          <Spinner animation="border" size="sm" />
                        </ButtonBlue>
                      ) : (
                        <ButtonBlue
                          className="h-40 "
                          style={{ width: "160px" }}
                          onClick={UpdateAdminDetail}
                        >
                          Save
                        </ButtonBlue>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </BoxStyle>

            <BoxStyle className="my-4">
              <div className="row profile-sec-1 px-0">
                <div className=" profile-card-2 col-sm-6 col-12">
                  <div>
                    <YellowHeading>Update Password</YellowHeading>
                    <h6>
                      Ensure your account is using a long, random password to
                      stay secure.
                    </h6>
                  </div>
                </div>
                <div className="profile-card col-sm-6 col-12">
                  <Form onSubmit={updatePassword} className="p-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Current Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type={showpassword ? "text" : "password"}
                        name="password"
                        minLength={8}
                        required
                        value={passwordChange?.password}
                        onChange={handlePasswordChange}
                        placeholder="Current Password"
                      />
                      {showpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        New Password
                      </Form.Label>
                      <Form.Control
                        name="newpassword"
                        value={passwordChange?.newpassword}
                        onChange={handlePasswordChange}
                        className="form-input"
                        minLength={8}
                        required
                        type={shownewpassword ? "text" : "password"}
                        placeholder="New Password"
                      />
                      {shownewpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowNewPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowNewPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        className="form-input"
                        type={showconfirmpassword ? "text" : "password"}
                        name="confirmnewpass"
                        minLength={8}
                        required
                        value={passwordChange?.confirmnewpass}
                        onChange={handlePasswordChange}
                        placeholder="Re-enter Password"
                      />
                      {showconfirmpassword ? (
                        <div className="eye-position">
                          <AiFillEyeInvisible
                            onClick={() => setShowConfirmPassword(false)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      ) : (
                        <div className="eye-position">
                          <AiFillEye
                            onClick={() => setShowConfirmPassword(true)}
                            className="password-eye1 pe-1"
                            color="#602a94"
                            size={28}
                          />
                        </div>
                      )}
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                      {passwordloading ? (
                        <ButtonBlue className="h-40" style={{ width: "160px" }}>
                          <Spinner animation="border" size="sm" />
                        </ButtonBlue>
                      ) : (
                        <ButtonBlue
                          className="h-40 "
                          style={{ width: "160px" }}
                          type="submit"
                        >
                          Save
                        </ButtonBlue>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </BoxStyle>
            {/* <BoxStyle className="my-4">
              <div className="row profile-sec-1 px-0">
                <div className=" profile-card-2 col-sm-6 col-12">
                  <div>
                    <YellowHeading>Plan Details</YellowHeading>
                    <h6>Plan Details.</h6>
                  </div>
                </div>
                <div className="profile-card col-sm-6 col-12">
                  <Form className="p-4">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">Plan Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        name="name"
                        disabled
                        value={plan.name}
                        onChange={handlePlanChange}
                        placeholder="Basic"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="form-title">
                        Job Posting
                      </Form.Label>
                      <Form.Control
                        name="jobs"
                        disabled
                        value={plan.jobs}
                        onChange={handlePlanChange}
                        className="form-input"
                        type="number"
                        placeholder="50"
                      />
                    </Form.Group>

                    <div className="d-flex justify-content-end mt-4">
                      <h6 className="form-title me-2">Bill</h6>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <ButtonBlue
                        className="h-40 "
                        style={{ width: "160px" }}
                        type="submit"
                      >
                        Download
                      </ButtonBlue>
                    </div>
                  </Form>
                </div>
              </div>
            </BoxStyle> */}
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
