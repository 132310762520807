import {
  ButtonPurpleOutline,
  ButtonYellowFill,
  DashboardBoxStyle,
  Paragraph,
  PinkButton,
} from "components/common/CommonStyles";
import React, { useEffect, useRef, useState } from "react";
import InterviewLogo from "assets/image/interview-logo.png";
import user from "assets/image/user.png";
import "./interview.css";
import { AiFillFilePdf, AiFillMail, AiFillPhone } from "react-icons/ai";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { MultiStepProgress } from "components/ProgressBar/MultiStepProgress";

import { Toaster, toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import axios from "axios";
import {
  ApplyInterview,
  GetIntervieweeDetails,
  GetSingleInterview,
  IntervieweeSignUp,
  UpdateIntervieweeProfile,
} from "../../../axios/apis";
import {
  InterviewPageBox,
  LightPurpleBtn,
  PurpleBoxYellowBorder,
} from "container/ActualInterview/InterviewStyled";
import Loading from "components/common/Loading";
const fetchSkillDetails = async (skillId) => {
  try {
    const response = await fetch(
      // `https://interview-api.viosa.in/interview/skills/${skillId}`
      `https://api.viosa.in/interview-pro/interview/skills/${skillId}`
    );
    const data = await response.json();
    return data.skills; // Assuming the API returns the skill object with a 'skills' field containing the skill name.
  } catch (error) {
    // console.error(`Error fetching skill details for ${skillId}:`, error);
    return null;
  }
};
const ApplyForInterview = ({ item }) => {
  const UploadResume = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [interviewdata, setInterviewData] = useState({});
  const [show, setShow] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [updateloading, setupdateLoading] = useState(false);
  const [skillNames, setSkillNames] = useState([]);
  const navigate = useNavigate();
  const [loadingfileUpload, setLoafingFileUpload] = useState(false);
  // console.log("Component Rendered with item:", interviewdata);

  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      // console.log(response);
      setInterviewData(response.data.interview);
      console.log("response", response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  const Apply = async (e) => {
    e.preventDefault();
    // console.log(userDetail);
    if (userDetail.resume === "") {
      toast.error("Please Upload Resume", ToastStyles);
      return;
    }
    if (userDetail.coverletter === "") {
      toast.error("Please Upload CoverLetter", ToastStyles);
      return;
    }
    setupdateLoading(true);
    let data = {
      firstname: userDetail.firstname,
      lastname: userDetail.lastname,
      linkedin: userDetail.linkedin,
      phoneNumber: userDetail.phoneNumber,
      profile: userDetail.profile,
      resume: userDetail.resume,
      gender: userDetail.gender,
      coverletter: userDetail.coverletter,
      specialization: userDetail.specialization,
    };
    let data2 = {
      interviewId: params.id,
    };
    try {
      const res = await UpdateIntervieweeProfile(data);
      const res2 = await ApplyInterview(data2);
      toast.success("Applied Successfully", ToastStyles);
      // navigate(`/interview/video-interview/${item?._id}`);
      navigate(`/interview/video-interview/${params?.id}`);
      setupdateLoading(false);
      // setupdateprofile(!updateprofile)
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || "Try Again", ToastStyles);
      setupdateLoading(false);
    }
  };

  const GetUser = async () => {
    setLoading(true);
    try {
      const res = await GetIntervieweeDetails();
      // console.log(res);
      setUserDetail(res?.data?.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  // console.log(interviewdata);
  useEffect(() => {
    GetInterviewDetails();
    GetUser();
  }, [params.id]);

  useEffect(() => {
    const fetchSkills = async () => {
      if (Array.isArray(interviewdata.skills)) {
        const skillsPromises = interviewdata.skills.map(fetchSkillDetails);
        const skills = await Promise.all(skillsPromises);
        console.log(skills);
        setSkillNames(skills.filter(Boolean));
      }
    };

    fetchSkills();
  }, [interviewdata.skills]);

  const handleChange = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };

  const [percentage, setPercentage] = useState(0);
  const uploadFile = async (e) => {
    if (e.target.files[0]) {
      setLoafingFileUpload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoafingFileUpload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoafingFileUpload(true);

      axios
        .post(`${rootUrl}/v2/upload/pdf`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          setUserDetail({ ...userDetail, [e.target.name]: data.data.link });
          setLoafingFileUpload(false);
          toast.success("File Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          setLoafingFileUpload(false);
        });
    }
  };
  const [signuploading, setsignupLoading] = useState(false);
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller apply-interview interview-form h-70 padding-0">
          <InterviewPageBox className="px-2">
            <div className="col-12">
              {/* <div className="section-1 p-3">
                        <div className="mt-4 mb-2 step-counter">
                           <MultiStepProgress />
                        </div>
                     </div> */}
              <div className="section-2 my-3 col-12 d-flex">
                <div className="col-12 col-md-8">
                  <PurpleBoxYellowBorder className="mx-3 margin-res">
                    <div className="dark-purple-bg p-3">
                      <div className="d-flex align-items-center justify-content-between align-item-center">
                        <div className="d-flex ">
                          <img
                            src={interviewdata?.icon || InterviewLogo}
                            alt="logo"
                            className="rounded-circle "
                            width={100}
                            height={100}
                          />
                          <div className="px-3 d-flex flex-column justify-content-center">
                            <p className="my-1">
                              Company Name: <b>{interviewdata?.company}</b>
                            </p>
                            <h6 className="my-1">
                              {interviewdata?.jobtitle || "Job title"}
                            </h6>
                            <p className="my-1">
                              {interviewdata?.jobarea}, {interviewdata?.jobcity}
                              , India({interviewdata?.joblocation})
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <h5>Employment Type</h5>
                      <p>{interviewdata?.jobtype}</p>
                      <h5>Role Overview</h5>
                      <Paragraph className="mb-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: interviewdata?.jobdescription,
                          }}
                        ></div>
                      </Paragraph>
                      <h5>Required Skills</h5>
                      <div className="skills-box">
                        {/* {interviewdata?.skills?.map((b, ind) => (
                          <PinkButton className="mx-1" key={ind}>
                            {b.skills}
                          </PinkButton>
                        ))} */}
                        {/* {interviewdata.skills.length > 0 && ( */}
                        <div className="w-100-525">
                          <Paragraph className="pt-1 mb-0">Skills</Paragraph>
                          {skillNames.map((skill, index) => (
                            <PinkButton key={index} className="fs-12 mx-1 my-1">
                              {skill}
                            </PinkButton>
                          ))}
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                  </PurpleBoxYellowBorder>
                </div>
                <div className="col-12 col-md-4">
                  <PurpleBoxYellowBorder className="me-3 margin-res mt-0">
                    <Form onSubmit={Apply} className="p-4">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Name</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          required
                          disabled
                          name="firstname"
                          value={
                            userDetail.firstname + " " + userDetail.lastname
                          }
                          onChange={handleChange}
                          // placeholder="Deepa"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Email</Form.Label>
                        <Form.Control
                          name="email"
                          required
                          disabled
                          value={userDetail.email}
                          onChange={handleChange}
                          className="form-input"
                          type="email"
                          placeholder="abc@gmail.com"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="phone"
                          disabled
                          name="phoneNumber"
                          required
                          value={userDetail.phoneNumber}
                          onChange={handleChange}
                          placeholder=""
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">Resume</Form.Label>
                        <Form.Control
                          className="form-input"
                          type="file"
                          // value={userDetail.resume}

                          name="resume"
                          onChange={uploadFile}
                          placeholder="Upload Resume"
                        />
                        {userDetail?.resume && (
                          <p
                            style={{ color: "green" }}
                            className="form-title mb-0"
                          >
                            Uploaded
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Cover Letter
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="file"
                          name="coverletter"
                          onChange={uploadFile}
                          placeholder="Upload Cover Letter"
                        />
                        {userDetail?.coverletter && (
                          <p style={{ color: "green" }} className="form- mb-0">
                            Uploaded
                          </p>
                        )}
                      </Form.Group>
                      <p>
                        By applying, you agree with{" "}
                        <span className="yellow">
                          VIOSA.IN Candidate Terms of Use
                        </span>
                      </p>
                      {updateloading ? (
                        <>
                          <ButtonYellowFill>
                            <Spinner animation="border" size="sm" />
                          </ButtonYellowFill>
                        </>
                      ) : (
                        <>
                          <ButtonYellowFill
                            disabled={loadingfileUpload}
                            type="submit"
                          >
                            Apply Now
                          </ButtonYellowFill>
                        </>
                      )}
                    </Form>
                  </PurpleBoxYellowBorder>
                </div>
              </div>
            </div>
          </InterviewPageBox>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default ApplyForInterview;
